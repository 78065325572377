import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../shared/spinner';
import { Parametros } from '../../shared/model/parametros';
import ParametrosService from '../../services/parametrosService';

function ParametrosTi() {
  const [loading, setLoading] = useState(true);
  const [parametros, setParametros] = useState<Parametros>();
  const [nomeBancoBpo, setNomeBancoBpo] = useState('');
  const [urlBpo, setUrlBpo] = useState('');
  const [usuarioBpo, setUsuarioBpo] = useState('');
  const [senhaBpo, setSenhaBpo] = useState('');
  const [urlDigitalBpo, setUrlDigitalBpo] = useState('');
  const [urlLecca, setUrlLecca] = useState('');
  const [tokenLecca, setTokenLecca] = useState('');
  const [urlVortx, setUrlVortx] = useState('');
  const [tokenVortx, setTokenVortx] = useState('');
  const [urlCessao, setUrlCessao] = useState('');
  const [tokenCessao, setTokenCessao] = useState('');

  const parametrosService: ParametrosService = new ParametrosService();

  const listarTodosParametrosTi = async () => {
    try {
      setLoading(true);
      const responseList = await parametrosService.listarTodosParametros();
      const json = responseList.data;
      
      setParametros(json);
      setNomeBancoBpo(json.nomeBancoBpo);
      setUrlBpo(json.urlBpo);
      setUsuarioBpo(json.usuarioBpo);
      setSenhaBpo(json.senhaBpo);
      setUrlDigitalBpo(json.urlDigitalBpo);
      setUrlLecca(json.urlLecca);
      setTokenLecca(json.tokenLecca);
      setUrlVortx(json.urlVortx);
      setTokenVortx(json.tokenVortx);
      setUrlCessao(json.urlCessao);
      setTokenCessao(json.tokenCessao);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.error(err.response.data);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
      setLoading(true);
      
      try {
        let parametros: Parametros = new Parametros();
        parametros.nomeBancoBpo = nomeBancoBpo;
        parametros.urlBpo = urlBpo;
        parametros.usuarioBpo = usuarioBpo;
        parametros.senhaBpo = senhaBpo;
        parametros.urlDigitalBpo = urlDigitalBpo;
        parametros.urlLecca = urlLecca;
        parametros.tokenLecca = tokenLecca;
        parametros.urlVortx = urlVortx;
        parametros.tokenVortx = tokenVortx;
        parametros.urlCessao = urlCessao;
        parametros.tokenCessao = tokenCessao;

        await parametrosService.alterarParametros(parametros);
        
        setLoading(false)
        alert('Alteração realizada com sucesso!');
      } catch (err: any) {
        setLoading(false);
        alert(err.response.data);
      }
  };

  useEffect(() => {
    listarTodosParametrosTi();
  }, []);

  return (
    <div className="position-relative m-md-3 container-fluid SiteFontSize">
      <div className="row col-md-12">
        <h6><strong>Sistema &gt; Parâmetros TI</strong></h6>
      </div>
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div style={{ marginTop: '15px' }}>
            <h5>Integração BPO</h5>
            <div className="row">
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>Nome Banco BPO</span>
                <input type="text" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={nomeBancoBpo} onChange={(e) => setNomeBancoBpo(e.target.value)}
                />
              </div>
              <div className="col-md-2 form-group">
                <span style={{ textAlign: 'left' }}>Usuário</span>
                <input type="text" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={usuarioBpo} onChange={(e) => setUsuarioBpo(e.target.value)}
                />
              </div>
              <div className="col-md-2 form-group">
                <span style={{ textAlign: 'left' }}>Senha</span>
                <input type="password" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={senhaBpo} onChange={(e) => setSenhaBpo(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>URL Serviço</span>
                <input type="url" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={urlBpo} onChange={(e) => setUrlBpo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
            <h5>Integração Digital BPO</h5>
            <div className="row">
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>URL Serviço</span>
                <input type="url" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={urlDigitalBpo} onChange={(e) => setUrlDigitalBpo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
            <h5>Integração Lecca</h5>
            <div className="row">
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>URL Serviço</span>
                <input type="url" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={urlLecca} onChange={(e) => setUrlLecca(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>Token</span>
                <input type="password" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={tokenLecca} onChange={(e) => setTokenLecca(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
            <h5>Integração VortX</h5>
            <div className="row">
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>URL Serviço</span>
                <input type="url" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={urlVortx} onChange={(e) => setUrlVortx(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <span style={{ textAlign: 'left' }}>Token</span>
                <input type="password" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={tokenVortx} onChange={(e) => setTokenVortx(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: '15px' }}>
              <h5>Integração Cessão</h5>
              <div className="row">
                <div className="col-md-6 form-group">
                  <span style={{ textAlign: 'left' }}>URL Serviço</span>
                  <input type="url" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={urlCessao} onChange={(e) => setUrlCessao(e.target.value)}
                />
                </div>
                <div className="col-md-6 form-group">
                  <span style={{ textAlign: 'left' }}>Token</span>
                  <input type="password" className="form-control form-control-sm brds" style={{ textAlign: 'left' }} 
                  value={tokenCessao} onChange={(e) => setTokenCessao(e.target.value)}
                />
                </div>
              </div>
            </div>
            <div>
              <hr className="my-4" />
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button className="w-40 btn btn-lg" id="App-button-verde">Voltar</button>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Salvar Parâmetros</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
export default ParametrosTi;