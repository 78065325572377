import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../shared/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { RelatoriosDTO } from '../../shared/dto/relatoriosDTO';
import ArquivoService from '../../services/arquivoService';

function OrigensCadastradasComponent() {
  const [loading, setLoading] = useState(false);

  const [selectedOrigem, setSelectedOrigem] = useState('MATRIZ');

  const [availableOrigens, setAvailableOrigens] = useState<any[]>([]);
  const [selectedOrigens, setSelectedOrigens] = useState<any[]>([]);
  const [selectedToMoveOrigens, setSelectedToMoveOrigens] = useState<any[]>([]);
  const [selectedFromSelectedOrigens, setSelectedFromSelectedOrigens] = useState<any[]>([]);

  const arquivoService: ArquivoService = new ArquivoService();

  const listarOrigens = async (origem: string) => {
    setLoading(true);
    try {
      const response = await arquivoService.listarOrigemGenerica(origem);
      
      setAvailableOrigens(response.data);
      setSelectedOrigens([]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const gerarRelatorio = async () => {
    if (selectedOrigens.length <= 0) return alert('Selecione ao menos uma Origem');

    setLoading(true);
    try {
      const dto: RelatoriosDTO = new RelatoriosDTO();
      dto.tipoOrigemIds = selectedOrigens.map(origem => origem.id);
      dto.tipoOrigem = selectedOrigem;

      const response = await arquivoService.gerarRelatorioOrigensCadastradas(dto);

      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        alert(errorText);
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Relatorio_Origens_Cadastradas.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        alert(errorText);
      } else {
        alert('Ocorreu um erro ao gerar o relatório');
      }
      console.error(err);
    }
  };

  useEffect(() => {
    listarOrigens('MATRIZ');
  }, []);

  const moveToSelectedOrigens = () => {
    const newSelected = [...selectedOrigens, ...selectedToMoveOrigens];
    const newAvailable = availableOrigens.filter(
      (origem) => !selectedToMoveOrigens.includes(origem)
    );

    setAvailableOrigens(newAvailable);
    setSelectedOrigens(newSelected);
    setSelectedToMoveOrigens([]);
  };

  const moveToAvailableOrigens = () => {
    const newAvailable = [...availableOrigens, ...selectedFromSelectedOrigens];
    const newSelected = selectedOrigens.filter(
      (origem) => !selectedFromSelectedOrigens.includes(origem)
    );

    setAvailableOrigens(newAvailable);
    setSelectedOrigens(newSelected);
    setSelectedFromSelectedOrigens([]);
  };

  const moveAllToSelectedOrigens = () => {
    const newSelected = [...selectedOrigens, ...availableOrigens];
    setSelectedOrigens(newSelected);
    setAvailableOrigens([]);
  };

  const moveAllToAvailableOrigens = () => {
    const newAvailable = [...availableOrigens, ...selectedOrigens];
    setAvailableOrigens(newAvailable);
    setSelectedOrigens([]);
  };

  function capitalizeFirstLetter(str: string) {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>Relatório de Produção Sintético</strong>
            </div>
            <div className="card-body">
              <div className="" style={{ marginTop: '10px' }}>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-3">
                    <label>Tipo da Origem</label>
                    <select className="form-select" value={selectedOrigem} onChange={(e) => {setSelectedOrigem(e.target.value); listarOrigens(e.target.value) }}>
                      <option value="MATRIZ">Matriz</option>
                      <option value="PROMOTORA">Promotora</option>
                      <option value="SUPERVISOR">Supervisor</option>
                      <option value="OPERADOR">Operador</option>
                    </select>
                  </div>
                </div>

              </div>

              {/* QUADROS ORIGENS */}
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <h6>{capitalizeFirstLetter(selectedOrigem.toUpperCase() === 'PROMOTORA' ? 'Promotoras' : selectedOrigem.toUpperCase() === 'PONTOVENDA' ? 'Pontos de Venda' : selectedOrigem.toUpperCase()+'es')} Disponíveis</h6>
                  <select
                    multiple
                    value={selectedToMoveOrigens.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const origem = availableOrigens.find(p => p.id!.toString() === options[i].value);
                          if (origem) {
                            selected.push(origem);
                          }
                        }
                      }
                      setSelectedToMoveOrigens(selected);
                    }}
                    style={{ width: '575px', height: '180px' }}
                  >
                    {availableOrigens.map((origem) => (
                      <option key={origem.id} value={origem.id!.toString()}>
                        {origem.id} - {origem.nome!}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <button onClick={moveToSelectedOrigens} style={{ margin: '5px' }}>{'>'}</button>
                  <button onClick={moveAllToSelectedOrigens} style={{ margin: '5px' }}>{'>>'}</button>
                  <button onClick={moveToAvailableOrigens} style={{ margin: '5px' }}>{'<'}</button>
                  <button onClick={moveAllToAvailableOrigens} style={{ margin: '5px' }}>{'<<'}</button>
                </div>
                <div>
                <h6>{capitalizeFirstLetter(selectedOrigem.toUpperCase() === 'PROMOTORA' ? 'Promotoras' : selectedOrigem.toUpperCase() === 'PONTOVENDA' ? 'Pontos de Venda' : selectedOrigem.toUpperCase()+'es')} Selecionados</h6>
                  <select
                    multiple
                    value={selectedFromSelectedOrigens.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const origem = selectedOrigens.find(p => p.id!.toString() === options[i].value);
                          if (origem) {
                            selected.push(origem);
                          }
                        }
                      }
                      setSelectedFromSelectedOrigens(selected);
                    }}
                    style={{ width: '575px', height: '180px' }}
                  >
                    {selectedOrigens.map((origem) => (
                      <option key={origem.id} value={origem.id!.toString()}>
                        {origem.id} - {origem.nome!}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row" style={{ marginTop: '20px', height: '50px', maxWidth: '70%' }}>
                <div className="col-md-3">
                  <button className="input-group-btn"
                    style={{
                      width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                      justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                    }} onClick={gerarRelatorio}>
                    <a>
                      <FontAwesomeIcon icon={faPrint} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                    </a>
                    <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Gerar Relatório</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
}
export default OrigensCadastradasComponent;