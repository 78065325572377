import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import { format, parse } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUpload, faDownload, faRefresh, faRecycle, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { ArquivoDTO } from '../../../shared/dto/arquivoDTO';
import { StatusProcessamentoEnum } from '../../../shared/enum/statusProcessamentoEnum';
import { UUID } from 'crypto';
import { useParams } from 'react-router-dom';
import { Cessao } from '../../../shared/model/cessao';
import ArquivoService from '../../../services/arquivoService';
import OperacaoParcelaService from '../../../services/operacaoParcelaService';
import CessaoService from '../../../services/cessaoService';

function ArquivoLoteComponent() {
  const { processamento } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [numeroCessao, setNumeroCessao] = useState('');
  const [nomeCessao, setNomeCessao] = useState('');

  const [exibeDetalhe, setExibeDetalhe] = useState(false);

  const [dtMovimento, setDtMovimento] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [arquivo, setArquivo] = useState<File | null>(null);
  const [nomeArquivo, setNomeArquivo] = useState<string>('');
  const [arquivoBase64, setArquivoBase64] = useState<string>('');
  const [arquivosDTO, setArquivosDTO] = useState<ArquivoDTO[]>([]);

  const [tipoArquivo, setTipoArquivo] = useState<string[]>([]);
  const [tipoArquivoProcessamento, setTipoArquivoProcessamento] = useState<string>("");

  const arquivoService: ArquivoService = new ArquivoService();
  const operacaoParcelaService: OperacaoParcelaService = new OperacaoParcelaService();
  const cessaoService: CessaoService = new CessaoService();

  const listarTiposArquivos = async () => {
    try {
      setLoading(true);
      const responseList = await arquivoService.listarTiposArquivos(processamento);
      const json = responseList.data;
      setTipoArquivo(json);
      
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleTipoArquivo = (event: any) => {
    setTipoArquivoProcessamento(event.target.value);
    setExibeDetalhe(true);
    listarArquivosBaixaLote(event.target.value);
  }

  const listarArquivosBaixaLote = async (tipoArq: string) => {
    try {
      setLoading(true);
      const responseList = await arquivoService.listarArquivosLote(tipoArq);

      const json = responseList.data;
      setArquivosDTO(json);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleDownloadModelo = async () => {
    try {
      const response = await arquivoService.downloadModeloLote(tipoArquivoProcessamento);

      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      if (tipoArquivoProcessamento === 'BAIXA_LOTE') {
        link.setAttribute('download', 'Layout - Baixa de Parcela.csv');
      } else if (tipoArquivoProcessamento === 'PRORROGACAO_LOTE') {
        link.setAttribute('download', 'Layout - Alteracao de Vencimentos.csv');
      } else if (tipoArquivoProcessamento === 'CESSAO_LOTE') {
        link.setAttribute('download', 'Layout - Lancamento de Cessao.csv');
      } else if (tipoArquivoProcessamento === 'RECOMPRA_LOTE') {
        link.setAttribute('download', 'Layout - Recompra de Cessao.csv');
      }

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  const handleDownloadArquivo = async (caminhoArquivo: string, nomeArquivo: string) => {
    try {
      const response = await arquivoService.downloadArquivoLote(caminhoArquivo);

      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nomeArquivo);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  const handleClickUpload = (event: any) => {
    setArquivo(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        setArquivoBase64(e.target.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    setNomeArquivo(event.target.files[0].name);
  }

  const uploadArquivo = async () => {
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return alert('Data de movimento não pode ser superior a data de hoje');
    if (tipoArquivoProcessamento === 'CESSAO_LOTE' && (numeroCessao === '' || nomeCessao === '')) return alert('Preencha o corretamente o código cessão');

    if (arquivo) {
      setLoading(true);
      try {
        let arquivoDTO = new ArquivoDTO();
        arquivoDTO.arquivoBase64 = arquivoBase64.split(',')[1];
        arquivoDTO.nomeArquivo = nomeArquivo;
        arquivoDTO.dtInclusao = new Date();
        await operacaoParcelaService.processarArquivoLote(tipoArquivoProcessamento, numeroCessao, arquivoDTO)
          .then((resp) => {
            
            setArquivosDTO([...arquivosDTO, resp.data]);
            setNomeArquivo('')
            setLoading(false);
            alert('Arquivo incluído com sucesso!');
          });
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  }

  const reprocessarArquivo = async (idArquivo: UUID) => {
    try {
      alert('Arquivo enviado para reprocessamento!');
      await operacaoParcelaService.reprocessarArquivoLote(tipoArquivoProcessamento, idArquivo);
    } catch (err) {
      console.error(err);
    }
  }

  const handleRefresh = () => {
    listarArquivosBaixaLote(tipoArquivoProcessamento);
  }

  const listarCessao = async () => {
    if (numeroCessao === '') return alert('Preencha o campo de busca');
    try {
      setLoading(true);

      const responseList = await cessaoService.listarCessaoPorId(numeroCessao);
      const json = responseList.data;
      setNomeCessao(json.nome);
      
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarTiposArquivos();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <strong>Processamento de Arquivos em Lote</strong>
                </div>

                <div className="row" style={{ display: 'flex', textAlign: 'center' }}>
                  <label style={{ marginTop: '10px' }}>Tipo de Processamento de Arquivo</label>
                  <div style={{ display: 'flex', width: '100%', backgroundColor: 'white', justifyContent: 'center' }}>
                    <select
                      className="form-select"
                      style={{ backgroundColor: 'white', marginRight: '10px', width: '30%', textAlignLast: 'center' }}
                      value={tipoArquivoProcessamento}
                      onChange={(e) => { handleTipoArquivo(e); setExibeDetalhe(false); setNomeCessao(''); setNumeroCessao(''); }}
                    >
                      <option value="" disabled>{"Selecione uma opção"}</option>
                      {tipoArquivo.map((item, index) => (
                        <option key={index} value={item}>{item.replace(/_/g, " EM ")}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {exibeDetalhe && (
                  <>
                    {tipoArquivoProcessamento === 'CESSAO_LOTE' && (
                      <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Código Cessão <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control form-control-sm brds" style={{ color: 'Black', backgroundColor: 'White' }}
                              value={numeroCessao} onChange={(e) => { setNumeroCessao(e.target.value); setNomeCessao(''); }}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <button className="input-group-btn" style={{
                            backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                            justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                          }}
                            onClick={listarCessao}
                          >
                            <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                          </button>
                        </div>
                      </div>
                    )}
                    <>
                      <div className="" style={{ marginTop: '10px' }}>
                        <div className="row">
                          {tipoArquivoProcessamento === 'CESSAO_LOTE' && (
                            <div className="col-md-3" style={{ paddingRight: '10px' }}>
                              <label style={{ width: '100%' }}>Nome Cessão</label>
                            </div>
                          )}
                          {processamento !== 'CESSAO' && (
                            <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              <label style={{ width: '100%' }}>Data Movimento</label>
                            </div>
                          )}
                        </div>
                        <div className="row" style={{ marginTop: '5px' }}>
                          {tipoArquivoProcessamento === 'CESSAO_LOTE' && (
                            <div className="col-md-3" style={{ paddingRight: '10px' }}>
                              <input type="text" value={nomeCessao} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                            </div>
                          )}
                          {processamento !== 'CESSAO' && (
                            <div className="col-md-2" style={{ paddingLeft: '10px' }}>
                              <InputMask required mask="99/99/9999" className="form-control" type="text" value={dtMovimento}
                                onChange={(e) => setDtMovimento(e.target.value.replace(/_/g, ""))}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-md-5">
                          <button className="input-group-btn" style={{
                            width: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#000041', borderColor: '#000041',
                            justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                          }}
                            onClick={handleDownloadModelo}
                          >
                            <FontAwesomeIcon icon={faDownload} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                            <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Download Arquivo Modelo {tipoArquivoProcessamento.replace(/_/g, " EM ")}</strong>
                          </button>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <span>Arquivo</span>
                            <br />
                            <input
                              style={{ backgroundColor: 'black', border: '1px solid black', color: 'White', width: '90%' }}
                              type="file"
                              className="form-control-file btn btn-info"
                              onChange={(e) => handleClickUpload(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group" style={{ marginTop: '25px' }}>
                            <a className="btn btn-success" onClick={uploadArquivo}>
                              <FontAwesomeIcon icon={faUpload} style={{ color: 'White', fontSize: 'larger', marginTop: '5px', marginRight: '5px' }} />
                              Importar Arquivo
                            </a>
                          </div>
                        </div>
                      </div>

                      {arquivosDTO && arquivosDTO.length > 0 ? (
                        <>
                          <div className="row" style={{ justifyContent: 'space-between' }}>
                            <div className="col-md-3">
                              <label style={{ marginTop: '20px', fontWeight: 'bold', fontSize: 'larger' }}>Arquivos Processados</label>
                            </div>
                            <div className="col-md-1">
                              <div className="form-group" style={{ marginTop: '19px', borderColor: 'black', color: 'black' }}>
                                <button onClick={handleRefresh} style={{ display: 'inline-block', border: '1px solid black', borderRadius: '4px', padding: '5px', backgroundColor: 'black' }}>
                                  <FontAwesomeIcon icon={faRefresh} style={{ marginTop: '5px', fontSize: 'larger', color: 'white' }} />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }} className="table-responsive">
                            <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                              <tbody>
                                <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black', position: 'sticky' }}>
                                  <th style={{ textAlign: 'center' }} scope="col">ID</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Arquivo</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Data Movimento</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Status Processamento</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Data Início</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Data Fim</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Mensagem</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Detalhe</th>
                                  {processamento !== 'CESSAO' && (
                                    <th style={{ textAlign: 'center' }} scope="col">Reprocessar</th>
                                  )}
                                  <th style={{ textAlign: 'center' }} scope="col">Download</th>
                                </tr>
                                {arquivosDTO.map((item) => (
                                  <tr className="GridRowStyle" key={item.id}>
                                    <td style={{ textAlign: 'center' }}>{item.id}</td>
                                    <td style={{ textAlign: 'center' }}>{item.nomeArquivo}</td>
                                    <td style={{ textAlign: 'center' }}>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy') : ''}</td>
                                    <td style={{ textAlign: 'center' }}>{item.statusProcessamento ? StatusProcessamentoEnum.find(i => i.value === item.statusProcessamento.toString())?.label : ''}</td>
                                    <td style={{ textAlign: 'center' }}>{item.dtInicioProcessamento ? format(new Date(item.dtInicioProcessamento), 'dd/MM/yyyy HH:mm:ss') : ''}</td>
                                    <td style={{ textAlign: 'center' }}>{item.dtFimProcessamento ? format(new Date(item.dtFimProcessamento), 'dd/MM/yyyy HH:mm:ss') : ''}</td>
                                    <td style={{ textAlign: 'center' }}>{item.mensagem}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      <a style={{ cursor: 'pointer', display: 'inline-block' }} href={`/backoffice-arquivo-lote/detalhe/${item.id}`}>
                                        <FontAwesomeIcon icon={faEye} style={{ fontSize: 'larger', marginTop: '5px', marginRight: '5px', color: 'black' }} />
                                      </a>
                                    </td>
                                    {processamento !== 'CESSAO' && (
                                      <td style={{ textAlign: 'center' }}>
                                        <a onClick={() => reprocessarArquivo(item.id!)} style={{ cursor: 'pointer', display: 'inline-block' }}>
                                          <FontAwesomeIcon icon={faRecycle} style={{ fontSize: 'larger', marginTop: '5px', marginRight: '5px' }} />
                                        </a>
                                      </td>
                                    )}
                                    <td style={{ textAlign: 'center' }}>
                                      <a onClick={() => handleDownloadArquivo(item.caminhoArquivo, item.nomeArquivo)} style={{ cursor: 'pointer', display: 'inline-block' }}>
                                        <FontAwesomeIcon icon={faDownload} style={{ fontSize: 'larger', marginTop: '5px', marginRight: '5px' }} />
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <div className="row" style={{ justifyContent: 'space-between' }}>
                          <div className="col-md-3">
                            <label style={{ color: 'red', marginTop: '10px' }}>Não há registros</label>
                          </div>
                          <div className="col-md-1">
                            <div style={{ marginTop: '19px', borderColor: 'black', color: 'black' }}>
                              <a onClick={() => setRefresh(!refresh)} style={{ cursor: 'pointer', display: 'inline-block', border: '1px solid black', borderRadius: '4px', padding: '5px', backgroundColor: 'black' }}>
                                <FontAwesomeIcon icon={faRefresh} style={{ marginTop: '5px', fontSize: 'larger', color: 'white' }} />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );


}

export default ArquivoLoteComponent;