import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Spinner from '../../../shared/spinner';
import { Orgao } from '../../../shared/model/orgao';
import { ButtonAlterarOrgao, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import EmpregadorService from '../../../services/empregadorService';

function OrgaoComponent() {
  const location = useLocation();
  const { idEmpregador } = location.state || {};

  const [loading, setLoading] = useState(true);
  const [orgao, setOrgao] = useState<Orgao[]>([]);
  const [id, setId] = useState<number>();
  const [ativo, setAtivo] = useState<boolean>(false);
  const [nome, setNome] = useState('');
  const [codigoExterno, setCodigoExterno] = useState<number>();
  const [codigoAverbacao, setCodigoAverbacao] = useState<number>();
  const [alteracao, setAlteracao] = useState<boolean>(false);

  const empregadorService: EmpregadorService = new EmpregadorService();

  const listarPorEmpregador = async () => {
    try {
      setLoading(true);
      limparCampos();
      setAlteracao(false);
      const responseList = await empregadorService.listarOrgaoPorEmpregador(idEmpregador);
      const jsonListOrgao = responseList.data;
      setOrgao(jsonListOrgao);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarOrgao = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }
    if (confirmacao) {
      try {
        await empregadorService.ativarInativarOrgao(id);
        listarPorEmpregador();
      } catch (err) {
        console.error(err);
      }
    }
  }

  const alterarOrgao = (id: number, nome: string, ativo: boolean, codigoExterno: number, codigoAverbacao: number) => {

    setAlteracao(true);
    setId(id);
    setNome(nome);
    setAtivo(ativo);
    setCodigoExterno(codigoExterno);
    setCodigoAverbacao(codigoAverbacao);
  }

  const limparCampos = () => {
    setId(NaN);
    setNome('');
    setCodigoAverbacao(NaN);
    setCodigoExterno(NaN);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      let org: Orgao = new Orgao();
      org.idEmpregador = parseInt(idEmpregador!);
      org.id = id!;
      org.nome = nome;
      org.ativo = ativo;
      org.codigoExterno = codigoExterno!;
      org.codigoAverbacao = codigoAverbacao!;
      await empregadorService.incluirAlterarOrgao(org)
        .then(() => setLoading(false));

      listarPorEmpregador();
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarPorEmpregador();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Parâmetros Cartão &gt; Empregador  &gt; Órgão</h5>
          <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
            Voltar
          </Button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-md-2">
              <label>Código Externo</label>
              <InputMask mask="999999999" type="text" className="form-control" value={codigoExterno} onChange={(e) => setCodigoExterno(parseInt((e.target.value).replace(/_/g, "")))} />
            </div>
            <div className="col-md-2">
              <label>Código Averbação</label>
              <InputMask mask="999999999" type="text" className="form-control" value={codigoAverbacao} onChange={(e) => setCodigoAverbacao(parseInt((e.target.value).replace(/_/g, "")))} />
            </div>
            <div className="col-md-4">
              {alteracao ? (
                <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                  <a >
                    <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Alterar Órgão</button>
                  </a>
                  <a >
                    <button className="w-40 btn btn-lg" id="App-button-verde" onClick={() => { setAlteracao(false); limparCampos() }}>Cancelar Alteração</button>
                  </a>
                </div>
              ) : (
                <div className="col-xl" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                  <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Adicionar Órgão</button>
                </div>
              )}
            </div>
          </div>
        </form>

        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Código Externo</th>
                    <th scope="col">Código Averbação</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orgao.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.nome}</td>
                      <td>{item.codigoExterno}</td>
                      <td>{item.codigoAverbacao}</td>
                      <td>
                        <ButtonAlterarOrgao onClick={() => alterarOrgao(item.id!, item.nome!, item.ativo!, item.codigoExterno!, item.codigoAverbacao!)} />
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarOrgao(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarOrgao(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OrgaoComponent;