import createAxiosInstance from './axiosConfig';
import { Parametros } from '../shared/model/parametros';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/parametrosController');

export default class ParametrosService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosParametros() {
    return await this.axios.get(`/listarTodosParametros`);
  };

  async alterarParametros(dto: Parametros) {
    return await this.axios.post(`/alterarParametros`, dto);
  };

}