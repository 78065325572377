import createAxiosInstance from './axiosConfig';
import { PromotoraDTO } from '../shared/dto/promotoraDTO';
import { FinanciamentoPromotoraDTO } from '../shared/dto/financiamentoPromotoraDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/promotoraController');

export default class PromotoraService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodasPromotorasAtivas() {
    return await this.axios.get(`/listarTodasPromotorasAtivas`);
  };

  async listarTodasPromotoras() {
    return await this.axios.get(`/listarTodasPromotoras`);
  };

  async ativarInativarPromotora(id: string) {
    return await this.axios.get(`/ativarInativarPromotora?id=${id}`);
  };

  async listarPromotoraPorId(id: string) {
    return await this.axios.get(`/listarPromotoraPorId?id=${id}`);
  };

  async incluirAlterarPromotora(dto: PromotoraDTO) {
    return await this.axios.post(`/incluirAlterarPromotora`, dto);
  };

  async listarFinanciamentoPromotora(idFinanciamento: string) {
    return await this.axios.get(`/listarFinanciamentoPromotora?idFinanciamento=${idFinanciamento}`);
  };

  async incluirAlterarFinanciamentoPromotora(idFinanciamento: string, dto: FinanciamentoPromotoraDTO[]) {
    return await this.axios.post(`incluirAlterarFinanciamentoPromotora?financiamentoId=${idFinanciamento}`, dto);
  };

}