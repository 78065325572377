import React, { useEffect, useState } from 'react';
import pkg from '../../../package.json'; 
import VersaoSistemaService from '../services/versaoSistemaService';

const Rodape = () => {
  const [apiVersion, setApiVersion] = useState<string | null>(null);
  const versaoSistemaService = new VersaoSistemaService();

  useEffect(() => {
    const fetchApiVersion = async () => {
      try {
        const response = await versaoSistemaService.getVersionApi();
        setApiVersion(response.data); 
      } catch (err) {
        console.error('Erro ao buscar versão da API', err);
      }
    };
    
    fetchApiVersion();
  }, []);

  return (
    <footer style={{ marginTop: '20px', fontSize: '12px', color: '#666', textAlign: 'center' }}>
      &copy; BPOConsig Serviços LTDA. Todos os direitos reservados.
      <br />
      Versão Plataforma: {pkg.version} | Versão API: {apiVersion ? apiVersion : 'Carregando...'}
    </footer>
  );
};

export default Rodape;
