import { RotateLoader } from 'react-spinners';

const override ={display: 'flex', margin: 'auto'};


const Spinner = ({loading} : {loading:boolean}) => {
  return (
    <div className="sweet-loading">
      <RotateLoader color={'#5a8e91'} loading={loading} cssOverride={override} />
    </div>
  );
};

export default Spinner;