export const Situacoes = [
    { value: "", label: "" },
    { value: "CAD", label: "CAD" },
    { value: "AND", label: "AND" },
    { value: "PEN", label: "PEN" },
    { value: "APR", label: "APR" },
    { value: "LIB", label: "LIB" },
    { value: "PAG", label: "PAG" },
    { value: "INT", label: "INT" },
    { value: "REP", label: "REP" },
];

export const SituacoesOpcoes = ["Simulação", "Cadastrado", "Andamento", "Pendente", "Aprovado", "Liberado", "Pago", "Integrado", "Reprovado"];

  