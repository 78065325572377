import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import InputMask from 'react-input-mask';
import { format, parse } from 'date-fns';
import { Empregador } from '../../../shared/model/empregador';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { HistoricoFinanceiro } from '../../../shared/model/historicoFinanceiro';
import { RelatoriosDTO } from '../../../shared/dto/relatoriosDTO';
import ArquivoService from '../../../services/arquivoService';
import EmpregadorService from '../../../services/empregadorService';
import HistoricoFinanceiroService from '../../../services/historicoFinanceiroService';

function MovimentacaoFinanceiraComponent() {
  const [loading, setLoading] = useState(false);

  const [dtBase1, setDtBase1] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtBase2, setDtBase2] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [availableHistoricosFinanceiro, setAvailableHistoricosFinanceiro] = useState<HistoricoFinanceiro[]>([]);
  const [selectedHistoricosFinanceiro, setSelectedHistoricosFinanceiro] = useState<HistoricoFinanceiro[]>([]);
  const [selectedToMoveHistoricosFinanceiro, setSelectedToMoveHistoricosFinanceiro] = useState<HistoricoFinanceiro[]>([]);
  const [selectedFromSelectedHistoricosFinanceiro, setSelectedFromSelectedHistoricosFinanceiro] = useState<HistoricoFinanceiro[]>([]);

  const [availableEmpregadores, setAvailableEmpregadores] = useState<Empregador[]>([]);
  const [selectedEmpregadores, setSelectedEmpregadores] = useState<Empregador[]>([]);
  const [selectedToMoveEmpregadores, setSelectedToMoveEmpregadores] = useState<Empregador[]>([]);
  const [selectedFromSelectedEmpregadores, setSelectedFromSelectedEmpregadores] = useState<Empregador[]>([]);

  const arquivoService: ArquivoService = new ArquivoService();
  const empregadorService: EmpregadorService = new EmpregadorService();
  const historicoFinanceiroService: HistoricoFinanceiroService = new HistoricoFinanceiroService();

  const listarHistoricosFinanceiro = async () => {
    setLoading(true);
    try {
      const response = await historicoFinanceiroService.listarTodasHistoricoFinanceiros();
      setAvailableHistoricosFinanceiro(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarEmpregadores = async () => {
    setLoading(true);
    try {
      const response = await empregadorService.listarTodosEmpregadoresAtivos();
      setAvailableEmpregadores(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const gerarRelatorio = async () => {
    if (selectedEmpregadores.length <= 0) return alert('Selecione ao menos um Empregador');
    if (selectedHistoricosFinanceiro.length <= 0) return alert('Selecione ao menos uma Movimentoção Financeira');
    if (dtBase1 === '' || dtBase2 === '') return alert('Preencha os campos de datas!');
    if (dtBase1 !== '' && dtBase2 !== '' && parse(dtBase1, "dd/MM/yyyy", new Date()) > parse(dtBase2, "dd/MM/yyyy", new Date())) return alert('Data inicial deve ser inferior a data final');
    setLoading(true);
    try {
      const dto: RelatoriosDTO = new RelatoriosDTO();
      dto.empregadores = selectedEmpregadores;
      dto.dtBase1 = parse(dtBase1, "dd/MM/yyyy", new Date());
      dto.dtBase2 = parse(dtBase2, "dd/MM/yyyy", new Date());
      dto.historicosFinanceiros = selectedHistoricosFinanceiro;

      const response = await arquivoService.gerarRelatorioDeMovimentacaoFinanceira(dto);

      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        alert(errorText);
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Relatorio_Movimentação_Financeira.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        alert(errorText);
      } else {
        alert('Ocorreu um erro ao gerar o relatório');
      }
      console.error(err);
    }
  };

  useEffect(() => {
    listarHistoricosFinanceiro();
    listarEmpregadores();
  }, []);

  const moveToSelectedHistoricosFinanceiro = () => {
    const newSelected = [...selectedHistoricosFinanceiro, ...selectedToMoveHistoricosFinanceiro];
    const newAvailable = availableHistoricosFinanceiro.filter(
      (historicoFinanceiro) => !selectedToMoveHistoricosFinanceiro.includes(historicoFinanceiro)
    );

    setAvailableHistoricosFinanceiro(newAvailable);
    setSelectedHistoricosFinanceiro(newSelected);
    setSelectedToMoveHistoricosFinanceiro([]);
  };

  const moveToAvailableHistoricosFinanceiro = () => {
    const newAvailable = [...availableHistoricosFinanceiro, ...selectedFromSelectedHistoricosFinanceiro];
    const newSelected = selectedHistoricosFinanceiro.filter(
      (historicoFinanceiro) => !selectedFromSelectedHistoricosFinanceiro.includes(historicoFinanceiro)
    );

    setAvailableHistoricosFinanceiro(newAvailable);
    setSelectedHistoricosFinanceiro(newSelected);
    setSelectedFromSelectedHistoricosFinanceiro([]);
  };

  const moveAllToSelectedHistoricosFinanceiro = () => {
    const newSelected = [...selectedHistoricosFinanceiro, ...availableHistoricosFinanceiro];
    setSelectedHistoricosFinanceiro(newSelected);
    setAvailableHistoricosFinanceiro([]);
  };

  const moveAllToAvailableHistoricosFinanceiro = () => {
    const newAvailable = [...availableHistoricosFinanceiro, ...selectedHistoricosFinanceiro];
    setAvailableHistoricosFinanceiro(newAvailable);
    setSelectedHistoricosFinanceiro([]);
  };

  //EMPREGADOR

  const moveToSelectedEmpregadores = () => {
    const newSelected = [...selectedEmpregadores, ...selectedToMoveEmpregadores];
    const newAvailable = availableEmpregadores.filter(
      (empregador) => !selectedToMoveEmpregadores.includes(empregador)
    );

    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores(newSelected);
    setSelectedToMoveEmpregadores([]);
  };

  const moveToAvailableEmpregadores = () => {
    const newAvailable = [...availableEmpregadores, ...selectedFromSelectedEmpregadores];
    const newSelected = selectedEmpregadores.filter(
      (empregador) => !selectedFromSelectedEmpregadores.includes(empregador)
    );

    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores(newSelected);
    setSelectedFromSelectedEmpregadores([]);
  };

  const moveAllToSelectedEmpregadores = () => {
    const newSelected = [...selectedEmpregadores, ...availableEmpregadores];
    setSelectedEmpregadores(newSelected);
    setAvailableEmpregadores([]);
  };

  const moveAllToAvailableEmpregadores = () => {
    const newAvailable = [...availableEmpregadores, ...selectedEmpregadores];
    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores([]);
  };

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>Relatório de Movimentação Financeira</strong>
            </div>
            <div className="card-body">
              <div className="" style={{ marginTop: '10px' }}>
                <div className="row">
                  <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <label>Período de Data Base <span style={{ color: 'red' }}>*</span></label>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                  <div className="col-md-4">
                    <thead>
                      <tr>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase1} onChange={(e) => setDtBase1((e.target.value).replace(/_/g, ""))} /></th>
                        <th style={{ width: "15px" }}> a </th>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase2} onChange={(e) => setDtBase2((e.target.value).replace(/_/g, ""))} /></th>
                      </tr>
                    </thead>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <h6>Histórico Financeiro Disponíveis</h6>
                  <select
                    multiple
                    value={selectedToMoveHistoricosFinanceiro.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const historicoFinanceiro = availableHistoricosFinanceiro.find(p => p.id!.toString() === options[i].value);
                          if (historicoFinanceiro) {
                            selected.push(historicoFinanceiro);
                          }
                        }
                      }
                      setSelectedToMoveHistoricosFinanceiro(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {availableHistoricosFinanceiro.map((historicoFinanceiro) => (
                      <option key={historicoFinanceiro.id} value={historicoFinanceiro.id!.toString()}>
                        {historicoFinanceiro.id} - {historicoFinanceiro.nome!} ({historicoFinanceiro.tipoEntradaSaida})
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <button onClick={moveToSelectedHistoricosFinanceiro} style={{ margin: '5px' }}>{'>'}</button>
                  <button onClick={moveAllToSelectedHistoricosFinanceiro} style={{ margin: '5px' }}>{'>>'}</button>
                  <button onClick={moveToAvailableHistoricosFinanceiro} style={{ margin: '5px' }}>{'<'}</button>
                  <button onClick={moveAllToAvailableHistoricosFinanceiro} style={{ margin: '5px' }}>{'<<'}</button>
                </div>
                <div>
                  <h6>Histórico Financeiro Selecionados</h6>
                  <select
                    multiple
                    value={selectedFromSelectedHistoricosFinanceiro.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const historicoFinanceiro = selectedHistoricosFinanceiro.find(p => p.id!.toString() === options[i].value);
                          if (historicoFinanceiro) {
                            selected.push(historicoFinanceiro);
                          }
                        }
                      }
                      setSelectedFromSelectedHistoricosFinanceiro(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {selectedHistoricosFinanceiro.map((historicoFinanceiro) => (
                      <option key={historicoFinanceiro.id} value={historicoFinanceiro.id!.toString()}>
                        {historicoFinanceiro.id} - {historicoFinanceiro.nome!} ({historicoFinanceiro.tipoEntradaSaida})
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <h6>Empregadores Disponíveis </h6>
                  < select
                    multiple
                    value={selectedToMoveEmpregadores.map((e) => e.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const empregador = availableEmpregadores.find(e => e.id!.toString() === options[i].value);
                          if (empregador) {
                            selected.push(empregador);
                          }
                        }
                      }
                      setSelectedToMoveEmpregadores(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {
                      availableEmpregadores.map((empregador) => (
                        <option key={empregador.id} value={empregador.id!.toString()} >
                          {empregador.id} - {empregador.nome!}
                        </option>
                      ))
                    }
                  </select>
                </div>
                < div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <button onClick={moveToSelectedEmpregadores} style={{ margin: '5px' }}> {'>'} </button>
                  < button onClick={moveAllToSelectedEmpregadores} style={{ margin: '5px' }}> {'>>'} </button>
                  < button onClick={moveToAvailableEmpregadores} style={{ margin: '5px' }}> {'<'} </button>
                  < button onClick={moveAllToAvailableEmpregadores} style={{ margin: '5px' }}> {'<<'} </button>
                </div>
                < div >
                  <h6>Empregadores Selecionados </h6>
                  < select
                    multiple
                    value={selectedFromSelectedEmpregadores.map((e) => e.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const empregador = selectedEmpregadores.find(e => e.id!.toString() === options[i].value);
                          if (empregador) {
                            selected.push(empregador);
                          }
                        }
                      }
                      setSelectedFromSelectedEmpregadores(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {
                      selectedEmpregadores.map((empregador) => (
                        <option key={empregador.id} value={empregador.id!.toString()} >
                          {empregador.id} - {empregador.nome!}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>

              <div className="row" style={{ marginTop: '20px', height: '50px', maxWidth: '70%' }}>
                <div className="col-md-3">
                  <button className="input-group-btn"
                    style={{
                      width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                      justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                    }}onClick={gerarRelatorio}>
                    <a>
                      <FontAwesomeIcon icon={faPrint} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                    </a>
                    <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Gerar Relatório</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
}
export default MovimentacaoFinanceiraComponent;