import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { FinanciamentoPromotoraDTO } from '../../../shared/dto/financiamentoPromotoraDTO';
import PromotoraService from '../../../services/promotoraService';

function FinanciamentoPromotora() {
  const location = useLocation();
  const { idFinanciamento, codigoFinanciamento, nomeFinanciamento } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [financiamentoPromotoras, setFinanciamentoPromotoras] = useState<FinanciamentoPromotoraDTO[]>([]);
  const [selecionarTudo, setSelecionarTudo] = useState(false);

  const promotoraService: PromotoraService = new PromotoraService();

  const listarPromotorasPorFinancinamento = async () => {
    try {
      const response = await promotoraService.listarFinanciamentoPromotora(idFinanciamento);
      setFinanciamentoPromotoras(response.data);

    } catch (err) {
      console.error(err);
    }
  };

  const handleAtivoChange = (id: any) => {
    setFinanciamentoPromotoras((prevData) =>
      prevData.map((item) =>
        item.promotora === id ? {
          ...item, ativo: !item.ativo
        } : item
      )
    );
  };

  const selecionarDeselecionarTodos = (marcar: boolean) => {
    setSelecionarTudo(!selecionarTudo);
    setFinanciamentoPromotoras((prevData) =>
      prevData.map((item) => ({ ...item, ativo: marcar }))
    );
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await promotoraService.incluirAlterarFinanciamentoPromotora(idFinanciamento, financiamentoPromotoras)
        .then((response) => setLoading(false));

      console.log(response)
      alert('Atualização realizada com sucesso!');
      window.history.back();
    } catch (err) {
      setLoading(false);
      alert('Ocorreu um erro ao atualizar listar!');
      console.error(err);
    }
  }

  useEffect(() => {
    listarPromotorasPorFinancinamento();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Tabela de Financiamento &gt; Promotoras</h5>
        <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
          Voltar
        </Button>
      </div>
      {loading ? <Spinner loading={loading} /> : (
        <>
          <div className="row">
            <div className="col-md-1">
              <label>ID</label>
              <text className="form-control" display={'none'}>{idFinanciamento}</text>
            </div>
            <div className="col-md-2">
              <label>Código</label>
              <text className="form-control" display={'none'}>{codigoFinanciamento}</text>
            </div>
            <div className="col-md-3">
              <label>Nome</label>
              <text className="form-control" display={'none'}>{nomeFinanciamento}</text>
            </div>
          </div>
          <br></br>
          <div>
            {selecionarTudo ? (
              <>
                <input type="checkbox" checked={selecionarTudo} onChange={() => selecionarDeselecionarTodos(false)} />
                <label>  Deselecionar Tudo</label>
              </>
            ) : (
              <>
                <input type="checkbox" checked={selecionarTudo} onChange={() => selecionarDeselecionarTodos(true)} />
                <label>  Selecionar Tudo</label>
              </>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="table-responsive">
              <div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Ativo</th>
                      <th scope="col">Promotoras</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financiamentoPromotoras.map((item) => (
                      <tr>
                        <td><input type="checkbox" checked={item.ativo} onChange={() => handleAtivoChange(item.promotora)} /></td>
                        <td>{item.promotora} - {item.nomePromotora}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Atualizar Listar</button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default FinanciamentoPromotora;