import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Motivo } from './model/motivo';
import MotivoService from '../services/motivoService';


interface ReprovarOuPendenciarPropostaModalProps {
  showModal: boolean;
  handleModalCancel: () => void;
  handleModalConfirm: (selectedMotivo: string) => void;
  tipoPendenciaRecusa: string;
}

const ReprovarOuPendenciarPropostaModal: React.FC<ReprovarOuPendenciarPropostaModalProps> = ({ showModal, handleModalCancel, handleModalConfirm, tipoPendenciaRecusa }) => {

  const [motivos, setMotivos] = useState<Motivo[]>([]);
  const [motivoSelect, setMotivoSelect] = useState('');

  const motivoService: MotivoService = new MotivoService();

  const listarMotivos = async () => {
    try {
      const responseList = await motivoService.listarTodosMotivosPorPendenciaOuRecusa(tipoPendenciaRecusa);
      const jsonListMotivo = responseList.data;
      setMotivos(jsonListMotivo);
      setMotivoSelect(jsonListMotivo[0]?.id?.toString()!);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    listarMotivos();
  }, [tipoPendenciaRecusa]);

  return (
    <Modal show={showModal} onHide={handleModalCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{tipoPendenciaRecusa === 'R' ? 'Reprovar Propostas' : 'Pendenciar Propostas'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Motivo</label>
          <select className="form-select" value={motivoSelect} onChange={(e) => setMotivoSelect(e.target.value)}>
            {motivos.map((item) => (
              <option key={item.id} value={item.id!}>
                {item.descricao}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalCancel}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={() => handleModalConfirm(motivoSelect)}>
          {tipoPendenciaRecusa === 'R' ? 'Reprovar' : 'Pendenciar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReprovarOuPendenciarPropostaModal;
