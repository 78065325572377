import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import InputMask from 'react-input-mask';
import { format, parse } from 'date-fns';
import { Usuario } from '../../shared/model/usuario';
import { cpf as cpfValidacao } from 'cpf-cnpj-validator';
import UsuarioService from '../../services/usuarioService';

function UsuarioInclusaoAlteracao() {

  const tpUsuario = [
    { value: '', label: '' },
    { value: 'MASTER', label: 'Master' },
    { value: 'MATRIZ', label: 'Matriz' },
    { value: 'PROMOTORA', label: 'Promotora' },
    { value: 'SUPERVISOR', label: 'Supervisor' },
    { value: 'PONTOVENDA', label: 'Ponto Venda' },
  ];

  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [dtNascimento, setDtNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState(tpUsuario[0].value);
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [senha2, setSenha2] = useState('');

  const [loading, setLoading] = useState(false);

  const usuarioService: UsuarioService = new UsuarioService();

  const listarUsuarioPorId = async () => {
    setLoading(true);
    try {
      const response = await usuarioService.listarUsuarioPorId(id);
      const json = response.data;

      setNome(json.nome);
      setCpf(json.cpf);
      setDtNascimento(json.dtNascimento ? format(new Date(json.dtNascimento), 'dd/MM/yyyy') : '');
      setEmail(json.email);
      setTipoUsuario(json.tipoUsuario);
      setLogin(json.login);

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      alert(err.response.data);
      console.error(err);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!cpfValidacao.isValid(cpf)) return alert('CPF Inválido');
    if (senha !== senha2) return alert('As senhas devem ser iguais!');

    let usuario: Usuario = new Usuario();
    usuario.id = id;
    usuario.nome = nome;
    usuario.cpf = cpf;
    usuario.dtNascimento = parse(dtNascimento, 'dd/MM/yyyy', new Date());
    usuario.email = email;
    usuario.tipoUsuario = tipoUsuario;
    usuario.login = login;
    usuario.senha = senha;

    try {
      await usuarioService.incluirAlterarUsuario(usuario);
      
      setLoading(false);
      if (tipo === 'inclusao') {
        alert('Usuário salvo com sucesso!');
      } else {
        alert('Usuário alterado com sucesso!');
      }
      window.history.back();
    } catch (err: any) {
      setLoading(false);
      alert(err.response.data);
      console.error(err);
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarUsuarioPorId();
    }
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Usuário &gt; Cadastro</h5> : (
                <h5>Usuário &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row" style={{ marginTop: '20px' }}>
            {tipo === 'alteracao' ? (
              <div className="col-md-1">
                <label>Código</label>
                <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
              </div>
            ) : null}
            <div className="col-md-4">
              <label>Nome Completo <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>CPF <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf!} onChange={(e) => { setCpf(e.target.value); }} />
              </div>
            </div>
            <div className="col-xl">
              <label>Data Nascimento <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99/99/9999" className="form-control" type="text" value={dtNascimento} onChange={(e) => setDtNascimento((e.target.value).replace(/_/g, ""))} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label>E-mail <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="col-md-4">
              <label>Tipo de Usuário <span style={{ color: 'red' }}>*</span></label>
              <select required className="form-select" defaultValue={tpUsuario[0].value} value={tipoUsuario} onChange={(e) => setTipoUsuario(e.target.value)}>
                {tpUsuario.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Tipo de Acesso <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select">
                <option>Interno</option>
                <option>Externo</option>
              </select>
            </div>
          </div>

          {tipo === 'alteracao' ? (
            <div className="row">
              <div className="col-xl">
                <label>Login</label>
                <input type="text" className="form-control" value={login} readOnly style={{ backgroundColor: 'LightGrey' }} />
              </div>
              <div className="col-xl">
                <label>Senha </label>
                <input type="password" className="form-control" value={senha} onChange={(e) => setSenha(e.target.value)} />
              </div>
              <div className="col-xl">
                <label>Confirmação Senha</label>
                <input type="password" className="form-control" value={senha2} onChange={(e) => setSenha2(e.target.value)} />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xl">
                <label>Login <span style={{ color: 'red' }}>*</span></label>
                <input required type="text" className="form-control" value={login} onChange={(e) => setLogin(e.target.value)} />
              </div>
              <div className="col-xl">
                <label>Senha <span style={{ color: 'red' }}>*</span></label>
                <input required type="password" className="form-control" value={senha} onChange={(e) => setSenha(e.target.value)} />
              </div>
              <div className="col-xl">
                <label>Confirmação Senha <span style={{ color: 'red' }}>*</span></label>
                <input required type="password" className="form-control" value={senha2} onChange={(e) => setSenha2(e.target.value)} />
              </div>
            </div>
          )}


          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Usuário' : 'Salvar Usuário'}</button>
              </div>
            </div>
          </div>
        </form>
      )
      }
    </div >
  );
}

export default UsuarioInclusaoAlteracao;