import createAxiosInstance from './axiosConfig';
import { GerenteDTO } from '../shared/dto/gerenteDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/gerenteController');

export default class SupervisorService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosGerentes() {
    return await this.axios.get(`/listarTodosGerentes`);
  };

  async listarTodosGerentesAtivos() {
    return await this.axios.get(`/listarTodosGerentesAtivos`);
  };

  async ativarInativarGerente(id: string) {
    return await this.axios.get(`ativarInativarGerente?id=${id}`);
  };

  async listarGerentePorId(id: string) {
    return await this.axios.get(`listarGerentePorId?id=${id}`);
  };

  async incluirAlterarGerente(dto: GerenteDTO) {
    return await this.axios.post(`/incluirAlterarGerente`, dto);
  };

}