import styled from 'styled-components';

export const Container = styled.section`

  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 3rem;
  box-shadow: 50px;


  @media(max-width: 460px) {
    padding: 1rem;
    background-color: #ffff;
    border: none;
  }

  background-color: #E6e7e8;

  border: 2px solid #D3D3D3;
  border-radius: 1%;
  margin: auto;

  .dados{
    margin-bottom: 1rem;
    border-bottom: 2px solid #D3D3D3;
    .title{
      width: auto;
    }
  }

  .formSelect{
    width: 20%;
  } 
  .formSelect2{
    width: 30%;
  }

  .formSelect3{
    width: 25%;
  }

  .button-area{
    Button{
      margin: 5px;
    }
  }

  .rmc-area{
    
    display: flex;
    flex-direction: row;
    .tabela{
      background-color: rgb(194 226 181);
      width: 40%;
      border-radius: 10px;
      height: 100px;
      padding: 1rem;
      margin-top: 1rem;
      .rmc{
        color: #316100;
      }
    }
    .rmc-button{
      margin-top: 2rem;
      margin-left: 2rem;
    }
    .rmc-field{
      margin-top: 1rem;
      margin-left: 1rem;
    }
    
  }

  .select-tab{
    margin-top: 1rem;
    .table{
      border-radius: 15px;
      background-color: #fff;
    }
    .table>thead{
      border-radius: 15px;
      background: rgb(122 164 167);
    }
    .table>tbody{
      .tr{
        .td{
          width: 1rem;
        }
      }
    }
  }

  .header-cards{
    border-radius: 15px 15px 0px 0px;
    color: rgb(49 97 0);
    height: 3rem;
    background: rgb(194 226 181);
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .input-card{
    padding: 1rem;
    border: 1px, black
  }

  .card-body{
    color: #495057;
    .card-field{
      font-weight: 500;
    }
    .card-text{
      margin-bottom: 1rem;;
    }
  }

  .saque{
    margin-top: 1rem;
    .button-area{
      Button{
        margin-top: 15px;
      }
    }
  }

`