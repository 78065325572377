import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Button, Form, Table } from 'react-bootstrap';
import { Promotora } from '../../shared/model/promotora';
import Spinner from '../../shared/spinner';
import { Container } from './style';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { PontoVendaDTO } from '../../shared/dto/pontoVendaDTO';
import { PromotoraEmpregadorDTO } from '../../shared/dto/promotoraEmpregadorDTO';
import { Orgao } from '../../shared/model/orgao';
import { RiCalculatorLine } from 'react-icons/ri';
import { BiDollar } from 'react-icons/bi';
import { Modal } from "react-bootstrap";
import { FaCheck } from 'react-icons/fa'
import { Financiamento } from '../../shared/model/financiamento';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { format } from 'date-fns';
import { cpf as cpfValidacao } from 'cpf-cnpj-validator';
import { CalculoResultadoSimulacaoDTO } from '../../shared/dto/calculoResultadoSimulacaoDTO';
import { ResultadoSimulacaoPropostaDTO } from '../../shared/dto/resultadoSimulacaoPropostaDTO';
import { PropostaDTO } from '../../shared/dto/propostaDTO';
import { Cliente } from '../../shared/model/Cliente';
import { useNavigate } from 'react-router-dom';
import PropostaService from '../../services/propostaService';
import PromotoraService from '../../services/promotoraService';
import PontoVendaService from '../../services/pontoVendaService';
import EmpregadorService from '../../services/empregadorService';
import FinanciamentoService from '../../services/financiamentoService';
import PromotoraEmpregadorService from '../../services/promotoraEmpregadorService';

function CartaoComponent() {
  const navigate = useNavigate();

  const tipoProposta = [
    { value: 'Cartão Sacado', label: 'Cartão Sacado' },
    { value: 'Cartão Sem Saque', label: 'Cartão Sem Saque' },
    { value: 'Saque Complementar', label: 'Saque Complementar' },
    { value: 'Saque Refin', label: 'Saque Refin' },
  ];

  const prazos = [
    { value: '24', label: '24' },
    { value: '36', label: '36' },
    { value: '48', label: '48' },
    { value: '60', label: '60' },
    { value: '72', label: '72' },
  ];

  const [promotora, setPromotora] = useState('');
  const [promotoras, setPromotoras] = useState<Promotora[]>([]);
  const [pontoVenda, setPontoVenda] = useState('');
  const [pontoVendas, setPontoVendas] = useState<PontoVendaDTO[]>([]);
  const [tpProposta, setTpProposta] = useState(tipoProposta[0].value);
  const [promotoraEmpregador, setPromotoraEmpregador] = useState<PromotoraEmpregadorDTO[]>([]);
  const [empregador, setEmpregador] = useState('');
  const [codigoExterno, setCodigoExterno] = useState('');
  const [orgao, setOrgao] = useState('');
  const [orgaos, setOrgaos] = useState<Orgao[]>([]);
  const [cpf, setCpf] = useState('');
  const [matricula, setMatricula] = useState('');
  const [nome, setNome] = useState('');
  const [dtNascimento, setDtNascimento] = useState('');
  const [codigoAutorizacao, setCodigoAutorizacao] = useState('');

  const [modalShow, setModalShow] = useState(false);
  const [valorRMC, setValorRMC] = useState('');
  const [valorRMCOnline, setValorRMCOnline] = useState('');
  const [rmcDisponivel, setRmcDisponivel] = useState(false);
  const [lotacao, setLotacao] = useState('');
  const [secretaria, setSecretaria] = useState('');
  const [situacao, setSituacao] = useState('');
  const [tabelaFinanciamentos, setTabelaFinanciamentos] = useState<Financiamento[]>([]);
  const [idFinanciamentoEscolhido, setIdFinanciamentoEscolhido] = useState('');
  const [financiamento, setFinanciamento] = useState('');
  const [prazo, setPrazo] = useState(prazos[0].value);

  const [showResultadoSimulacao, setShowResultadoSimulacao] = useState(false);
  const [valoresSimulacao, setValoresSimulacao] = useState<CalculoResultadoSimulacaoDTO>();

  const [valorParcela, setValorParcela] = useState<number>(0);
  const [valorSolicitado, setValorSolicitado] = useState<number>(0);
  const [resultadoSimulacaoFinal, setResultadoSimulacaoFinal] = useState<ResultadoSimulacaoPropostaDTO>();
  const [dtPrimeiroVencimento, setDtPrimeiroVencimento] = useState('');
  const [dtUltimoVencimento, setDtUltimoVencimento] = useState('');

  const [loading, setLoading] = useState(false);

  const propostaService: PropostaService = new PropostaService();
  const promotoraService: PromotoraService = new PromotoraService();
  const pontoVendaService: PontoVendaService = new PontoVendaService();
  const empregadorService: EmpregadorService = new EmpregadorService();
  const financiamentoService: FinanciamentoService = new FinanciamentoService();
  const promotoraEmpregadorService: PromotoraEmpregadorService = new PromotoraEmpregadorService();

  interface MyVerticallyCenteredModalProps {
    show: boolean;
    onHide: () => void;
    onModalClose: (valorRMC: string) => void;
  }

  const MyVerticallyCenteredModal: React.FC<MyVerticallyCenteredModalProps> = (props) => {
    const [valorRM, setValorRM] = useState('');
    const handleButtonClick = () => {
      if (valorRM === '' || valorRM === undefined) {
        alert('Valor RMC Inválido')
        return;
      }

      props.onModalClose(valorRM);
      props.onHide();
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Calculadora de Margem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Valor RMC <span style={{ color: 'red' }}>*</span></Form.Label>
          <input required type="number" className="form-control" value={valorRM} onChange={(e) => setValorRM(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleButtonClick()}>OK!</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  const listarPromotoras = async () => {
    setLoading(true);
    try {
      await promotoraService.listarTodasPromotorasAtivas()
        .then(resp => {
          setPromotoras(resp.data);
          setPromotora(resp.data[0].id?.toString()!);
          listarPontoVendas(resp.data[0].id?.toString()!);
          listarEmpregadorPorPromotora(resp.data[0].id?.toString()!);
          esconderCampos();
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      setPontoVenda('');
      setEmpregador('');
      setOrgao('');
      console.error(err);
    }
  }

  const listarPontoVendas = async (id: any) => {
    setLoading(true);
    try {
      await pontoVendaService.listarPontoVendaPorPromotora(id)
        .then(resp => {
          setPontoVendas(resp.data);
          setPontoVenda(resp.data[0].id?.toString()!);
          esconderCampos();
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      setPontoVenda('');
      setEmpregador('');
      setOrgao('');
      console.error(err);
    }
  }

  const listarEmpregadorPorPromotora = async (id: any) => {
    try {
      setLoading(true);
      await promotoraEmpregadorService.listarEmpregadorPorPromotora(id)
        .then(resp => {
          const jsonListPromotoraEmpregador = resp.data;

          setPromotoraEmpregador(jsonListPromotoraEmpregador);
          setEmpregador(jsonListPromotoraEmpregador[0].id?.toString()!);
          setCodigoExterno(jsonListPromotoraEmpregador[0].codigoExterno!);
          listarOrgaoPorEmpregador(jsonListPromotoraEmpregador[0].id?.toString()!);
          esconderCampos();
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      setEmpregador('');
      setOrgao('');
      console.error(err);
    }
  }

  const listarOrgaoPorEmpregador = async (id: any) => {
    try {
      setLoading(true);
      await empregadorService.listarOrgaoPorEmpregador(id)
        .then(resp => {
          const jsonListOrgao = resp.data;

          setOrgaos(jsonListOrgao);
          setOrgao(jsonListOrgao[0].id?.toString()!);
          esconderCampos();
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      setOrgao('');
      console.error(err);
    }
  }

  const handleEmpregadorChange = (e: any) => {
    setEmpregador(e.target.value);

    const selectedEmpregador = promotoraEmpregador.find(item => item.id === e.target.value);
    if (selectedEmpregador) {
      setCodigoExterno(selectedEmpregador.codigoExterno);
    }
    esconderCampos();
    listarOrgaoPorEmpregador(e.target.value);
  };

  const carregarTabelaFinanciamentos = async () => {
    if (valorRMCOnline !== '' && valorRMC > valorRMCOnline) {
      alert('Valor RMC alterado para maior que o valor RMC Online');
      return;
    } else if (dtNascimento === '' || dtNascimento.length < 10) {
      alert('Preencha Data de Nascimento')
    } else {
      try {
        setLoading(true);
        await financiamentoService.listarFinanciamentoPorPromotoraEmpregadorProposta(promotora, empregador, tpProposta)
          .then(resp => {
            const jsonListFinanciamento = resp.data;

            setTabelaFinanciamentos(jsonListFinanciamento);
            setLoading(false);
          })
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  }

  const esconderCampos = () => {
    setValorRMC('');
    setTabelaFinanciamentos([]);
    setIdFinanciamentoEscolhido('');
    setShowResultadoSimulacao(false);
  }

  const calcularMargem = async () => {
    if (promotora && pontoVenda && empregador && orgao && cpf && dtNascimento) {
      if (!cpfValidacao.isValid(cpf)) {
        alert('CPF Inválido');
        return;
      }
      console.log(orgao)
      setModalShow(true);
    }
  }

  const obterMargemOnline = async () => {
    if (promotora && pontoVenda && empregador && orgao && cpf) {
      if (!cpfValidacao.isValid(cpf)) {
        alert('CPF Inválido');
        return;
      }
      let cliente: Cliente = new Cliente();

      cliente.cpf = cpf;
      cliente.numeroBeneficiario = matricula === '' ? 0 + '' : matricula;
      cliente.codigoAutorizacao = codigoAutorizacao;

      setLoading(true);
      try {
        await propostaService.obterMargemOnline(codigoExterno, cliente)
          .then(resp => {
            const json = resp.data;
            setNome(json.nome);
            setRmcDisponivel(true);
            setValorRMC(parseFloat(json.valorMargem).toFixed(2).replace('.', ','));
            setValorRMCOnline(parseFloat(json.valorMargem).toFixed(2).replace('.', ','));
            setLotacao(json.lotacao);
            setMatricula(json.matricula);
            setSecretaria(json.secretaria);
            setSituacao(json.situacao);
            setLoading(false);
          })

      } catch (err: any) {
        setLoading(false);
        alert(err.response.data);
      }

    } else {
      alert('Preencha os campos obrigatórios');
    }
  }

  const handleInputChange = (event: any) => {
    setTabelaFinanciamentos([]);
    const inputValue = event.target.value;
    const cleanValue = inputValue.replace(/[,.]/g, '');
    const formattedValue = cleanValue.replace(/(\d+)(\d{2})$/, '$1,$2');
    setValorRMC(formattedValue);
  };

  const buscarValoresTabela = async (codigoFinanciamento: string) => {
    try {
      setLoading(true);
      let calculoResultadoSimulacaoDTO: CalculoResultadoSimulacaoDTO = new CalculoResultadoSimulacaoDTO();
      calculoResultadoSimulacaoDTO.promotora = promotora;
      calculoResultadoSimulacaoDTO.empregador = empregador;
      calculoResultadoSimulacaoDTO.financiamento = codigoFinanciamento;
      calculoResultadoSimulacaoDTO.produto = tabelaFinanciamentos.find(item => item.codigo === codigoFinanciamento)?.idProduto!;
      calculoResultadoSimulacaoDTO.valorRMC = valorRMC;
      calculoResultadoSimulacaoDTO.valorLimite = "0";
      calculoResultadoSimulacaoDTO.limiteCompra = "0";
      calculoResultadoSimulacaoDTO.limiteSaque = "0";
      calculoResultadoSimulacaoDTO.tarifaEmissao = "0";
      calculoResultadoSimulacaoDTO.anuidade = "0";

      await propostaService.simulacaoRMTTotal(calculoResultadoSimulacaoDTO)
        .then(resp => {
          const jsonList = resp.data;

          setValoresSimulacao(jsonList);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const resultadoSimulacaoParcela = async () => {
    if ((valorParcela !== 0 && valorSolicitado !== 0)) {
      alert('Informe apenas ou valor da parcela ou valor solicitado!');
      return;
    } else if ((valorParcela === null && valorSolicitado === null) || (valorParcela === 0 && valorSolicitado === 0)) {
      alert('Informe valor da parcela ou valor solicitado!');
      return;
    } else {
      let simulacaoPropostaDTO: ResultadoSimulacaoPropostaDTO = new ResultadoSimulacaoPropostaDTO();
      simulacaoPropostaDTO.promotora = promotora;
      simulacaoPropostaDTO.empregador = empregador;
      simulacaoPropostaDTO.financiamento = idFinanciamentoEscolhido;
      simulacaoPropostaDTO.produto = tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.idProduto!;
      simulacaoPropostaDTO.valorRMC = valorRMC;
      simulacaoPropostaDTO.valorParcela = valorParcela.toString();
      simulacaoPropostaDTO.valorSolicitado = valorSolicitado.toString();
      simulacaoPropostaDTO.prazo = prazo;

      simulacaoPropostaDTO.valorSolicit = "0";
      simulacaoPropostaDTO.valorIof = "0";
      simulacaoPropostaDTO.valorPrincipal = "0";
      simulacaoPropostaDTO.valorParc = "0";
      simulacaoPropostaDTO.valorRefin = "0";
      simulacaoPropostaDTO.valorCompra = "0";
      simulacaoPropostaDTO.valorLiquido = "0";
      simulacaoPropostaDTO.valorBruto = "0";
      simulacaoPropostaDTO.taxaClAM = "0";
      simulacaoPropostaDTO.taxaClAA = "0";
      simulacaoPropostaDTO.taxaCetAM = "0";
      simulacaoPropostaDTO.taxaCetAA = "0";
      simulacaoPropostaDTO.primeiroVencimento = new Date();
      simulacaoPropostaDTO.ultimoVencimento = new Date();



      try {
        setLoading(true);
        await propostaService.resultadoSimulacaoParcela(simulacaoPropostaDTO)
          .then(resp => {
            const jsonList = resp.data;

            setResultadoSimulacaoFinal(jsonList);
            setDtPrimeiroVencimento(format(new Date(jsonList.primeiroVencimento), 'dd/MM/yyyy'));
            setDtUltimoVencimento(format(new Date(jsonList.ultimoVencimento), 'dd/MM/yyyy'));
            setShowResultadoSimulacao(true);
            setLoading(false);
          })
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let propostaDTO: PropostaDTO = new PropostaDTO();
    let cliente: Cliente = new Cliente();

    cliente.cpf = cpf;
    const partesData = dtNascimento.split('/');
    const dataFormatada = partesData[2] + '-' + partesData[1] + '-' + partesData[0];
    const novaData = new Date(dataFormatada);
    cliente.dtNascimento = new Date(novaData);
    cliente.nome = nome;
    cliente.numeroBeneficiario = matricula;
    cliente.codigoAutorizacao = codigoAutorizacao;

    propostaDTO.promotora = promotora;
    propostaDTO.pontoVenda = pontoVenda;
    propostaDTO.produto = tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.idProduto!;
    propostaDTO.tipoProposta = tpProposta;
    propostaDTO.empregador = empregador;
    propostaDTO.orgao = orgao;
    propostaDTO.cliente = cliente;
    propostaDTO.valorRMC = valorRMC;
    propostaDTO.valorLimite = valoresSimulacao?.valorLimite!;
    propostaDTO.limiteCompra = valoresSimulacao?.limiteCompra!;
    propostaDTO.limiteSaque = valoresSimulacao?.limiteSaque!;
    propostaDTO.tarifaEmissao = valoresSimulacao?.tarifaEmissao!;
    propostaDTO.anuidade = valoresSimulacao?.anuidade!;
    propostaDTO.financiamento = financiamento;

    propostaDTO.simulacaoProposta = resultadoSimulacaoFinal!;

    try {
      setLoading(true);

      await propostaService.inclusaoAlteracaoProposta(propostaDTO)
        .then(resp => {
          const json = resp.data;

          setLoading(false);
          alert('Inclusão realizada com sucesso!');
          navigate('/esteira-cliente/alteracao', { state: { idCliente: json.idCliente, edicao: 'proposta', idProposta: json.id, atualizacaoCadastro: 'false' } });
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
      alert('Ocorreu um erro ao cadastrar proposta!');
    }

  }

  useEffect(() => {
    listarPromotoras();
  }, []);

  return (
    <Container>
      {loading ? <Spinner loading={loading} /> : false}
      <form onSubmit={handleSubmit} >
        <Row className="mb-3">
          <Form.Group as={Col} md='4' controlId="formBasicSelect">
            <Form.Label>Promotora <span style={{ color: 'red' }}>*</span></Form.Label>
            <select className="form-select"
              defaultValue={promotora} value={promotora} onChange={(e) => { setPromotora(e.target.value); listarPontoVendas(e.target.value); listarEmpregadorPorPromotora(e.target.value) }}
            >
              {promotoras.map((item) => (
                <option key={item.id} value={item.id!}>
                  {item.id + ' - ' + item.nome}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group as={Col} md='4' controlId="formBasicSelect">
            <Form.Label>Ponto de Venda <span style={{ color: 'red' }}>*</span></Form.Label>
            <select className="form-select"
              defaultValue={pontoVenda} value={pontoVenda} onChange={(e) => setPontoVenda(e.target.value)}
            >
              {pontoVendas.map((item) => (
                <option key={item.id} value={item.id!}>
                  {item.id + ' - ' + item.nome}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group as={Col} md='4' controlId="formBasicSelect">
            <Form.Label>Produto <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              required
              as="select"
            >
              <option value="Cartão Benefício">Cartão Benefício</option>
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md='4' controlId="formBasicSelect">
            <Form.Label>Tipo de Proposta <span style={{ color: 'red' }}>*</span></Form.Label>
            <select className="form-select"
              defaultValue={tpProposta} value={tpProposta} onChange={(e) => setTpProposta(e.target.value)}
            >
              {tipoProposta.map((item) => (
                <option key={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group as={Col} md='4' controlId="formBasicSelect">
            <Form.Label>Empregador <span style={{ color: 'red' }}>*</span></Form.Label>
            <select className="form-select"
              defaultValue={empregador} value={empregador} onChange={handleEmpregadorChange}
            >
              {promotoraEmpregador.map((item) => (
                <option key={item.id} value={item.id!}>
                  {item.id + ' - ' + item.nome}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group as={Col} md='4' controlId="formBasicSelect">
            <Form.Label>Orgão (Secretaria/UPAG) <span style={{ color: 'red' }}>*</span></Form.Label>
            <select className="form-select"
              defaultValue={orgao} value={orgao} onChange={(e) => setOrgao(e.target.value)}
            >
              {orgaos.map((item) => (
                <option key={item.id} value={item.id!}>
                  {item.id + ' - ' + item.nome}
                </option>
              ))}
            </select>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="3" controlId="validationCpf">
            <Form.Label>CPF <span style={{ color: 'red' }}>*</span></Form.Label>
            <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={(e) => setCpf(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="validationCodigo">
            <Form.Label>Matrícula</Form.Label>
            <input type="text" className="form-control" value={matricula} onChange={(e) => setMatricula(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationName">
            <Form.Label>Nome</Form.Label>
            <input type="text" required className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="validationDate">
            <Form.Label>Data de Nascimento <span style={{ color: 'red' }}>*</span></Form.Label>
            <input className="form-control" type="date" value={dtNascimento} onChange={(e) => setDtNascimento(e.target.value)} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationName">
            <Form.Label>Código de Autorização</Form.Label>
            <input type="text" className="form-control" value={codigoAutorizacao} onChange={(e) => setCodigoAutorizacao(e.target.value)} />
          </Form.Group>
        </Row>

        <div className='button-area'>
          <Button variant="primary" onClick={() => { calcularMargem() }}>
            <RiCalculatorLine /> Calcular Margem
          </Button>
          <Button variant='success' onClick={() => { obterMargemOnline() }}>
            <BiDollar /> Obter Margem Online
          </Button>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => { setModalShow(false); setRmcDisponivel(true) }}
          onModalClose={setValorRMC}
        />

        {(rmcDisponivel || valorRMC !== '' && valorRMC !== undefined) ? (
          <div className="row">
            <div className="col-md-5">
              <div className="alert alert-success alert-dismissible">
                <h5><small>RMC disponível: R$</small>
                  <input className="form-control" value={valorRMC} onChange={handleInputChange} />
                </h5>
                <h5><small>Lotação: </small>
                  <span><small>{lotacao}</small> </span>
                </h5>
                <h5><small>Secretária: </small>
                  <span><small>{secretaria}</small> </span>
                </h5>
                <h5><small>Situação Servidor: </small>
                  <span><small>{situacao}</small> </span>
                </h5>
              </div>
            </div>
            <div className="col-md-3 text-left">
              <Button variant="primary" onClick={() => { carregarTabelaFinanciamentos() }}>
                <RiCalculatorLine /> Exibir Tabelas
              </Button>
            </div>
          </div>
        ) : false}
        {tabelaFinanciamentos.length > 0 ? (
          <>
            {loading ? <Spinner loading={loading} /> : false}
            <div className="select-tab">
              Selecione a Tabela
              <Table bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Código</th>
                    <th>Tabela</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelaFinanciamentos.map((item) => (
                    <tr>
                      <td style={{ width: '1rem' }}>
                        <Button onClick={() => { setIdFinanciamentoEscolhido(item.codigo); buscarValoresTabela(item.codigo); setFinanciamento(item.id != null ? item.id.toString() : '') }}><FaCheck /></Button>
                      </td>
                      <td>{item.codigo}</td>
                      <td>{item.nome}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {idFinanciamentoEscolhido !== '' && !loading ? (
              <><div className="header-cards">
                <b>Resultado Simulação - {idFinanciamentoEscolhido}/{tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.nome}</b>
              </div><div style={{ backgroundColor: 'white', padding: 'auto' }}>
                  <div className="input-card">
                    <Form.Label>Valor do Limite</Form.Label>
                    <div className="card-text">
                      R$ {valoresSimulacao?.valorRMC}
                    </div>
                  </div>
                </div><CardGroup>
                  <Card style={{ borderRadius: '0px 0px 0px 15px' }}>
                    <Card.Body>
                      <Card.Title>Proposta de Limite</Card.Title>

                      <div className="card-field">
                        Valor RMC
                      </div>
                      <div className="card-text">
                        R$ {valoresSimulacao?.valorRMC}
                      </div>
                      <div className="card-field">
                        Limite Total
                      </div>
                      <div className="card-text">
                        R$ {valoresSimulacao?.valorLimite}
                      </div>
                      <div className="card-field">
                        Limite de Compras
                      </div>
                      <div className="card-text">
                        R$ {valoresSimulacao?.limiteCompra}
                      </div>
                      <div className="card-field">
                        Limite Saque
                      </div>
                      <div className="card-text">
                        R$ {valoresSimulacao?.limiteSaque}
                      </div>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title style={{ border: "'black', '1px'" }}>Taxas e Tarifas</Card.Title>
                      <div className="card-field">
                        Tarifa de Emissão
                      </div>
                      <div className="card-text">
                        R$ {valoresSimulacao?.tarifaEmissao}
                      </div>
                      <div className="card-field">
                        Anuidade
                      </div>
                      <div className="card-text">
                        R$ {valoresSimulacao?.anuidade}
                      </div>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>Convênio</Card.Title>
                      <div className="card-field">
                        Empregador
                      </div>
                      <div className="card-text">
                        {promotoraEmpregador.find(item => item.id === empregador)?.nome}
                      </div>
                      <div className="card-field">
                        Produto
                      </div>
                      <div className="card-text">
                        {tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.nomeProduto}
                      </div>
                      <div className="card-field">
                        Tabela
                      </div>
                      <div className="card-text">
                        {tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.nome}
                      </div>
                    </Card.Body>
                  </Card>
                  <Card style={{ borderRadius: '0px 0px 15px 0px' }}>
                    <Card.Body>
                      <Card.Title>Fatura</Card.Title>
                      <div className="card-field">
                        Dia de Corte
                      </div>
                      <div className="card-text">
                        {tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.diaCorte}
                      </div>
                      <div className="card-field">
                        Dia de Vencimento
                      </div>
                      <div className="card-text">
                        {tabelaFinanciamentos.find(item => item.codigo === idFinanciamentoEscolhido)?.diaVencimento}
                      </div>
                    </Card.Body>
                  </Card>
                </CardGroup><div className="saque">
                  <Row className="mb-3">
                    <Form.Group as={Col} md="2" controlId="validationName">
                      <Form.Label>Valor Parcela</Form.Label>
                      <input type="number" required className="form-control" value={valorParcela} onChange={(e) => setValorParcela(parseInt(e.target.value))} readOnly/>
                    </Form.Group>

                    <Form.Group as={Col} md="2" controlId="validationName">
                      <Form.Label>Valor Solicitado</Form.Label>
                      <input type="number" required className="form-control" value={valorSolicitado} onChange={(e) => setValorSolicitado(parseInt(e.target.value))} />
                    </Form.Group>

                    <Form.Group as={Col} md="2" controlId="validationDate">
                      <Form.Label>Data da Simulação</Form.Label>
                      <Form.Control
                        color="gray" type="text" readOnly disabled
                        value={format(new Date(), 'dd/MM/yyyy')}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md='4' controlId="formBasicSelect">
                      <Form.Label>Prazo</Form.Label>
                      <select className="form-select" defaultValue={prazo} value={prazo} onChange={(e) => setPrazo(e.target.value)}
                      >
                        {prazos.map((item) => (
                          <option key={item.label} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Group>

                    <div className="button-area">
                      <Button variant='primary' onClick={() => { resultadoSimulacaoParcela() }}>
                        <BiDollar /> Simular Saque</Button>
                    </div>
                  </Row>
                </div>

                {showResultadoSimulacao && !loading ? (
                  <><div className="header-cards">
                    <b>Resultado Simulação Saque Parcelado</b>
                  </div><CardGroup>
                      <Card style={{ borderRadius: '0px 0px 0px 15px' }}>
                        <Card.Body>
                          <Card.Title>Valores</Card.Title>

                          <div className="card-field">
                            Valor Solicitado
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorSolicit}
                          </div>
                          <div className="card-field">
                            Valor IOF
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorIof}
                          </div>
                          <div className="card-field">
                            Valor Líquido
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorLiquido}
                          </div>
                          <div className="card-field">
                            Valor Parcela
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorParc}
                          </div>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Card.Title style={{ border: "'black', '1px'" }}>Valores</Card.Title>
                          <div className="card-field">
                            Valor Refin
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorRefin}
                          </div>
                          <div className="card-field">
                            Valor Compra
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorCompra}
                          </div>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Card.Title>Valores</Card.Title>
                          <div className="card-field">
                            Valor Principal
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorPrincipal}
                          </div>
                          <div className="card-field">
                            Valor Bruto
                          </div>
                          <div className="card-text">
                            R$ {resultadoSimulacaoFinal?.valorBruto}
                          </div>
                        </Card.Body>
                      </Card>
                      <Card style={{ borderRadius: '0px 0px 15px 0px' }}>
                        <Card.Body>
                          <Card.Title>Taxas</Card.Title>
                          <div className="card-field">
                            Taxa CL a.m.
                          </div>
                          <div className="card-text">
                            {resultadoSimulacaoFinal?.taxaClAM}
                          </div>
                          <div className="card-field">
                            Taxa CL a.a.
                          </div>
                          <div className="card-text">
                            {resultadoSimulacaoFinal?.taxaClAA}
                          </div>
                          <div className="card-field">
                            Taxa CET a.m.
                          </div>
                          <div className="card-text">
                            {resultadoSimulacaoFinal?.taxaCetAM}
                          </div>
                          <div className="card-field">
                            Taxa CET a.a.
                          </div>
                          <div className="card-text">
                            {resultadoSimulacaoFinal?.taxaCetAA}
                          </div>

                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Card.Title>Prazos</Card.Title>
                          <div className="card-field">
                            Prazo
                          </div>
                          <div className="card-text">
                            {resultadoSimulacaoFinal?.prazo}
                          </div>
                          <div className="card-field">
                            Primeiro Vencimento
                          </div>
                          <div className="card-text">
                            {dtPrimeiroVencimento}
                          </div>
                          <div className="card-field">
                            último Vencimento
                          </div>
                          <div className="card-text">
                            {dtUltimoVencimento}
                          </div>
                        </Card.Body>
                      </Card>
                    </CardGroup><div className="button-area" style={{ marginTop: '1rem' }}>
                      <Button variant='success' type="submit"><BiDollar /> Solicitar Proposta</Button>
                    </div></>
                ) : false}
              </>
            ) : false}
          </>
        ) : valorRMC !== '' ? (
          <table
            style={{
              color: 'Black', backgroundColor: '#CCCCCC', borderColor: '#999999',
              borderWidth: '3px', borderStyle: 'Solid', width: '100%'
            }}>
            <tbody>
              <tr>
                <td>Nenhuma Tabela localizada!</td>
              </tr>
            </tbody>
          </table>
        ) : false}
      </form>
    </Container >
  );
} export default CartaoComponent