import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import { Produto } from '../../../shared/model/produto';
import { ProdutoIdadeCartao } from '../../../shared/model/produtoIdadeCartao';
import { ProdutoIdadeSaque } from '../../../shared/model/produtoIdadeSaque';
import { EsteiraModelo } from '../../../shared/model/esteira_modelo';
import ProdutoService from '../../../services/produtoService';
import EsteiraModeloService from '../../../services/esteiraModeloService';

function ProdutoInclusaoAlteracao() {
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const tipoProd = [
    { value: 'Antecipação de Remuneração', label: 'Antecipação de Remuneração' },
    { value: 'Cartão Benefício', label: 'Cartão Benefício' },
  ];

  const tipoValidarPor = [
    { value: 'CPF', label: 'CPF' },
    { value: 'CPF + Matr', label: 'CPF + Matr' },
  ];

  const tipoValidaBanco = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ];

  const tipoIof = [
    { value: 'Isento', label: 'Isento' },
    { value: 'IOF Lecca', label: 'IOF Lecca' },
  ];

  const tipoFinancia = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ];

  const tipoCalculaDV = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ];

  const tipoConsistirIdade = [
    { value: 'Data Base', label: 'Data Base' },
    { value: 'Data de Vencimento', label: 'Data de Vencimento' },
  ];

  const [nome, setNome] = useState('');
  const [tipoProduto, setTipoProduto] = useState(tipoProd[0].value);
  const [esteiras, setEsteiras] = useState<EsteiraModelo[]>([]);
  const [esteira, setEsteira] = useState('');
  const [horaCorte, setHoraCorte] = useState('');
  const [validaBanco, setValidaBanco] = useState(tipoValidaBanco[0].value);
  const [valorMinLimite, setValorMinLimite] = useState('');
  const [qtdContratos, setQtdContratos] = useState('');
  const [validarPor, setValidarPor] = useState(tipoValidarPor[0].value);
  const [faixaIdade1, setFaixaIdade1] = useState('');
  const [faixaIdade2, setFaixaIdade2] = useState('');
  const [iof, setIof] = useState(tipoIof[0].value);
  const [financia, setFinancia] = useState(tipoFinancia[0].value);
  const [prefixo, setPrefixo] = useState('');
  const [mascara, setMascara] = useState('');
  const [calculaDV, setCalculaDV] = useState(tipoCalculaDV[0].value);
  const [percentCompra, setPercentCompra] = useState('');
  const [percentSaque, setPercentSaque] = useState('');
  const [limiteSaque1, setLimiteSaque1] = useState('');
  const [limiteSaque2, setLimiteSaque2] = useState('');
  const [codigoExterno, setCodigoExterno] = useState('');
  const [consisteIdadeSaque, setConsisteIdadeSaque] = useState(tipoConsistirIdade[0].value);

  const [limiteIdadeCartao, setLimiteIdadeCartao] = useState('');
  const [fatorMultiplicadorRMCCartao, setFatorMultiplicadorRMCCartao] = useState('1');
  const [valorMaximoLimiteCartao, setValorMaximoLimiteCartao] = useState('');

  const [tabelaIdadeCartao, setTabelaIdadeCartao] = useState<ProdutoIdadeCartao[]>([]);

  const [limiteIdadeSaque, setLimiteIdadeSaque] = useState('');
  const [valorMaximoSaque, setValorMaximoSaque] = useState('');

  const [tabelaIdadeSaque, setTabelaIdadeSaque] = useState<ProdutoIdadeSaque[]>([]);

  const [loading, setLoading] = useState(false);
  const produtoService: ProdutoService = new ProdutoService();
  const esteiraModeloService: EsteiraModeloService = new EsteiraModeloService();

  const listarEsteiras = async () => {
    try {
      const response = await esteiraModeloService.listarTodasEsteirasModelo();
      setEsteiras(response.data);
      if (tipo === 'inclusao') {
        setEsteira(response.data[0].id?.toString()!);
      }  
    } catch (err) {
      console.error(err);
    }
  };

  const adicionarDadoTabelaIdadeCartao = () => {
    if (!fatorMultiplicadorRMCCartao || !limiteIdadeCartao || !valorMaximoLimiteCartao) {
      alert('Preencha todos os campos da tabela de idade de cartão!');
      return;
    }
    if (tabelaIdadeCartao.some(item => item.idadeMaxima.toString() === limiteIdadeCartao)) {
      alert('Já existe um registro com esta idade');
      return;
    }
    let tabelaIdad: ProdutoIdadeCartao = new ProdutoIdadeCartao();
    tabelaIdad.fatorRMC = parseInt(fatorMultiplicadorRMCCartao!);
    tabelaIdad.idadeMaxima = parseInt(limiteIdadeCartao!);
    tabelaIdad.valorMaximo = valorMaximoLimiteCartao!;
    setTabelaIdadeCartao([...tabelaIdadeCartao, tabelaIdad]);
    setLimiteIdadeCartao('');
    setValorMaximoLimiteCartao('');
  }
  const adicionarDadoTabelaIdadeSaque = () => {
    if (!limiteIdadeSaque || !valorMaximoSaque) {
      alert('Preencha todos os campos da tabela de idade de saque!');
      return;
    }
    if (tabelaIdadeSaque.some(item => item.idadeMaxima.toString() === limiteIdadeSaque)) {
      alert('Já existe um registro com esta idade');
      return;
    }
    let tabelaIdad: ProdutoIdadeSaque = new ProdutoIdadeSaque();
    tabelaIdad.idadeMaxima = parseInt(limiteIdadeSaque!);
    tabelaIdad.valorMaximo = valorMaximoSaque!;
    setTabelaIdadeSaque([...tabelaIdadeSaque, tabelaIdad]);
    setLimiteIdadeSaque('');
    setValorMaximoSaque('');
  };

  const removerDadoTabelaIdadeCartao = (index: number) => {
    const newTabelaIdade = [...tabelaIdadeCartao];
    newTabelaIdade.splice(index, 1);
    setTabelaIdadeCartao(newTabelaIdade);
  };

  const removerDadoTabelaIdadeSaque = (index: number) => {
    const newTabelaIdade = [...tabelaIdadeSaque];
    newTabelaIdade.splice(index, 1);
    setTabelaIdadeSaque(newTabelaIdade);
  };

  const listarProdutoPorId = async () => {
    setLoading(true);
    try {
      const response = await produtoService.listarProdutoPorId(id);
      const jsonProduto = response.data;

      setNome(jsonProduto.nome);
      setTipoProduto(jsonProduto.tipoProduto);
      setEsteira(jsonProduto.esteira.id!.toString());
      setHoraCorte(jsonProduto.horaCorte);
      setValidaBanco(jsonProduto.validaBanco);
      setValorMinLimite(jsonProduto.valorMinLimite);
      setQtdContratos(jsonProduto.qtdContratos);
      setValidarPor(jsonProduto.validarPor);
      setFaixaIdade1(jsonProduto.faixaIdade1);
      setFaixaIdade2(jsonProduto.faixaIdade2);
      setIof(jsonProduto.iof);
      setFinancia(jsonProduto.financia);
      setPrefixo(jsonProduto.prefixo);
      setMascara(jsonProduto.mascara);
      setCalculaDV(jsonProduto.calculaDV);
      setPercentCompra(jsonProduto.percentCompra);
      setPercentSaque(jsonProduto.percentSaque);
      setLimiteSaque1(jsonProduto.limiteSaque1);
      setLimiteSaque2(jsonProduto.limiteSaque2);
      setConsisteIdadeSaque(jsonProduto.consisteIdadeSaque);
      setTabelaIdadeCartao(jsonProduto.tabelaIdadeCartao);
      setTabelaIdadeSaque(jsonProduto.tabelaIdadeSaque);
      setCodigoExterno(jsonProduto.codigoExterno.toString());

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const limparCampos = () => {
    setNome('');
    setTipoProduto('');
    setEsteira('');
    setHoraCorte('');
    setValidaBanco('');
    setValorMinLimite('');
    setQtdContratos('');
    setValidarPor('');
    setFaixaIdade1('');
    setFaixaIdade2('');
    setIof('');
    setFinancia('');
    setPrefixo('');
    setMascara('');
    setCalculaDV('');
    setPercentCompra('');
    setPercentSaque('');
    setLimiteSaque1('');
    setLimiteSaque2('');
    setConsisteIdadeSaque('');
    setTabelaIdadeCartao([]);
    setTabelaIdadeSaque([]);
    setCodigoExterno('');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (tabelaIdadeCartao == null || tabelaIdadeCartao.length < 1) {
      alert('Não existe Faixas de Idade de cartão cadastrada!');
      return;
    } else if((parseInt(faixaIdade1) > parseInt(faixaIdade2)) || (parseInt(limiteSaque1) > parseInt(limiteSaque2))
      || parseInt(percentCompra) + parseInt(percentSaque) > 100) {
      alert('Preencha os dados corretamente!');
      return;
    }else {
      setLoading(true);
      let produto: Produto = new Produto();
      produto.id = id;
      produto.nome = nome;
      produto.tipoProduto = tipoProduto;
      let est = new EsteiraModelo();
      est.id = esteira;
      produto.esteira = est;
      produto.horaCorte = horaCorte;
      produto.validaBanco = validaBanco;
      produto.valorMinLimite = valorMinLimite;
      produto.qtdContratos = qtdContratos;
      produto.validarPor = validarPor;
      produto.faixaIdade1 = faixaIdade1;
      produto.faixaIdade2 = faixaIdade2;
      produto.iof = iof;
      produto.financia = financia;
      produto.prefixo = prefixo;
      produto.mascara = mascara;
      produto.calculaDV = calculaDV;
      produto.percentCompra = percentCompra;
      produto.percentSaque = percentSaque;
      produto.limiteSaque1 = limiteSaque1;
      produto.limiteSaque2 = limiteSaque2;
      produto.consisteIdadeSaque = consisteIdadeSaque;
      produto.tabelaIdadeCartao = tabelaIdadeCartao;
      produto.tabelaIdadeSaque = tabelaIdadeSaque;
      produto.codigoExterno = parseInt(codigoExterno);
      
      try {
        const response = await produtoService.incluirAlterarProduto(produto)
          .then(() => setLoading(false));

        alert('Alteração realizada com sucesso!');
        window.location.href = '/parametros-produto';
      } catch (err) {
        setLoading(false);
        alert('Ocorreu um erro ao criar produto!');
        console.error(err);
      }
    }

  };

  useEffect(() => {
    listarEsteiras();
    if (tipo === 'alteracao') {
      listarProdutoPorId();
    }
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Parâmetros Cartão &gt; Produtos &gt; Inclusão</h5> : (
                <h5>Parâmetros Cartão &gt; Produtos &gt; Inclusão</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <span>Tipo de Produto <span style={{ color: 'red' }}>*</span></span>
                <select className="form-select" defaultValue={tipoProduto[0]} value={tipoProduto} onChange={(e) => setTipoProduto(e.target.value)}>
                  {tipoProd.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <label>Esteira <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={esteira} value={esteira} onChange={(e) => setEsteira(e.target.value)}>
                {esteiras.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nomeEsteira}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <label>Horário Corte <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99:99" type="text" className="form-control" value={horaCorte} onChange={(e) => setHoraCorte((e.target.value).replace(/_/g, ""))} />
            </div>

            <div className="col-md-3">
              <label>Valida Banco Digital <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={validaBanco[0]} value={validaBanco} onChange={(e) => setValidaBanco(e.target.value)}>
                {tipoValidaBanco.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-5">
              <label>Código Externo (Motor Averbação) <span style={{ color: 'red' }}>*</span></label>
              <input required type="number" className="form-control" value={codigoExterno} onChange={(e) => setCodigoExterno((e.target.value))} />
            </div>

          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label>Valor Mínimo de Limite <span style={{ color: 'red' }}>*</span></label>
                <input required  type="number" className="form-control" value={valorMinLimite} onChange={(e) => setValorMinLimite(e.target.value)} />
              </div>
            </div>
            <div className="col-xl">
              <label>QTD Contratos <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99" type="text" className="form-control" value={qtdContratos} onChange={(e) => setQtdContratos((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Validar Por: <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={validarPor} value={validarPor} onChange={(e) => setValidarPor(e.target.value)}>
                {tipoValidarPor.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-4">
              <label>Faixa de Idade <span style={{ color: 'red' }}>*</span></label>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}><InputMask required mask="99" maskChar={null} type="text" className="form-control" value={faixaIdade1} onChange={(e) => setFaixaIdade1((e.target.value).replace(/_/g, ""))} /></th>
                  <th style={{ width: "15px" }}> a </th>
                  <th style={{ width: "150px" }}><InputMask required mask="99" maskChar={null} type="text" className="form-control" value={faixaIdade2} onChange={(e) => setFaixaIdade2((e.target.value).replace(/_/g, ""))} /></th>
                </tr>
              </thead>
            </div>
          </div>
          <h6 style={{ marginTop: '20px' }}>IOF</h6>
          <div className="row">
            <div className="col-md-2">
              <label>Tipo IOF <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={iof} value={iof} onChange={(e) => setIof(e.target.value)}>
                {tipoIof.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-2">
              <label>Financia <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={financia} value={financia} onChange={(e) => setFinancia(e.target.value)}>
                {tipoFinancia.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <h6 style={{ marginTop: '20px' }}>Máscara do Número do Contrato</h6>
          <div className="row">
            <div className="col-md-2">
              <label>Prefixo</label>
              <InputMask mask="9" type="text" className="form-control" value={prefixo} onChange={(e) => setPrefixo((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-3">
              <label>Máscara</label>
              <InputMask mask="9999999999" type="text" className="form-control" value={mascara} onChange={(e) => setMascara((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-2">
              <label>Calcula DV</label>
              <select className="form-select" defaultValue={calculaDV} value={calculaDV} onChange={(e) => setCalculaDV(e.target.value)}>
                {tipoCalculaDV.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <h6 style={{ marginTop: '20px' }}>Parâmetros Cartão Consignado</h6>
          <div className="row">
            <div className="col-md-2">
              <label>% Compra <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99" maskChar={null} type="text" className="form-control" value={percentCompra} onChange={(e) => setPercentCompra((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-3">
              <label>% Saque <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99" maskChar={null} type="text" className="form-control" value={percentSaque} onChange={(e) => setPercentSaque((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-4">
              <label>Limites de Saque <span style={{ color: 'red' }}>*</span></label>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}><InputMask required mask="9999999" maskChar={null} type="text" className="form-control" value={limiteSaque1} onChange={(e) => setLimiteSaque1((e.target.value).replace(/_/g, ""))} /></th>
                  <th style={{ width: "15px" }}> a </th>
                  <th style={{ width: "150px" }}><InputMask required mask="9999999" maskChar={null} type="text" className="form-control" value={limiteSaque2} onChange={(e) => setLimiteSaque2((e.target.value).replace(/_/g, ""))} /></th>
                </tr>
              </thead>
            </div>
          </div>

          <h6 style={{ marginTop: '20px' }}>Política de Idade - Cartão</h6>
          <div className="row">
            <div className="col-md-2">
              <label>Limite de Idade <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" className="form-control" value={limiteIdadeCartao} onChange={(e) => {setLimiteIdadeCartao((e.target.value).replace(/_/g, "")); console.log((e.target.value).replace(/_/g, ""))}} />
            </div>
            <div className="col-md-3">
              <label>Fator Multiplicador RMC</label>
              <text className="form-control" display={'none'}>{fatorMultiplicadorRMCCartao}</text>
            </div>
            <div className="col-md-3">
              <label>Valor Máximo de Limite <span style={{ color: 'red' }}>*</span></label>
              <input  type="number" className="form-control" value={valorMaximoLimiteCartao} onChange={(e) => {setValorMaximoLimiteCartao(e.target.value)}} />
            </div>
            <div className="col-xl">
              <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white', cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}
                onClick={() => adicionarDadoTabelaIdadeCartao()}>+
              </Button>
            </div>
          </div>

          {tabelaIdadeCartao.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Idade Máxima</th>
                    <th scope="col">Fator RMC</th>
                    <th scope="col">Valor Máximo Limite</th>
                    <th scope="col">Excluir</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelaIdadeCartao.map((item, index) => (
                    <tr>
                      <td>{item.idadeMaxima}</td>
                      <td>{item.fatorRMC}</td>
                      <td>R$ {item.valorMaximo}</td>
                      <td>
                        <Button style={{
                          backgroundColor: '#dc3545',
                          border: '1px solid #dc3545',
                          color: 'white',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }} onClick={() => removerDadoTabelaIdadeCartao(index)}>X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : false}

          <h6 style={{ marginTop: '20px' }}>Política de Idade - Saque</h6>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label>Consistir Idade em: <span style={{ color: 'red' }}>*</span></label>
                <select className="form-select" defaultValue={consisteIdadeSaque} value={consisteIdadeSaque} onChange={(e) => setConsisteIdadeSaque(e.target.value)}>
                  {tipoConsistirIdade.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <label>Limite de Idade <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" className="form-control" value={limiteIdadeSaque} onChange={(e) => setLimiteIdadeSaque((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-md-3">
              <label>Valor Máximo de Limite <span style={{ color: 'red' }}>*</span></label>
              <input  type="number" className="form-control" value={valorMaximoSaque} onChange={(e) => setValorMaximoSaque(e.target.value)} />
            </div>
            <div className="col-xl">
              <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white', cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}
                onClick={() => adicionarDadoTabelaIdadeSaque()}>+
              </Button>
            </div>
          </div>

          {tabelaIdadeSaque.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Idade Máxima</th>
                    <th scope="col">Valor Máximo Limite</th>
                    <th scope="col">Excluir</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelaIdadeSaque.map((item, index) => (
                    <tr>
                      <td>{item.idadeMaxima}</td>
                      <td>R$ {item.valorMaximo}</td>
                      <td>
                        <Button style={{
                          backgroundColor: '#dc3545',
                          border: '1px solid #dc3545',
                          color: 'white',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }} onClick={() => removerDadoTabelaIdadeSaque(index)}>X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : false}

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Produto' : 'Salvar Produto'}</button>
            </div>
          </div>
        </form>
      )
      }
    </div >
  );
}

export default ProdutoInclusaoAlteracao;