export const TipoDocumento = [
    { value: "1", label: "RG" },
    { value: "2", label: "RG - Verso" },
    { value: "3", label: "CPF" },
    { value: "4", label: "CNH" },
    { value: "5", label: "CNH - Verso" },
    { value: "6", label: "Comprovante de Endereço" },
    { value: "7", label: "Comprovante de Renda" },
    { value: "8", label: "Termo de Adesão" },
    { value: "9", label: "Ficha Cadastral" },
    { value: "10", label: "Autorização de Desconto em Folha" },
    { value: "11", label: "Autorização de Saque via Cartão" },
    { value: "12", label: "Audio/Gravação" },
    { value: "13", label: "Selfie" },
    { value: "14", label: "Vídeo do Cliente" },
    { value: "15", label: "CCB - Via Negociável" },
    { value: "16", label: "CCB - Via Não Negociável" },
    { value: "17", label: "Boleto Bancário" },
    { value: "18", label: "Comprovante Averbação" },
    { value: "19", label: "Extrato Bancário" },
    { value: "99", label: "Outros" },
];