import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import Spinner from '../../shared/spinner';
import { format, parse } from 'date-fns';
import InputMask from 'react-input-mask';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RelatoriosDTO } from '../../shared/dto/relatoriosDTO';
import ArquivoService from '../../services/arquivoService';

function ClienteRelatorioComponent() {
  const [loading, setLoading] = useState(false);

  const [dtBase1, setDtBase1] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtBase2, setDtBase2] = useState(format(new Date(), 'dd/MM/yyyy'));

  const arquivoService: ArquivoService = new ArquivoService();

  const gerarRelatorio = async () => {
    if (dtBase1 === '' || dtBase2 === '') return alert('Preencha os campos de datas!');
    if (dtBase1 !== '' && dtBase2 !== '' && parse(dtBase1, "dd/MM/yyyy", new Date()) > parse(dtBase2, "dd/MM/yyyy", new Date())) return alert('Data inicial deve ser inferior a data final');
    setLoading(true);
    try {
      const dto: RelatoriosDTO = new RelatoriosDTO();
      dto.dtBase1 = parse(dtBase1, "dd/MM/yyyy", new Date());
      dto.dtBase2 = parse(dtBase2, "dd/MM/yyyy", new Date());

      const response = await arquivoService.gerarRelatorioCliente(dto);

      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        alert(errorText);
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Relatorio_Clientes.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        alert(errorText);
      } else {
        alert('Ocorreu um erro ao gerar o relatório');
      }
      console.error(err);
    }
  };

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>Relatório de Clientes</strong>
            </div>
            <div className="card-body">
              <div className="" style={{ marginTop: '10px' }}>
                <div className="row">
                  <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <label>Período de Datas <span style={{ color: 'red' }}>*</span></label>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                  <div className="col-md-4">
                    <thead>
                      <tr>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase1} onChange={(e) => setDtBase1((e.target.value).replace(/_/g, ""))} /></th>
                        <th style={{ width: "15px" }}> a </th>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase2} onChange={(e) => setDtBase2((e.target.value).replace(/_/g, ""))} /></th>
                      </tr>
                    </thead>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: '20px', height: '50px', maxWidth: '70%' }}>
                <div className="col-md-3">
                  <button className="input-group-btn"
                    style={{
                      width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                      justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                    }}onClick={gerarRelatorio}>
                    <a>
                      <FontAwesomeIcon icon={faPrint} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                    </a>
                    <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Gerar Relatório</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
}

export default ClienteRelatorioComponent;