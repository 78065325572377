export const SituacaoEsteiraEnum = [
  { value: "SIM", label: "Simulação" },
  { value: "CAD", label: "Cadastrado" },
  { value: "AND", label: "Andamento" },
  { value: "PEN", label: "Pendente" },
  { value: "APR", label: "Aprovado" },
  { value: "LIB", label: "Liberado" },
  { value: "PAG", label: "Pago" },
  { value: "INT", label: "Integrado" },
  { value: "REP", label: "Reprovado" },
]
