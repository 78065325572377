import createAxiosInstance from './axiosConfig';
import { EmpregadorPromotoraDTO } from '../shared/dto/empregadorPromotoraDTO';
import { OperacaoDTO } from '../shared/dto/operacaoDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/operacaoController');

export default class OperacaoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarOperacoesCessaoPorPromotoraEmpregador(dto: EmpregadorPromotoraDTO) {
    return await this.axios.post(`/listarOperacoesCessaoPorPromotoraEmpregador`, dto);
  };

  async cederOperacoesCessao(numeroCessao: string, dto: OperacaoDTO[]) {
    return await this.axios.post(`/cederOperacoesCessao?idCessao=${numeroCessao}`, dto);
  };

  async listarOperacoesRecompraPorPromotoraEmpregador(dto: EmpregadorPromotoraDTO) {
    return await this.axios.post(`/listarOperacoesRecompraPorPromotoraEmpregador`, dto);
  };

  async recomprarOperacoesCessao(numeroCessao: string, dto: OperacaoDTO[]) {
    return await this.axios.post(`/recomprarOperacoesCessao?idCessao=${numeroCessao}`, dto);
  };

  async listarContratoPorFiltro(selectValue: string, inputValue: string) {
    return await this.axios.get(`/listarContratoPorFiltro?tipoParametro=${selectValue}&valorParametro=${inputValue}`);
  };

  async listarContratoDetalhePorContrato(contrato: string) {
    return await this.axios.get(`/listarContratoDetalhePorContrato?contrato=${contrato}`);
  };

  async listarNomeClientePorContrato(contrato: string) {
    return await this.axios.get(`/listarNomeClientePorContrato?contrato=${contrato}`);
  };

  async estornarOperacao(contrato: string, dtMovimento: string) {
    return await this.axios.get(`/estornarOperacao?contrato=${contrato}&dtMovimentacao=${dtMovimento}`);
  };

  async listarDadosParaProrrogacao(contrato: string) {
    return await this.axios.get(`/listarDadosParaProrrogacao?contrato=${contrato}`);
  };

}