import { OperacaoParcela } from "../model/operacaoParcela";

export class BaixaParcelaDTO {
  public nomeCliente!: string;
  public faixaParcela1!: number;
  public faixaParcela2!: number;
  public parcelas!: OperacaoParcela[];
  public valorFinal!: number;
  public saldoTotal!: number;
  public valorPago!: number;
  public dtMovimento!: Date;
  public valorMovimento!: number;
  public movimento!: string;
  public idHistoricoFinanceiro!: number | null;
}