import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import Spinner from '../../../shared/spinner';
import { format, parse } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose, faCalculator, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { ProrrogacaoVencimentoDTO } from '../../../shared/dto/prorrogacaoVencimentoDTO';
import { ProrrogacaoVencimentoParcelaDTO } from '../../../shared/dto/prorrogacaoVencimentoParcelaDTO';
import OperacaoParcelaService from '../../../services/operacaoParcelaService';
import OperacaoService from '../../../services/operacaoService';

function ProrrogacaoVencimentoManualComponent() {
  const [loading, setLoading] = useState(false);

  const [dto, setDto] = useState<ProrrogacaoVencimentoDTO>();
  const [parcelasList, setParcelasList] = useState<ProrrogacaoVencimentoParcelaDTO[] | null>([]);
  const [exibeDetalhe, setExibeDetalhe] = useState(false);
  const [exibeDetalheParcelas, setExibeDetalheParcelas] = useState(false);
  const [comecarDaParcela, setComecarDaParcela] = useState(0);
  const [dtVencimentoProrrogacao, setDtVencimentoProrrogacao] = useState('');

  const [contrato, setContrato] = useState('');
  const [dtMovimento, setDtMovimento] = useState(format(new Date(), 'dd/MM/yyyy'));

  const operacaoParcelaService: OperacaoParcelaService = new OperacaoParcelaService();
  const operacaoService: OperacaoService = new OperacaoService();
  
  const listarContrato = async () => {
    if (contrato === '') return alert('Preencha o campo de busca');
    try {
      setParcelasList([]);
      setLoading(true);

      const responseList = await operacaoService.listarDadosParaProrrogacao(contrato);
      const json = responseList.data;
      setDto(responseList.data);
      setComecarDaParcela(json.comecarDaParcela);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarParcelas = async () => {
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return alert('Data de movimento não pode ser superior a data de hoje');
    if (dtVencimentoProrrogacao === '') return alert('Preencha o campo de data de prorrogação');
    const parsedDtVencimentoParcela = dto?.dtVencimentoParcela ? new Date(dto.dtVencimentoParcela) : new Date();
    if (parse(dtVencimentoProrrogacao, "dd/MM/yyyy", new Date()) <= parsedDtVencimentoParcela) return alert('Data de prorrogação deve ser superior a data de vencimento da parcela');
    if (isNaN(comecarDaParcela)) return alert('Preencha o campo de inicio da parcela');
    if (comecarDaParcela > dto?.prazo!) return alert('Erro! Insira o inicio da parcela corretamente (entre 1 e ' + dto?.prazo + ')');

    try {
      setParcelasList([]);
      setLoading(true);

      const responseList = await operacaoParcelaService.listarParcelasParaProrrogacao(contrato, comecarDaParcela, parse(dtVencimentoProrrogacao, "dd/MM/yyyy", new Date()));
      setParcelasList(responseList.data);
      
      setExibeDetalheParcelas(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const prorrogarParcelas = async () => {
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return alert('Data de movimento não pode ser superior a data de hoje');
   
    try {
      setLoading(true);
      const responseList = await operacaoParcelaService.prorrogarParcelas(parse(dtMovimento, "dd/MM/yyyy", new Date()), parcelasList);
      alert(responseList.data);
      setContrato(''); 
      setExibeDetalhe(false); 
      setDtMovimento(format(new Date(), 'dd/MM/yyyy')); 
      setComecarDaParcela(0); 
      setParcelasList([]); 
      setDtVencimentoProrrogacao('');
      setExibeDetalheParcelas(false)
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      alert(err.response.data);
      console.error(err);
    }
  }

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <strong>Prorrogação de Vencimentos</strong>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Contrato <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
                        value={contrato} onChange={(e) => { setContrato((e.target.value)); setExibeDetalhe(false); setDtMovimento(format(new Date(), 'dd/MM/yyyy')); setComecarDaParcela(0); setParcelasList([]); setDtVencimentoProrrogacao('');setExibeDetalheParcelas(false) }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarContrato}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>
  
                {exibeDetalhe && (
                  <>
                    <div className="row" style={{ marginTop: '10px' }}>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Nome Cliente</span>
                          <input type="text" value={dto?.nomeCliente} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Data 1º Vencimento</span>
                          <input type="text" value={dto?.dtPrimeiroVencimento ? format(new Date(dto?.dtPrimeiroVencimento), 'dd/MM/yyyy') : ''} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Data Último Vencimento</span>
                          <input type="text" value={dto?.dtUltimoVencimento ? format(new Date(dto?.dtUltimoVencimento), 'dd/MM/yyyy') : ''} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Próxima Parcela</span>
                          <input type="text" value={dto?.proximaParcela} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Data Vencimento Parcela</span>
                          <input type="text" value={dto?.dtVencimentoParcela ? format(new Date(dto?.dtVencimentoParcela), 'dd/MM/yyyy') : ''} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                      <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <label style={{ width: '100%' }}>Data Movimento <span style={{ color: 'red' }}>*</span></label>
                        <InputMask required mask="99/99/9999" className="form-control" type="text"
                          value={dtMovimento} onChange={(e) => setDtMovimento((e.target.value).replace(/_/g, ""))} />
                      </div>
                      <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <label style={{ width: '100%' }}>A partir da Parcela: <span style={{ color: 'red' }}>*</span></label>
                        <input type="number" className="form-control" style={{ color: 'Black', backgroundColor: 'White' }}
                          value={comecarDaParcela} onChange={(e) => { setComecarDaParcela(parseInt(e.target.value)); setExibeDetalheParcelas(false) }} />
                      </div>
                      <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <label style={{ width: '100%' }}>Data Vcto 1º Parc. Prorrog. <span style={{ color: 'red' }}>*</span></label>
                        <InputMask required mask="99/99/9999" className="form-control" type="text"
                          value={dtVencimentoProrrogacao} onChange={(e) => { setDtVencimentoProrrogacao((e.target.value).replace(/_/g, "")); setExibeDetalheParcelas(false) }} />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '15px' }}>
                      <div className="col-md-3">
                        <button className="input-group-btn"
                          style={{
                            width: '40%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                            justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '50px', minWidth: '50px'
                          }} onClick={listarParcelas}>
                          <a>
                            <FontAwesomeIcon icon={faCalculator} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                          </a>
                          <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Calcular</strong>
                        </button>
                      </div>
                    </div>
                    {exibeDetalheParcelas && (
                      parcelasList != null && parcelasList!.length > 0 ? (
                        <>
                          <div style={{ height: '250px' }} className="table-responsive">
                            <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                              <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                                <thead>
                                  <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black', position: 'sticky', top: '0' }}>
                                    <th style={{ textAlign: 'center' }} scope="col">NÚMERO</th>
                                    <th style={{ textAlign: 'center' }} scope="col">VENCIMENTO</th>
                                    <th style={{ textAlign: 'center' }} scope="col">NOVO VENCIMENTO</th>
                                    <th style={{ textAlign: 'center' }} scope="col">VALOR PARCELA</th>
                                    <th style={{ textAlign: 'center' }} scope="col">STATUS</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {parcelasList.map((item) => (
                                    <tr className="GridRowStyle" key={item.numeroParcela}>
                                      <td style={{ textAlign: 'center' }}>{item.numeroParcela}</td>
                                      <td style={{ textAlign: 'center' }}>{item.dtVencimento ? format(new Date(item.dtVencimento), 'dd/MM/yyyy') : ''}</td>
                                      <td style={{ textAlign: 'center' }}>{item.dtNovoVencimento ? format(new Date(item.dtNovoVencimento), 'dd/MM/yyyy') : ''}</td>
                                      <td style={{ textAlign: 'center' }}>R$ {item.valorParcela!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                      <td style={{ textAlign: 'center' }}>{item.status}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: '10px', height: '50px', maxWidth: '70%' }}>
                            <div className="col-md-3">
                              <button className="input-group-btn"
                                style={{
                                  width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                                  justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                                }} onClick={prorrogarParcelas}>
                                <a>
                                  <FontAwesomeIcon icon={faSave} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                                </a>
                                <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Confirmar</strong>
                              </button>
                            </div>
                            <div className="col-md-3">
                              <button className="input-group-btn"
                                style={{
                                  width: '80%', display: 'flex', alignItems: 'center', backgroundColor: 'red', borderColor: 'red',
                                  justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                                }} onClick={() => window.history.back()}>
                                <a>
                                  <FontAwesomeIcon icon={faClose} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                                </a>
                                <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Cancelar</strong>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                          <span style={{ color: 'red' }}>Não há parcelas em aberto neste Contrato!</span>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
  
}

export default ProrrogacaoVencimentoManualComponent;