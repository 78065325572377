
export class EsteiraDTO {
  public id?: number | string | null;
  public ativo!: boolean;
  public numeroEtapa!: number;
  public descricao!: string;
  public fase!: number;
  public numeroEtapaAprovado!: number;
  public numeroEtapaReprovado!: number;
  public numeroEtapaPendente!: number;
  public situacaoAprovado!: string;
  public situacaoPendente!: string;
  public situacaoReprovado!: string;
  public etapaProcesso!: string;
  public etapaAcao!: string;
  public idEsteiraModelo?: number | null;
  public nomeEsteira!: string;
}