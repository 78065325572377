import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { StatusProcessamentoEnum } from '../../../shared/enum/statusProcessamentoEnum';
import { ArquivoDetalheDTO } from '../../../shared/dto/arquivoDetalheDTO';
import { Button } from 'react-bootstrap';
import ArquivoService from '../../../services/arquivoService';

function ArquivoLoteDetalhe() {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { idArquivo } = useParams();
  const [detalhe, setDetalhe] = useState<ArquivoDetalheDTO>();

  const arquivoService: ArquivoService = new ArquivoService();

  const listarArquivosBaixaLote = async () => {
    try {
      setLoading(true);

      const response = await arquivoService.listarArquivoLoteDetalhe(idArquivo!);
      const json = response.data;
      setDetalhe(json);
      
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarArquivosBaixaLote();
  }, [refresh]);

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-body">
              <div className="card-header">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <strong>Detalhes do Arquivo</strong>
                  <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                    Voltar
                  </Button>
                </div>
              </div>

              <div className="card" style={{ marginTop: '25px' }}>
                <div className="card-body">
                  <div className="card-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <strong>Dados do Arquivo</strong>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-md-3">
                      <div className="form-group">
                        <span style={{ textAlign: 'left' }}>Nome Arquivo</span>
                        <input type="text" value={detalhe?.nomeArquivo} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <span style={{ textAlign: 'left' }}>Status Processamento</span>
                        <input type="text" value={StatusProcessamentoEnum.find(i => i.value === detalhe?.statusProcessamento.toString())?.label} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <span style={{ textAlign: 'left' }}>Mensagem Processamento</span>
                        <input type="text" value={detalhe?.mensagem} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group" style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', borderColor: 'black', color: 'black' }}>
                        <button onClick={() => setRefresh(!refresh)} style={{ display: 'inline-block', border: '1px solid black', borderRadius: '4px', padding: '5px', backgroundColor: 'black' }}>
                          <FontAwesomeIcon icon={faRefresh} style={{ fontSize: 'larger', color: 'white' }} />
                        </button>
                      </div>
                    </div>
                  </div>
                  {detalhe?.detalhes != null && detalhe?.detalhes!.length > 0 ? (
                    <>
                      <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                        <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                          <tbody>
                            <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                              <th style={{ textAlign: 'center' }} scope="col">Nº Linha</th>
                              <th style={{ textAlign: 'center' }} scope="col">Conteúdo</th>
                              <th style={{ textAlign: 'center' }} scope="col">Status Processamento</th>
                              <th style={{ textAlign: 'center' }} scope="col">Mensagem</th>
                            </tr>
                            {detalhe?.detalhes!.map((item) => (
                              <tr className="GridRowStyle">
                                <td style={{ textAlign: 'center' }}>{item.numeroLinha}</td>
                                <td style={{ textAlign: 'center' }}>{item.conteudoLinha}</td>
                                <td style={{ textAlign: 'center' }}>{StatusProcessamentoEnum.find(i => i.value === item.statusProcessamento.toString())?.label}</td>
                                <td style={{ textAlign: 'center' }}>{item.mensagem}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
}

export default ArquivoLoteDetalhe;