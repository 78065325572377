import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../../shared/spinner';
import { Button } from 'react-bootstrap';
import { HistoricoFinanceiro } from '../../../../shared/model/historicoFinanceiro';
import HistoricoFinanceiroService from '../../../../services/historicoFinanceiroService';

function HistoricoFinanceiroInclusaoAlteracao() {
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [nome, setNome] = useState('');
  const [entradaSaida, setEntradaSaida] = useState('ENTRADA');
  const [codigoHistLancamento, setCodigoHistLancamento] = useState<number>();

  const [loading, setLoading] = useState(false);

  const historicoFinanceiroService: HistoricoFinanceiroService = new HistoricoFinanceiroService();

  const listarHistoricoFinanceiroPorId = async () => {
    setLoading(true);
    try {
      const response = await historicoFinanceiroService.listarHistoricoFinanceiroPorId(id);
      const json = response.data;

      setNome(json.nome);
      setEntradaSaida(json.tipoEntradaSaida);
      setCodigoHistLancamento(json.codigoLancamento);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };


  const handleSubmit = async (event: any) => {

    event.preventDefault();
    let histFinanceiro: HistoricoFinanceiro = new HistoricoFinanceiro();
    histFinanceiro.id = id ? parseInt(id) : null;
    histFinanceiro.nome = nome;
    histFinanceiro.codigoLancamento = (codigoHistLancamento!);
    histFinanceiro.tipoEntradaSaida = (entradaSaida);

    try {
      await historicoFinanceiroService.incluirAlterarHistoricoFinanceiro(histFinanceiro);
      setLoading(false);
      alert('Histórico Financeiro alterado com sucesso!');
      window.history.back();
    } catch (err) {
      setLoading(false);
      alert('Ocorreu um erro ao alterar o Histórico Financeiro!');
      console.error(err);
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarHistoricoFinanceiroPorId();
    }
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>BackOffice &gt; Tabelas &gt; Histórico Financeiro &gt; Inclusão</h5> : (
                <h5>BackOffice &gt; Tabelas &gt; Histórico Financeiro &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            {tipo === 'alteracao' ? (
              <div className="col-xl">
                <label>Código</label>
                <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
              </div>
            ) : null}
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Tipo Entrada/Saída <span style={{ color: 'red' }}>*</span></label>
              <div style={{ display: 'flex', width: '100%', backgroundColor: 'white' }}>
                <select className="form-select" style={{ backgroundColor: 'white', marginRight: '10px', width: '25%' }}
                  value={entradaSaida}
                  onChange={(e) => setEntradaSaida(e.target.value)}>
                  <option style={{ backgroundColor: 'white' }} value="ENTRADA">Entrada</option>
                  <option style={{ backgroundColor: 'white' }} value="SAIDA">Saída</option>
                </select>
              </div>
            </div>

            <div className="col-xl">
              <label>Código Histórico de Lançamento<span style={{ color: 'red' }}>*</span></label>
              <input required type="number" className="form-control" value={codigoHistLancamento} onChange={(e) => setCodigoHistLancamento(parseInt(e.target.value))} />
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Histórico': 'Salvar Histórico'}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default HistoricoFinanceiroInclusaoAlteracao;