import createAxiosInstance from './axiosConfig';
import { ArquivoDTO } from '../shared/dto/arquivoDTO';
import { BaixaParcelaDTO } from '../shared/dto/baixaParcelaDTO';
import { EstornoParcelaDTO } from '../shared/dto/estornoParcelaDTO';
import { ProrrogacaoVencimentoParcelaDTO } from '../shared/dto/prorrogacaoVencimentoParcelaDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/operacaoParcelaController');

export default class OperacaoParcelaService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async processarArquivoLote(tipoArquivoProcessamento: string, numeroCessao: string, arquivoDTO: ArquivoDTO) {
    return await this.axios.post(`/processarArquivoLote?tipoArquivo=${tipoArquivoProcessamento}&idCessao=${numeroCessao}`, arquivoDTO);
  };

  async reprocessarArquivoLote(tipoArquivoProcessamento: string, idArquivo: string) {
    return await this.axios.post(`/reprocessarArquivoLote?idArquivo=${idArquivo}&tipoArquivo=${tipoArquivoProcessamento}`, {});
  };

  async listarParcelasPorContrato(contrato: string) {
    return await this.axios.get(`/listarParcelasPorContrato?contrato=${contrato}`);
  };

  async listarParcelasParaBaixa(contrato: string, dto: BaixaParcelaDTO | null) {
    return await this.axios.post(`/listarParcelasParaBaixa?contrato=${contrato}`, dto);
  };

  async baixarParcelas(contrato: string, dto: BaixaParcelaDTO) {
    return await this.axios.post(`/baixarParcelas?contrato=${contrato}`, dto);
  };

  async listarParcelasBaixadasPorContrato(contrato: string) {
    return await this.axios.get(`/listarParcelasBaixadasPorContrato?contrato=${contrato}`);
  };

  async cancelarMovimentacao(motivo: string, dto: EstornoParcelaDTO[] | null) {
    return await this.axios.post(`/cancelarMovimentacao?motivoCancelamento=${motivo}`, dto);
  };

  async estornarParcelas(dto: EstornoParcelaDTO[] | null) {
    return await this.axios.post(`/estornarParcelas`, dto);
  };

  async listarParcelasParaProrrogacao(contrato: string, comecarDaParcela: number, dtVencimentoProrrogacao: Date) {
    return await this.axios.get(`/listarParcelasParaProrrogacao?contrato=${contrato}&comecoParcela=${comecarDaParcela}&dtProrrogacao=${dtVencimentoProrrogacao}`);
  };

  async prorrogarParcelas(dtMovimento: Date, dto: ProrrogacaoVencimentoParcelaDTO[] | null) {
    return await this.axios.post(`/prorrogarParcelas?dtMovimentacao=${dtMovimento}`, dto);
  };

  async getOperacaoParcelaTipoMovimentacaoEnum() {
    return await this.axios.get(`/getOperacaoParcelaTipoMovimentacaoEnum`);
  };

}