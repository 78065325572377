import createAxiosInstance from './axiosConfig';
import { PropostaObservacao } from '../shared/model/propostaObservacao';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/propostaObservacaoController');

export default class PropostaObservacaoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async inclusaoPropostaObservacao(dto: PropostaObservacao) {
    return await this.axios.post(`/inclusaoPropostaObservacao`, dto);
  };

}