import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../../shared/spinner';
import InputMask from 'react-input-mask';
import { Fidc } from '../../../../shared/model/fidc';
import { Cessao } from '../../../../shared/model/cessao';
import { format } from 'date-fns';
import CessaoService from '../../../../services/cessaoService';
import FidcService from '../../../../services/fidcService';

function CessaoInclusaoAlteracao() {
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();
  
  const [fidc, setFidc] = useState('');
  const [fidcs, setFidcs] = useState<Fidc[]>([]);
  const [codigo, setCodigo] = useState('');
  const [tipoRisco, setTipoRisco] = useState(1);
  const [nome, setNome] = useState('');
  const [dtBase, setDtBase] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [taxaCessao, setTaxaCessao] = useState('');

  const [loading, setLoading] = useState(false);
  const cessaoService: CessaoService = new CessaoService();
  const fidcService: FidcService = new FidcService();

  const listarFidcs = async () => {
    setLoading(true);
    try {
      const response = await fidcService.listarTodosFidcs();
      
      setFidcs(response.data);
      if (tipo === 'inclusao') {
        setDtBase(format(new Date(), 'dd/MM/yyyy'));
        setFidc(response.data[0].id?.toString()!);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const listarCessaoPorId = async () => {
    setLoading(true);
    try {
      const response = await cessaoService.listarCessaoPorId(id);
      const json = response.data;

      setNome(json.nome);
      setCodigo(json.codigo);
      setFidc(json.idFidc!.toString());
      setTipoRisco(json.tipoRisco!);
      setDtBase(format(new Date(json.dtInclusao), 'dd/MM/yyyy'));
      setTaxaCessao(json.taxa.toString());

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      alert(err.response.data);
      console.error(err);
    }
  };

  const handleSubmit = async (event: any) => {

    event.preventDefault();
    let cessao: Cessao = new Cessao();
    cessao.id = id ? parseInt(id) : null;
    cessao.nome = nome;
    cessao.idFidc = fidc ? parseInt(fidc) : null;
    cessao.codigo = codigo;
    cessao.tipoRisco = tipoRisco;
    cessao.taxa = parseFloat(taxaCessao.replace('.', '').replace(',', '.'));

    try {
      await cessaoService.incluirAlterarCessao(cessao);
      setLoading(false);
      if (tipo === 'inclusao') {
        alert('Cessão salva com sucesso!');
      } else {
        alert('Cessão alterada com sucesso!');
      }
      window.history.back();
    } catch (err) {
      setLoading(false);
      alert('Ocorreu um erro ao alterar a Cessão!');
      console.error(err);
    }
  };

  const checkTipoRisco = (option: any) => {
    setTipoRisco(option);
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarCessaoPorId();
    }
    listarFidcs();
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>BackOffice &gt; Cessão &gt; Nova Cessão de Crédito</h5> : (
                <h5>BackOffice &gt; Cessão &gt; Alterar Cessão de Crédito</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row" style={{ marginTop: '20px' }}>
            {tipo === 'alteracao' ? (
              <div className="col-md-1">
                <label>ID</label>
                <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
              </div>
            ) : null}
            <div className="col-md-5">
              <label>FIDC <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" value={fidc} onChange={(e) => setFidc(e.target.value)}>
                {fidcs.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-5">

              <label>Tipo de Risco <span style={{ color: 'red' }}>*</span></label>
              <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
                <label>
                  <input type="radio" name="options" value="1" checked={tipoRisco === 1} style={{ marginRight: '5px' }}
                    onChange={() => checkTipoRisco(1)}
                  />
                  Sem retenção de risco
                </label>
                <label>
                  <input type="radio" name="options" value="2" checked={tipoRisco === 2} style={{ marginRight: '5px' }}
                    onChange={() => checkTipoRisco(2)}
                  />
                  Com retenção de risco
                </label>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-2">
              <label>Código <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Data Base </label>
              <InputMask required style={{ backgroundColor: 'LightGrey' }} mask="99/99/9999" type="text" className="form-control" value={dtBase} readOnly/>
            </div>
            <div className="col-xl">
              <label>Taxa <span style={{ color: 'red' }}>*</span></label>
              <InputMask required maskChar={null} mask="9,999" type="text" className="form-control" value={taxaCessao} onChange={(e) => setTaxaCessao(e.target.value)} />
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Cessão' : 'Salvar Cessão'}</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default CessaoInclusaoAlteracao;