export class ResultadoSimulacaoPropostaDTO {
  public promotora!: string;
  public empregador!: string;
  public financiamento!: string;
  public produto!: string;
  public valorRMC!: string;
  public valorParcela!: string;
  public valorSolicitado!: string;
  public prazo!: string;

  public valorSolicit!: string;
  public valorIof!: string;
  public valorPrincipal!: string;
  public valorParc!: string;
  public valorRefin!: string;
  public valorCompra!: string;
  public valorLiquido!: string;
  public valorBruto!: string;
  public taxaClAM!: string;
  public taxaClAA!: string;
  public taxaCetAM!: string;
  public taxaCetAA!: string;
  public primeiroVencimento!: Date;
  public ultimoVencimento!: Date;
}