import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { Empregador } from '../../../shared/model/empregador';
import { ButtonAlterar, ButtonAtivar, ButtonInativar, ButtonOrgao, ButtonPromotora } from '../../../shared/buttons';
import EmpregadorService from '../../../services/empregadorService';

function EmpregadorComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [empregadores, setEmpregadores] = useState<Empregador[]>([]);

  const empregadorService: EmpregadorService = new EmpregadorService();

  const listarTodosEmpregadores = async () => {
    try {
      setLoading(true);
      const responseList = await empregadorService.listarTodosEmpregadores();
      const jsonListEmpregadores = responseList.data;
      setEmpregadores(jsonListEmpregadores);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarEmpregador = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }
    if (confirmacao) {
      try {
        await empregadorService.ativarInativarEmpregador(id);
        listarTodosEmpregadores();
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    listarTodosEmpregadores();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Empregador</h5>
        <Button
          onClick={() => navigate('/parametros-empregador/inclusao', { state: { id: null } })}
          style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
        >
          + Novo Empregador
        </Button>
      </div>
      {loading ? <Spinner loading={loading} /> : (
        <div className="table-responsive">
          <div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col">Nome</th>
                  <th scope="col">CNPJ</th>
                  <th scope="col">Código Externo</th>
                  <th scope="col">Alteração</th>
                  <th scope="col">Orgãos</th>
                  <th scope="col">Promotoras</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {empregadores.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.nome}</td>
                    <td>{item.cnpj}</td>
                    <td>{item.codigoExterno}</td>
                    <td>
                      <Link to={`/parametros-empregador/alteracao`} state={{ id: item.id }}>
                        <ButtonAlterar />
                      </Link>
                    </td>
                    <td>
                      <Link to={`/parametros-empregador/orgao`} state={{ idEmpregador: item.id }}>
                        <ButtonOrgao />
                      </Link>
                    </td>
                    <td>
                      <Link to={`/promotora-empregador`} state={{ chamada: 'empregador', idPromotora: 0, idEmpregador: item.id }}>
                        <ButtonPromotora />
                      </Link>
                    </td>
                    <td>
                      {item.ativo ?
                        <ButtonInativar onClick={() => ativarInativarEmpregador(item.id, item.ativo)} />
                        :
                        <ButtonAtivar onClick={() => ativarInativarEmpregador(item.id, item.ativo)} />
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmpregadorComponent;