import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { format, addMonths, addDays } from 'date-fns';
import { TabelaIdadeRMC } from '../../../shared/dto/tabelaIdadeRMC';
import { Button } from 'react-bootstrap';
import { cnpj as cnpjValidacao } from 'cpf-cnpj-validator';
import { Empregador } from '../../../shared/model/empregador';
import EmpregadorService from '../../../services/empregadorService';

function EmpregadorInclusaoAlteracao() {
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [averbacao, setAverbacao] = useState('');
  const [parcelaMinima, setParcelaMinima] = useState('');
  const [qtdContratos, setQtdContratos] = useState('');
  const [validarPor, setValidarPor] = useState('');
  const [diaCorte, setDiaCorte] = useState('');
  const [diaRepasse, setDiaRepasse] = useState('');
  const [diaVencimento, setDiaVencimento] = useState('');
  const [ajustaDU, setAjustaDU] = useState('');
  const [faixaCarencia, setFaixaCarencia] = useState('');
  const [dataBaseSim, setDataBaseSim] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dataPrimeiroVencimento, setDataPrimeiroVencimento] = useState('');
  const [tamanhoMatricula, setTamanhoMatricula] = useState('');
  const [codigoExterno, setCodigoExterno] = useState('');
  const [codigoAutorizador, setCodigoAutorizador] = useState('');
  const [consultaMargemOnline, setConsultaMargemOnline] = useState<boolean>(false);
  const [reservaMargemOnline, setReservaMargemOnline] = useState<boolean>(false);
  const [margemExclusiva, setMargemExclusiva] = useState<boolean>(false);
  const [percentSegurancaRMC, setPercentSegurancaRMC] = useState('');
  const [diaCorteFatura, setDiaCorteFatura] = useState('');
  const [diaCortePrevia, setDiaCortePrevia] = useState('');
  const [tarifaEmissaoCartao, setTarifaEmissaoCartao] = useState('');
  const [valorAnuidade, setValorAnuidade] = useState('');
  const [politicaIdade, setPoliticaIdade] = useState<boolean>(false)
  const [limiteIdade, setLimiteIdade] = useState('');
  const [fatorMultiplicadorRMC, setFatorMultiplicadorRMC] = useState('');
  const [valorMaximoLimite, setValorMaximoLimite] = useState('');

  const [tabelaIdade, setTabelaIdade] = useState<TabelaIdadeRMC[]>([]);

  const [loading, setLoading] = useState(false);

  const empregadorService: EmpregadorService = new EmpregadorService();

  const tipoAverbacao = [
    { value: 'Valor Saque', label: 'Valor Saque' },
    { value: 'Valor RMC', label: 'Valor RMC' },
  ];

  const tipoValidarPor = [
    { value: 'CPF', label: 'CPF' },
    { value: 'CPF + Matr', label: 'CPF + Matr' },
  ];

  const tipoAjustaDU = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ];

  const tipoFaixaCarencia = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];

  const setDatas = (value: any) => {
    try {
      setDataBaseSim(value);

      const partesData = value.split('/');
      const dataFormatada = partesData[2] + '-' + partesData[1] + '-' + partesData[0];
      const novaData = new Date(dataFormatada);

      const newDatePlusOneMonth = addMonths(novaData, 1);
      const newDatePlusOneDay = addDays(newDatePlusOneMonth, 1);
      const formattedResult = format(newDatePlusOneDay, 'dd/MM/yyyy');
      setDataPrimeiroVencimento(formattedResult);
    } catch (err) {
      console.error(err);
    }
  }

  const adicionarDadoTabelaIdade = () => {
    if (!fatorMultiplicadorRMC || !limiteIdade || !valorMaximoLimite) {
      alert('Preencha todos os campos da política de idade!');
      return;
    }
    if (tabelaIdade.some(item => item.idadeMaxima.toString() === limiteIdade)) {
      alert('Já existe um registro com esta idade');
      return;
    }
    let tabelaIdad: TabelaIdadeRMC = new TabelaIdadeRMC();
    tabelaIdad.fatorRMC = parseInt(fatorMultiplicadorRMC!);
    tabelaIdad.idadeMaxima = parseInt(limiteIdade!);
    tabelaIdad.valorMaximo = valorMaximoLimite!;
    setTabelaIdade([...tabelaIdade, tabelaIdad]);
    setFatorMultiplicadorRMC('');
    setLimiteIdade('');
    setValorMaximoLimite('');
  }

  const removerDadoTabelaIdade = (index: number) => {
    const newTabelaIdade = [...tabelaIdade];
    newTabelaIdade.splice(index, 1);
    setTabelaIdade(newTabelaIdade);
  }

  const listarEmpregadorPorId = async () => {
    setLoading(true);
    try {
      const response = await empregadorService.listarEmpregadorPorId(id);
      const jsonEmpregador = response.data;
      

      setNome(jsonEmpregador.nome);
      setCnpj(jsonEmpregador.cnpj);
      setAverbacao(jsonEmpregador.averbacao);
      setParcelaMinima(jsonEmpregador.parcelaMinima);
      setQtdContratos(jsonEmpregador.qtdContratos);
      setValidarPor(jsonEmpregador.validarPor);
      setDiaCorte(jsonEmpregador.diaCorte);
      setDiaRepasse(jsonEmpregador.diaRepasse);
      setDiaVencimento(jsonEmpregador.diaVencimento);
      setAjustaDU(jsonEmpregador.ajustaDU);
      setFaixaCarencia(jsonEmpregador.faixaCarencia);
      setDataBaseSim(format(new Date(jsonEmpregador.dataBaseSim), 'dd/MM/yyyy'));
      setDataPrimeiroVencimento(format(new Date(jsonEmpregador.dataPrimeiroVencimento), 'dd/MM/yyyy'));
      setTamanhoMatricula(jsonEmpregador.tamanhoMatricula);
      setCodigoExterno(jsonEmpregador.codigoExterno);
      setCodigoAutorizador(jsonEmpregador.codigoAutorizador);
      setConsultaMargemOnline(jsonEmpregador.consultaMargemOnline);
      setReservaMargemOnline(jsonEmpregador.reservaMargemOnline);
      setMargemExclusiva(jsonEmpregador.margemExclusiva);
      setPercentSegurancaRMC(jsonEmpregador.percentSegurancaRMC);
      setDiaCorteFatura(jsonEmpregador.diaCorteFatura);
      setDiaCortePrevia(jsonEmpregador.diaCortePrevia);
      setTarifaEmissaoCartao(jsonEmpregador.tarifaEmissaoCartao);
      setValorAnuidade(jsonEmpregador.valorAnuidade);
      setPoliticaIdade(jsonEmpregador.politicaIdade);
      setTabelaIdade(jsonEmpregador.tabelaIdade);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const limparCampos = () => {
    setNome('');
    setCnpj('');
    setAverbacao('');
    setParcelaMinima('');
    setQtdContratos('');
    setValidarPor('');
    setDiaCorte('');
    setDiaRepasse('');
    setDiaVencimento('');
    setAjustaDU('');
    setFaixaCarencia('');
    setDatas(format(new Date(), 'dd/MM/yyyy'));
    setTamanhoMatricula('');
    setCodigoExterno('');
    setCodigoAutorizador('');
    setConsultaMargemOnline(false);
    setReservaMargemOnline(false);
    setMargemExclusiva(false);
    setPercentSegurancaRMC('');
    setDiaCorteFatura('');
    setDiaCortePrevia('');
    setTarifaEmissaoCartao('');
    setValorAnuidade('');
    setPoliticaIdade(false);
    setLimiteIdade('');
    setFatorMultiplicadorRMC('');
    setValorMaximoLimite('');
    setTabelaIdade([]);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!cnpjValidacao.isValid(cnpj) && cnpj !== '') {
      alert('CNPJ Inválido');
      return;
    } else if ((tabelaIdade == null || tabelaIdade.length < 1) && politicaIdade == false) {
      alert('Não existe Faixas de Idade Cadastrada!');
      return;
    } else {
      setLoading(true);
      let empregador: Empregador = new Empregador();
      empregador.id = id;
      empregador.nome = nome;
      empregador.cnpj = cnpj;
      empregador.averbacao = averbacao;
      empregador.parcelaMinima = parcelaMinima;
      empregador.qtdContratos = qtdContratos;
      empregador.validarPor = validarPor;
      empregador.diaCorte = diaCorte;
      empregador.diaRepasse = diaRepasse;
      empregador.diaVencimento = diaVencimento;
      empregador.ajustaDU = ajustaDU;
      empregador.faixaCarencia = faixaCarencia;
      const partesData = dataBaseSim.split('/');
      const dataFormatada = partesData[2] + '-' + partesData[1] + '-' + partesData[0];
      const novaData = new Date(dataFormatada);
      empregador.dataBaseSim = novaData;
      const partesData2 = dataPrimeiroVencimento.split('/');
      const dataFormatada2 = partesData2[2] + '-' + partesData2[1] + '-' + partesData2[0];
      const novaData2 = new Date(dataFormatada2);
      empregador.dataPrimeiroVencimento = novaData2;
      empregador.tamanhoMatricula = tamanhoMatricula;
      empregador.codigoExterno = codigoExterno;
      empregador.codigoAutorizador = codigoAutorizador;
      empregador.consultaMargemOnline = consultaMargemOnline;
      empregador.reservaMargemOnline = reservaMargemOnline;
      empregador.margemExclusiva = margemExclusiva;
      empregador.percentSegurancaRMC = percentSegurancaRMC;
      empregador.diaCorteFatura = diaCorteFatura;
      empregador.diaCortePrevia = diaCortePrevia;
      empregador.tarifaEmissaoCartao = tarifaEmissaoCartao;
      empregador.valorAnuidade = valorAnuidade;
      empregador.politicaIdade = politicaIdade;
      empregador.tabelaIdade = tabelaIdade;

      
      try {

        await empregadorService.incluirAlterarEmpregador(empregador).then(() => setLoading(false));

        alert('Inclusão realizada com sucesso!');
        window.location.href = '/parametros-empregador';
      } catch (err) {
        setLoading(false);
        alert('Ocorreu um erro ao criar empregador!');
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarEmpregadorPorId();
    }
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Parâmetros Cartão &gt; Empregador &gt; Inclusão</h5> : (
                <h5>Parâmetros Cartão &gt; Empregador &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>CNPJ</label>
              <InputMask mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="col-xl">
              <label>Tipo Averbação <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={averbacao[0]} value={averbacao} onChange={(e) => setAverbacao(e.target.value)}>
                {tipoAverbacao.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Parcela Mínima <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99999999" type="text" required className="form-control" value={parcelaMinima} onChange={(e) => setParcelaMinima((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>QTD Contratos <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={qtdContratos} onChange={(e) => setQtdContratos((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Validar Por: <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={validarPor[0]} value={validarPor} onChange={(e) => setValidarPor(e.target.value)}>
                {tipoValidarPor.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Dia de Corte <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={diaCorte} onChange={(e) => setDiaCorte((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia de Repasse <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={diaRepasse} onChange={(e) => setDiaRepasse((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia Vencimento <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={diaVencimento} onChange={(e) => setDiaVencimento((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Ajusta DU? <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={ajustaDU[0]} value={ajustaDU} onChange={(e) => setAjustaDU(e.target.value)}>
                {tipoAjustaDU.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Faixa de Carência <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={faixaCarencia} value={faixaCarencia} onChange={(e) => setFaixaCarencia(e.target.value)}>
                {tipoFaixaCarencia.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Data Base Sim. <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99/99/9999" className="form-control" type="text" value={dataBaseSim} onChange={(e) => setDatas(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Data 1º Vcto. </label>
              <text className="form-control" display={'none'}>{dataPrimeiroVencimento}</text>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Tamanho Matrícula <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={tamanhoMatricula} onChange={(e) => setTamanhoMatricula((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Código Externo </label>
              <InputMask mask="999999" type="text" className="form-control" value={codigoExterno} onChange={(e) => setCodigoExterno((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Código Autorizador </label>
              <input type="text" className="form-control" value={codigoAutorizador} onChange={(e) => setCodigoAutorizador(e.target.value)} />
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-evenly', marginBottom: '15px' }}>
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={consultaMargemOnline} onChange={(consultaMargemOnline) => setConsultaMargemOnline(consultaMargemOnline.target.checked)} />
                Possui Consulta de Margem Online
              </label>
            </div>
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={reservaMargemOnline} onChange={(reservaMargemOnline) => setReservaMargemOnline(reservaMargemOnline.target.checked)} />
                Possui Reserva de Margem Online
              </label>
            </div>
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={margemExclusiva} onChange={(margemExclusiva) => setMargemExclusiva(margemExclusiva.target.checked)} />
                Margem Exclusiva
              </label>
            </div>
          </div>

          <h5>Parâmetros Cartão Consignado</h5>

          <div className="row" style={{ marginBottom: '15px' }}>
            <div className="col-xl">
              <label>% Segurança RMC <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="9999999999" type="text" required className="form-control" value={percentSegurancaRMC} onChange={(e) => setPercentSegurancaRMC((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia Corte Fatura <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={diaCorteFatura} onChange={(e) => setDiaCorteFatura((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia Corte Prévia <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" required className="form-control" value={diaCortePrevia} onChange={(e) => setDiaCortePrevia((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Tarifa Emissão Cartão</label>
              <InputMask mask="9999999999" type="text" className="form-control" value={tarifaEmissaoCartao} onChange={(e) => setTarifaEmissaoCartao((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Valor Anuidade</label>
              <InputMask mask="9999999999" type="text" className="form-control" value={valorAnuidade} onChange={(e) => setValorAnuidade((e.target.value).replace(/_/g, ""))} />
            </div>
          </div>

          <h5>Política de Idade</h5>
          <div className="row">
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={politicaIdade} onChange={(politicaIdade) => setPoliticaIdade(politicaIdade.target.checked)} />
                Utilizar Política de Idade do Cadastro de Produtos
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Limite de Idade <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99" type="text" className="form-control" value={limiteIdade} onChange={(e) => setLimiteIdade((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Fator Multiplicador RMC <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="9999999999" type="text" className="form-control" value={fatorMultiplicadorRMC} onChange={(e) => setFatorMultiplicadorRMC((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Valor Máximo de Limite <span style={{ color: 'red' }}>*</span></label>
              <input  type="number" className="form-control" value={valorMaximoLimite} onChange={(e) => setValorMaximoLimite(e.target.value)} />
            </div>
            <div className="col-xl">
              <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white', cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}
                onClick={() => adicionarDadoTabelaIdade()}>+
              </Button>
            </div>
          </div>

          {tabelaIdade.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Idade Máxima</th>
                    <th scope="col">Fator RMC</th>
                    <th scope="col">Valor Máximo Limite</th>
                    <th scope="col">Excluir</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelaIdade.map((item, index) => (
                    <tr>
                      <td>{item.idadeMaxima}</td>
                      <td>{item.fatorRMC}</td>
                      <td>R$ {item.valorMaximo}</td>
                      <td>
                        <Button style={{
                          backgroundColor: '#dc3545',
                          border: '1px solid #dc3545',
                          color: 'white',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }} onClick={() => removerDadoTabelaIdade(index)}>X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : false}
          <div>
            <hr className="my-4" />
            <div>
              <hr className="my-4" />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Empregador' : 'Salvar Empregador'}</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default EmpregadorInclusaoAlteracao;