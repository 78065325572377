export const Processos = [
  { value: "", label: "" },
  { value: "APR_PROP_AUT", label: "BPO - Aprova Proposta" },
  // { value: "AGUARDA_APR_EXT", label: "BPO - Aguarda Aprovação Externa" },
  { value: "ANEXAR_DOCS", label: "BPO - Anexar Documentos" },
  { value: "DADOS_CADASTRAIS", label: "BPO - Dados Cadastrais Cliente" },
  { value: "IMP_DOC", label: "BPO - Impressão de Documentos" },
  { value: "VISUALIZAR_PROP", label: "BPO - Visualizar Proposta" },
  { value: "PROP_REP", label: "BPO - Reprovar Proposta" },
  { value: "VALIDA_ALC_EST", label: "BPO - Validar Alçada Esteira" },
  { value: "VALIDA_TP_FORMALIZ", label: "BPO - Valida Tipo Formalização" },
  { value: "BPO_EMITIR_CARTAO", label: "BPO - Emitir Cartão" },
  { value: "INTEGRA_BACKOFFICE", label: "BPO - Integração BackOffice" },
  { value: "PROP_DADOS_LIB", label: "BPO - Dados Liberação Proposta" },
  { value: "LECCA_INC_PROP", label: "Lecca - Inclusão de Proposta" },
  { value: "CERQ_INC_CCB", label: "CERQ - Registro CCB" },
  { value: "ANALISE_RMC", label: "BPO - Análise RMC" },
  { value: "AVERBACAO_RMC", label: "BPO - Averbação RMC" },
  { value: "EXCLUSAO_RMC", label: "BPO - Exclusão RMC" },
  { value: "BPO_INC_FORM_DIG", label: "BPO - Inclusão Formalização Digital" },
  { value: "BPO_ENTR_CART", label: "BPO - Notifica Entrega Cartão" },
  { value: "BPO_NOTIF_DIG", label: "BPO - Notifica Digital" },
];