import 'bootstrap/dist/css/bootstrap.css';
import { cpf as cpfValidacao } from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EnderecoDTO } from '../../../shared/dto/enderecoDTO';
import { OperadorDTO } from '../../../shared/dto/operadorDTO';
import { Gerente } from '../../../shared/model/gerente';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import SupervisorService from '../../../services/supervisorService';
import OperadorService from '../../../services/operadorService';
import ViaCepService from '../../../services/viaCepService';

function OperadorInclusaoAlteracao() {
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();
  
  const [gerente, setGerente] = useState('');
  const [gerentes, setGerentes] = useState<Gerente[]>([]);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [numeroCertificacao, setNumeroCertificacao] = useState('');
  const [codigoBanco, setCodigoBanco] = useState('');
  const [emails, setEmails] = useState('');
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');

  const [loading, setLoading] = useState(false);

  const supervisorService: SupervisorService = new SupervisorService();
  const operadorService: OperadorService = new OperadorService();
  const viaCepService: ViaCepService = new ViaCepService();

  const listarGerentes = async () => {
    try {
      const response = await supervisorService.listarTodosGerentesAtivos();
      if (tipo === 'inclusao') {
        setGerente(response.data[0].id.toString());
      }
      setGerentes(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const listarOperadorPorId = async () => {
    setLoading(true);
    try {
      const response = await operadorService.listarOperadorPorId(id);
      const jsonOperador = response.data;
      

      setGerente(jsonOperador.idGerente.toString()!);
      setNome(jsonOperador.nome);
      setCpf(jsonOperador.cpf);
      setEmails(jsonOperador.email);
      setCodigoBanco(jsonOperador.codigoBanco);
      setNumeroCertificacao(jsonOperador.numeroCertificacao);
      setTelefoneComercial(jsonOperador.foneComercial);
      setTelefoneCelular(jsonOperador.foneCelular);
      setCep(jsonOperador.cep);
      setLogradouro(jsonOperador.endereco);
      setNumero(jsonOperador.numeroEndereco);
      setComplemento(jsonOperador.complemento);
      setBairro(jsonOperador.bairro);
      setLocalidade(jsonOperador.cidade);
      setUf(jsonOperador.uf);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleSubmit = async (event: any) => {
    if (!cpfValidacao.isValid(cpf)) {
      alert('CPF Inválido');
      return;
    } else {
      event.preventDefault();
      setLoading(true);
      let endereco : EnderecoDTO = new EnderecoDTO();
      endereco.cep = cep;
      endereco.logradouro = logradouro;
      endereco.numero = numero;
      endereco.complemento = complemento;
      endereco.bairro = bairro;
      endereco.uf = uf;
      endereco.localidade = localidade;
      let operadorDTO: OperadorDTO = new OperadorDTO();
      operadorDTO.gerente = gerente;
      operadorDTO.id = id!;
      operadorDTO.nome = nome;
      operadorDTO.cpf = cpf;
      operadorDTO.email = emails;
      operadorDTO.codigoBanco = codigoBanco;
      operadorDTO.numeroCertificacao = numeroCertificacao;
      operadorDTO.enderecoDTO = endereco;
      operadorDTO.telefoneComercial = telefoneComercial;
      operadorDTO.telefoneCelular = telefoneCelular;

      try {
        await operadorService.incluirAlterarOperador(operadorDTO);
        setLoading(false);
        alert('Operador salvo com sucesso!');
        window.history.back();
      } catch (err: any) {
        setLoading(false);
        alert(err.response.data);
        console.error(err);
      }
    }
  };

  const limparCampos = () => {
    setNome('');
    setCpf('');
    setEmails('');
    setTelefoneComercial('');
    setTelefoneCelular('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setLocalidade('');
    setUf('');
  }

  const viaCep = async (cep: string) => {
    if (cep !== undefined && cep !== '' && cep.length === 10) {
      console.log(cep)
      setLoading(true);
      const cepWithoutMask = cep.replace(/\D/g, '');
      try {
        const response = await viaCepService.viaCep(cepWithoutMask);

        const jsonViaCep = response.data;
        setLogradouro(jsonViaCep.logradouro);
        setLocalidade(jsonViaCep.localidade);
        setUf(jsonViaCep.uf);
        setBairro(jsonViaCep.bairro);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarOperadorPorId();
    }
    listarGerentes();
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <h5>Origens &gt; Operador &gt; Alteração</h5>
              <Link to="/origens-operador">
                <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}> Voltar</Button>
              </Link>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Supervisor <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={gerente} value={gerente} onChange={(e) => setGerente(e.target.value)}>
                {gerentes.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>CPF <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={(e) => setCpf(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Nº da Certificação</label>
              <input type="number" className="form-control" value={numeroCertificacao} onChange={(e) => setNumeroCertificacao(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Código Banco</label>
              <input type="number" className="form-control" value={codigoBanco} onChange={(e) => setCodigoBanco(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Lista de E-mails (separados por ';') <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={emails} onChange={(e) => setEmails(e.target.value)} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {loading ? <Spinner loading={loading} /> : false}
            <div className="col-md-2">
              <label>CEP <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99.999-999" type="text" className="form-control" required
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                onBlur={(e) => viaCep(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label>Endereço <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label>Número <span style={{ color: 'red' }}>*</span></label>
              <input type="number" className="form-control" required value={numero} onChange={(e) => setNumero(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label>Bairro <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={bairro} onChange={(e) => setBairro(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Complemento</label>
              <input type="text" className="form-control" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Cidade <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={localidade} onChange={(e) => setLocalidade(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Estado <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="aa" type="text" required className="form-control" value={uf} onChange={(e) => setUf(e.target.value.toUpperCase())} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            <div className="col-md">
              <label className="form-label">Telefone Comercial <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)9999-9999" type="text" className="form-control" value={telefoneComercial} onChange={(e) => setTelefoneComercial(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-md">
              <label className="form-label">Telefone Celular <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)99999-9999" type="text" className="form-control" value={telefoneCelular} onChange={(e) => setTelefoneCelular(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Operador' : 'Salvar Operador'}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default OperadorInclusaoAlteracao;