import createAxiosInstance from './axiosConfig';
import { HistoricoFinanceiro } from '../shared/model/historicoFinanceiro';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/historicoFinanceiroController');

export default class HistoricoFinanceiroService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarHistoricoFinanceiroPorTipoEAtivo() {
    return await this.axios.get(`/listarHistoricoFinanceiroPorTipoEAtivo?tipo=ENTRADA`);
  };

  async listarTodasHistoricoFinanceiros() {
    return await this.axios.get(`/listarTodasHistoricoFinanceiros`);
  };

  async ativarInativarHistoricoFinanceiro(id: string) {
    return await this.axios.get(`/ativarInativarHistoricoFinanceiro?id=${id}`);
  };

  async listarHistoricoFinanceiroPorId(id: string) {
    return await this.axios.get(`/listarHistoricoFinanceiroPorId?id=${id}`);
  };

  async incluirAlterarHistoricoFinanceiro(dto: HistoricoFinanceiro) {
    return await this.axios.post(`/incluirAlterarHistoricoFinanceiro`, dto);
  };

}