import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../../shared/spinner';
import { ButtonAlterar } from '../../../../shared/buttons';
import { Cessao } from '../../../../shared/model/cessao';
import { format } from 'date-fns';
import CessaoService from '../../../../services/cessaoService';

function CessaoComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cessoes, setCessoes] = useState<Cessao[]>([]);

  const cessaoService: CessaoService = new CessaoService();

  const listarTodasCessoes = async () => {
    try {
      setLoading(true);
      const responseList = await cessaoService.listarTodasCessoes();
      setCessoes(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarTodasCessoes();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Cessão &gt; Cessão de Carteira</h5>
          <Button
            onClick={() => navigate('/backoffice-tabelas/cessao/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Nova Cessão
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Data Base</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Taxa %</th>
                    <th scope="col">Alteração</th>
                  </tr>
                </thead>
                <tbody>
                  {cessoes.map((item) => (
                    <tr>
                      <td>{item.codigo}</td>
                      <td>{item.nome}</td>
                      <td>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy') : ''}</td>
                      <td>R$ {item.valor!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{item.taxa}</td>
                      <td>
                          <Link to={`/backoffice-tabelas/cessao/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CessaoComponent;