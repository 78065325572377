import createAxiosInstance from './axiosConfig';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/promotoraEmpregadorController');

export default class PromotoraEmpregadorService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarEmpregadorPorPromotora(id: string) {
    return await this.axios.get(`/listarEmpregadorPorPromotora?idPromotora=${id}`);
  };

}