import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import { PromotoraEmpregadorDTO } from '../../shared/dto/promotoraEmpregadorDTO';
import { Button } from 'react-bootstrap';
import EmpregadorService from '../../services/empregadorService';

function PromotoraEmpregador() {
  const location = useLocation();
  const { chamada, idPromotora, idEmpregador } = location.state || {};

  const [loading, setLoading] = useState(true);
  const [promotoraEmpregador, setPromotoraEmpregador] = useState<PromotoraEmpregadorDTO[]>([]);

  const empregadorService: EmpregadorService = new EmpregadorService();

  const listarTodosEmpregadores = async () => {
    try {
      setLoading(true);
      const responseList = await empregadorService.listarPromotorasEmpregadores(idPromotora, idEmpregador);
      const jsonListPromotoraEmpregador = responseList.data;
      setPromotoraEmpregador(jsonListPromotoraEmpregador);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleAtivoChange = (id: any) => {
    setPromotoraEmpregador((prevData) =>
      prevData.map((item) =>
        item.id === id ? {
          ...item, ativo: !item.ativo,
          consultaMargem: (!item.ativo ? item.consultaMargem : false),
          reservaMargem: (!item.ativo ? item.reservaMargem : false)
        } : item
      )
    );
  };

  const handleConsultaChange = (id: any) => {
    setPromotoraEmpregador((prevData) =>
      prevData.map((item) =>
        item.id === id && item.ativo === true ? { ...item, consultaMargem: !item.consultaMargem } : item
      )
    );
  };

  const handleReservaChange = (id: any) => {
    setPromotoraEmpregador((prevData) =>
      prevData.map((item) =>
        item.id === id && item.ativo === true ? { ...item, reservaMargem: !item.reservaMargem } : item
      )
    );
    
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
     await empregadorService.incluirAlterarPromotoraEmpregador(chamada, chamada === 'promotora' ? idPromotora : idEmpregador, promotoraEmpregador)
        .then(() => setLoading(false));
      alert('Atualização realizada com sucesso!');
    } catch (err) {
      setLoading(false);
      alert('Ocorreu um erro ao atualizar listar!');
      console.error(err);
    }
  }

  useEffect(() => {
    listarTodosEmpregadores();
  }, []);

  return (
    <div className="position-relative m-md-3">
      {loading ? <Spinner loading={loading} /> : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px' }}>
            {chamada === 'empregador' ? <h5>Empregador &gt; Promotora</h5> : <h5>Promotora &gt; Empregador</h5>}
            <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}> Voltar</Button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="table-responsive">
              <div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Ativo</th>
                      <th scope="col">Código</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Consulta Margem</th>
                      <th scope="col">Reserva Margem</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promotoraEmpregador.map((item) => (
                      <tr>
                        <td><input type="checkbox" checked={item.ativo} onChange={() => handleAtivoChange(item.id)} /></td>
                        <td>{item.id} </td>
                        <td>{item.nome} </td>
                        <td><input type="checkbox" checked={item.consultaMargem} onChange={() => handleConsultaChange(item.id)} /></td>
                        <td><input type="checkbox" checked={item.reservaMargem} onChange={() => handleReservaChange(item.id)} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Atualizar Listar</button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default PromotoraEmpregador;