import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import { format, parse } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { BaixaParcelaDTO } from '../../../shared/dto/baixaParcelaDTO';
import { MovimentoFinanceiroEnum } from '../../../shared/enum/movimentoFinanceiroEnum';
import { EstornoParcelaDTO } from '../../../shared/dto/estornoParcelaDTO';
import OperacaoParcelaService from '../../../services/operacaoParcelaService';
import OperacaoService from '../../../services/operacaoService';

function EstornoParcelaComponent() {
  const [loading, setLoading] = useState(false);

  const [dto, setDto] = useState<BaixaParcelaDTO>();
  const [parcelasList, setParcelasList] = useState<EstornoParcelaDTO[] | null>([]);
  const [exibeDetalhe, setExibeDetalhe] = useState(false);

  const [contrato, setContrato] = useState('');
  const [dtMovimento, setDtMovimento] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [nomeCliente, setNomeCliente] = useState('');

  const movimento = MovimentoFinanceiroEnum;

  const operacaoParcelaService: OperacaoParcelaService = new OperacaoParcelaService();
  const operacaoService: OperacaoService = new OperacaoService();

  const listarContrato = async () => {
    if (contrato === '') return alert('Preencha o campo de busca');
    try {
      setParcelasList([]);
      setLoading(true);

      const responseList = await operacaoParcelaService.listarParcelasBaixadasPorContrato(contrato);
      setParcelasList(responseList.data);
      const response = await operacaoService.listarNomeClientePorContrato(contrato);
      setNomeCliente(response.data);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const estornarParcelas = async () => {
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return alert('Data de movimento não pode ser superior a data de hoje');
    if (parcelasList!.filter(item => item.estornar).length === 0) return alert('Selecione pelo menos um item para estorno');

    try {
      setLoading(true);
      
      await operacaoParcelaService.estornarParcelas(parcelasList);
      setExibeDetalhe(false); 
      setNomeCliente('');
      
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <strong>Cancelamento de Movimentação</strong>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Contrato <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
                        value={contrato} onChange={(e) => { setContrato((e.target.value)); setExibeDetalhe(false); setNomeCliente(''); }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarContrato}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>

                {exibeDetalhe ? (
                  <>
                    <div className="" style={{ marginTop: '10px' }}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span style={{ textAlign: 'left' }}>Nome Cliente</span>
                          </div>
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          <label style={{ width: '100%' }}>Data Movimento</label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-md-3" style={{ paddingRight: '10px' }}>
                          <input type="text" value={nomeCliente} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: '10px' }}>
                          <InputMask required mask="99/99/9999" className="form-control" type="text"
                            value={dtMovimento} onChange={(e) => setDtMovimento((e.target.value).replace(/_/g, ""))} />
                        </div>
                      </div>
                    </div>
                    {parcelasList != null && parcelasList!.length > 0 ? (
                      <div className="table-responsive">
                        <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                          <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                            <tbody>
                              <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                                <th style={{ textAlign: 'center' }} scope="col">NÚMERO</th>
                                <th style={{ textAlign: 'center' }} scope="col">DATA BAIXA</th>
                                <th style={{ textAlign: 'center' }} scope="col">VALOR BAIXA</th>
                                <th style={{ textAlign: 'center' }} scope="col">HIST. FIN. BAIXA</th>
                                <th style={{ textAlign: 'center' }} scope="col">HIST. FIN. ESTORNO</th>
                                <th style={{ textAlign: 'center' }} scope="col">ESTORNAR</th>
                              </tr>
                              {parcelasList.map((item) => (
                                <tr className="GridRowStyle">
                                  <td style={{ textAlign: 'center' }}>{item.numeroParcela}</td>
                                  <td style={{ textAlign: 'center' }}>{item.dtBaixa ? format(new Date(item.dtBaixa), 'dd/MM/yyyy') : ''}</td>
                                  <td style={{ textAlign: 'center' }}>R$ {item.valorBaixa!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  <td style={{ textAlign: 'center' }}>{item.historicoFinanceiroBaixa}</td>
                                  <td style={{ textAlign: 'center' }}>{item.idHistoricoFinanceiroEstorno + ' - ' + item.nomeHistoricoFinanceiroEstorno}</td>
                                  <td style={{ textAlign: 'center' }}><input type="checkbox" onChange={e => item.estornar = (e.target.checked)} /></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="row" style={{ marginTop: '10px', height: '50px', maxWidth: '70%' }}>
                          <div className="col-md-3">
                            <button className="input-group-btn"
                              style={{
                                width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                                justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                              }} onClick={estornarParcelas}>
                              <a>
                                <FontAwesomeIcon icon={faSave} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                              </a>
                              <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Confirmar Estorno</strong>
                            </button>
                          </div>
                          <div className="col-md-3">
                            <button className="input-group-btn"
                              style={{
                                width: '80%', display: 'flex', alignItems: 'center', backgroundColor: 'red', borderColor: 'red',
                                justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                              }} onClick={() => window.history.back()}>
                              <a>
                                <FontAwesomeIcon icon={faClose} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                              </a>
                              <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Cancelar</strong>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : parcelasList != null ? (
                      <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                        <span style={{ color: 'red' }}>Não há saldo em aberto para este Contrato!</span>
                      </div>
                    ) : null}

                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div >
    </>
  );
}

export default EstornoParcelaComponent;