import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PromotoraDTO } from '../../../shared/dto/promotoraDTO';
import Spinner from '../../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonEmpregador, ButtonInativar } from '../../../shared/buttons';
import PromotoraService from '../../../services/promotoraService';

function PromotoraComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [promotora, setPromotora] = useState<PromotoraDTO[]>([]);

  const promotoraService: PromotoraService = new PromotoraService();

  const listAllPromotora = async () => {
    try {
      setLoading(true);
      const responseList = await promotoraService.listarTodasPromotoras();
      const jsonListPromotora = responseList.data;

      setPromotora(jsonListPromotora);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarPromotora = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }
    if (confirmacao) {
      try {
        await promotoraService.ativarInativarPromotora(id);
        listAllPromotora();

      } catch (err) {
        console.error(err);
      }
    }
  }
  useEffect(() => {
    listAllPromotora();
  }, []);


  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Promotora</h5>
          <Button
            onClick={() => navigate('/origens-promotora/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Nova Promotora
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Promotora</th>
                    <th scope="col">CNPJ</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Matriz</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Empregadores</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {promotora.map((item) => (
                    <tr>
                      <td>{item.id} - {item.nome}</td>
                      <td>{item.cnpj}</td>
                      <td>{item.cidade}</td>
                      <td>{item.telefoneComercial}</td>
                      <td>{item.email}</td>
                      <td>{item.matriz} - {item.nomeMatriz}</td>
                      <td>
                        <Link to={`/origens-promotora/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        <Link to={`/promotora-empregador`} state={{ chamada: 'promotora', idPromotora: item.id, idEmpregador: 0 }}>
                          <ButtonEmpregador />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarPromotora(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarPromotora(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}


export default PromotoraComponent;