import 'bootstrap/dist/css/bootstrap.css';
import { cpf as cpfValidacao } from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import { EnderecoDTO } from '../../../shared/dto/enderecoDTO';
import { GerenteDTO } from '../../../shared/dto/gerenteDTO';
import { Promotora } from '../../../shared/model/promotora';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import PromotoraService from '../../../services/promotoraService';
import SupervisorService from '../../../services/supervisorService';
import ViaCepService from '../../../services/viaCepService';

function SupervisorInclusaoAlteracao() {
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [promotora, setPromotora] = useState('');
  const [promotoras, setPromotoras] = useState<Promotora[]>([]);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [numeroCertificacao, setNumeroCertificacao] = useState('');
  const [emails, setEmails] = useState('');
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');

  const [loading, setLoading] = useState(false);

  const promotoraService: PromotoraService = new PromotoraService();
  const supervisorService: SupervisorService = new SupervisorService();
  const viaCepService: ViaCepService = new ViaCepService();

  const listarPromotoras = async () => {
    try {
      const response = await promotoraService.listarTodasPromotorasAtivas();
      if (tipo === 'inclusao') {
        setPromotora(response.data[0].id.toString());
      }      
      setPromotoras(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const listarGerentePorId = async () => {
    setLoading(true);
    try {
      const response = await supervisorService.listarGerentePorId(id);
      const jsonGerente = response.data;
      
      setPromotora(jsonGerente.idPromotora.toString()!);
      setNome(jsonGerente.nome);
      setCpf(jsonGerente.cpf);
      setEmails(jsonGerente.email);
      setNumeroCertificacao(jsonGerente.numeroCertificacao);
      setTelefoneComercial(jsonGerente.foneComercial);
      setTelefoneCelular(jsonGerente.foneCelular);
      setCep(jsonGerente.cep);
      setLogradouro(jsonGerente.endereco);
      setNumero(jsonGerente.numeroEndereco);
      setComplemento(jsonGerente.complemento);
      setBairro(jsonGerente.bairro);
      setLocalidade(jsonGerente.cidade);
      setUf(jsonGerente.uf);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const limparCampos = () => {
    setNome('');
    setCpf('');
    setEmails('');
    setNumeroCertificacao('');
    setTelefoneComercial('');
    setTelefoneCelular('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setLocalidade('');
    setUf('');
  }

  const handleSubmit = async (event: any) => {
    if (!cpfValidacao.isValid(cpf)) {
      alert('CPF Inválido');
      return;
    } else {
      event.preventDefault();
      setLoading(true);
      let endereco : EnderecoDTO = new EnderecoDTO();
      endereco.cep = cep;
      endereco.logradouro = logradouro;
      endereco.numero = numero;
      endereco.complemento = complemento;
      endereco.bairro = bairro;
      endereco.uf = uf;
      endereco.localidade = localidade;
      let gerenteDTO: GerenteDTO = new GerenteDTO();
      gerenteDTO.promotora = promotora;
      gerenteDTO.id = id!;
      gerenteDTO.nome = nome;
      gerenteDTO.cpf = cpf;
      gerenteDTO.email = emails;
      gerenteDTO.numeroCertificacao = numeroCertificacao;
      gerenteDTO.enderecoDTO = endereco;
      gerenteDTO.telefoneComercial = telefoneComercial;
      gerenteDTO.telefoneCelular = telefoneCelular;

      try {
        await supervisorService.incluirAlterarGerente(gerenteDTO);
        setLoading(false);
        alert('Supervisor salvo com sucesso!');
        window.history.back();
      } catch (err: any) {
        setLoading(false);
        alert(err.response.data);
        console.error(err);
      }
    }
  };

  const viaCep = async (cep: string) => {
    if (cep !== undefined && cep !== '' && cep.length === 10) {
      console.log(cep)
      setLoading(true);
      const cepWithoutMask = cep.replace(/\D/g, '');
      try {
        const response = await viaCepService.viaCep(cepWithoutMask);

        const jsonViaCep = response.data;
        setLogradouro(jsonViaCep.logradouro);
        setLocalidade(jsonViaCep.localidade);
        setUf(jsonViaCep.uf);
        setBairro(jsonViaCep.bairro);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    listarPromotoras();
    if (tipo === 'alteracao') {
      listarGerentePorId();
    }
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Origens &gt; Supervisor &gt; Inclusão</h5> : (
                <h5>Origens &gt; Supervisor &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Promotora <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={promotora} value={promotora} onChange={(e) => setPromotora(e.target.value)}>
                {promotoras.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>CPF <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={(e) => setCpf(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Nº da Certificação</label>
              <input type="number" className="form-control" value={numeroCertificacao} onChange={(e) => setNumeroCertificacao(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Lista de E-mails (separados por ';') <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={emails} onChange={(e) => setEmails(e.target.value)} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {loading ? <Spinner loading={loading} /> : false}
            <div className="col-md-2">
              <label>CEP <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99.999-999" type="text" className="form-control" required
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                onBlur={(e) => viaCep(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label>Endereço <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label>Número <span style={{ color: 'red' }}>*</span></label>
              <input type="number" className="form-control" required value={numero} onChange={(e) => setNumero(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label>Bairro <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={bairro} onChange={(e) => setBairro(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Complemento</label>
              <input type="text" className="form-control" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Cidade <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={localidade} onChange={(e) => setLocalidade(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Estado <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="aa" type="text" required className="form-control" value={uf} onChange={(e) => setUf(e.target.value.toUpperCase())} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            <div className="col-md">
              <label className="form-label">Telefone Comercial <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)9999-9999" type="text" className="form-control" value={telefoneComercial} onChange={(e) => setTelefoneComercial(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-md">
              <label className="form-label">Telefone Celular <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)99999-9999" type="text" className="form-control" value={telefoneCelular} onChange={(e) => setTelefoneCelular(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Supervisor' : 'Salvar Supervisor'}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default SupervisorInclusaoAlteracao;