import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Matriz } from '../../../shared/model/matriz';
import Spinner from '../../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import MatrizService from '../../../services/matrizService';

function MatrizComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [matriz, setMatriz] = useState<Matriz[]>([]);

  const matrizService: MatrizService = new MatrizService();

  const listarTodasMatrizes = async () => {
    try {
      setLoading(true);
      const responseList = await matrizService.listarTodasMatrizes();
      const jsonListMatriz = responseList.data;

      setMatriz(jsonListMatriz);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarMatriz = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }
    if (confirmacao) {
      try {
        await matrizService.ativarInativarMatriz(id);

        listarTodasMatrizes();
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    listarTodasMatrizes();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Matriz</h5>
          <Button
            onClick={() => navigate('/origens-matriz/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Nova Matriz
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Razão Social</th>
                    <th scope="col">CNPJ</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {matriz.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.nome}</td>
                      <td>{item.cnpj}</td>
                      <td>{item.cidade}</td>
                      <td>{item.foneComercial}</td>
                      <td>{item.email}</td>
                      <td>
                        <Link to={`/origens-matriz/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarMatriz(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarMatriz(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MatrizComponent;