import axios from 'axios';

const { REACT_APP_ESTEIRA_BACK } = process.env;

export default class VersaoSistemaService {
  axios: any;
  constructor() {
    this.axios = axios.create({
      baseURL: REACT_APP_ESTEIRA_BACK + '/api'
    })
  }

  async getVersionApi() {
    return await this.axios.get('/versao');
  };

}