import createAxiosInstance from './axiosConfig';
import { MatrizDTO } from '../shared/dto/matrizDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/matrizController');

export default class MatrizService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodasMatrizes() {
    return await this.axios.get(`/listarTodasMatrizes`);
  };

  async ativarInativarMatriz(id: string) {
    return await this.axios.get(`/ativarInativarMatriz?id=${id}`);
  };

  async listarMatrizPorId(id: string) {
    return await this.axios.get(`/listarMatrizPorId?id=${id}`);
  };

  async incluirAlterarMatriz(dto: MatrizDTO) {
    return await this.axios.post(`/incluirAlterarMatriz`, dto);
  };

  async listarTodasMatrizesAtivas() {
    return await this.axios.get(`/listarTodasMatrizesAtivas`);
  };

}