import createAxiosInstance from './axiosConfig';
import { EsteiraModelo } from '../shared/model/esteira_modelo';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/esteiraModeloController');

export default class EsteiraModeloService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodasEsteirasModelo() {
    return await this.axios.get(`/listarTodasEsteirasModelo`);
  };

  async incluirAlterarEsteiraModelo(dto: EsteiraModelo) {
    return await this.axios.post(`/incluirAlterarEsteiraModelo`, dto);
  };

}