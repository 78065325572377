import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../../../shared/buttons';
import Spinner from '../../../../shared/spinner';
import { HistoricoFinanceiro } from '../../../../shared/model/historicoFinanceiro';
import HistoricoFinanceiroService from '../../../../services/historicoFinanceiroService';

function HistoricoFinanceiroComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [histFinanceiro, setHistFinanceiro] = useState<HistoricoFinanceiro[]>([]);

  const historicoFinanceiroService: HistoricoFinanceiroService = new HistoricoFinanceiroService();

  const listarTodosHistoricosFinanceiros = async () => {
    try {
      setLoading(true);
      const responseList = await historicoFinanceiroService.listarTodasHistoricoFinanceiros();
      setHistFinanceiro(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarHistoricoFinanceiro = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }
    if (confirmacao) {
      try {
        await historicoFinanceiroService.ativarInativarHistoricoFinanceiro(id);
        listarTodosHistoricosFinanceiros();
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    listarTodosHistoricosFinanceiros();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>BackOffice &gt; Tabelas &gt; Histórico Financeiro</h5>
          <Button
            onClick={() => navigate('/backoffice-tabelas/historico-financeiro/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Novo Histórico Financeiro
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Status</th>
                    <th scope="col">Tipo E/S</th>
                    <th scope="col">Código Lançamento</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {histFinanceiro.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.nome}</td>
                      <td>{item.ativo ? 'Ativo' : 'Inativo'}</td>
                      <td>{item.tipoEntradaSaida}</td>
                      <td>{item.codigoLancamento}</td>
                      <td>
                        <Link to={`/backoffice-tabelas/historico-financeiro/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarHistoricoFinanceiro(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarHistoricoFinanceiro(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HistoricoFinanceiroComponent;