import { EnderecoDTO } from "./enderecoDTO";

export class PromotoraDTO {
  public matriz!: string;
  public nomeMatriz!: string;
  public id!: string | null;
  public ativo!: boolean;
  public nome!: string;
  public cnpj!: string;
  public email!: string;
  public cidade!: string;
  public enderecoDTO!: EnderecoDTO | undefined;
  public telefoneComercial!: string;
  public telefoneCelular!: string;
  public codExterno!: any;
  public gerenteResponsavel!: string;
  public valorMeta!: number | string;
}