export class EnderecoDTO {
  public logradouro!: string;
  public localidade!: string;
  public bairro!: string;
  public uf!: string;

  public cep!: string;
  public numero!: string;
  public complemento!: string;
  public erro!: string;
}