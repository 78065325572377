import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Motivo } from '../../../shared/model/motivo';
import Spinner from '../../../shared/spinner';
import { ButtonAlterarMotivo, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MotivoService from '../../../services/motivoService';

function MotivoComponent() {
  const [loading, setLoading] = useState(true);
  const [motivo, setMotivo] = useState<Motivo[]>([]);
  const [id1, setId1] = useState<number>();
  const [ativo, setAtivo] = useState<boolean>(false);
  const [descricao, setDescricao] = useState('');
  const [pendenciaRecusa, setPendenciaRecusa] = useState('');
  const [alteracao, setAlteracao] = useState<boolean>(false);
  const [atualizar, setAtualizar] = useState<boolean>(false);
  
  const motivoService: MotivoService = new MotivoService();

  const tipoMotivo = [
    { value: "", label: "" },
    { value: "P", label: "Pendenciar" },
    { value: "R", label: "Reprovar" },
  ]

  const listarMotivos = async () => {
    try {
      setLoading(true);
      setAlteracao(false);
      const responseList = await motivoService.listarTodosMotivos();
      setMotivo(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const ativarInativarMotivo = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }
    if (confirmacao) {
      try {
        await motivoService.ativarInativarMotivo(id);
        setAtualizar(!atualizar);
      } catch (err) {
        console.error(err);
      }
    };
  }

  const alterarMotivo = (id: number, ativo: boolean, descricao: string, pendenciaRecusa: string) => {
    if (alteracao && id === id1) {
      setDescricao('');
      setPendenciaRecusa('');
      return setAlteracao(false);
    }

    
    setAlteracao(true);
    setId1(id);
    setAtivo(ativo);
    setDescricao(descricao);
    setPendenciaRecusa(pendenciaRecusa);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      let motivo: Motivo = new Motivo();
      motivo.id = id1!;
      motivo.ativo = ativo;
      motivo.descricao = descricao!;
      motivo.pendenciaRecusa = pendenciaRecusa!;
      console.log(motivo)
      await motivoService.incluirAlterarMotivo(motivo)
        .then(() => setLoading(false));

      setAtualizar(!atualizar);
      setPendenciaRecusa('');
      setDescricao('');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarMotivos();
  }, [atualizar]);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5> Cadastro &gt; Motivos</h5>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-2">
              <label>Tipo</label>
              <select required className="form-select" value={pendenciaRecusa} onChange={(e) => setPendenciaRecusa(e.target.value)}>
                {tipoMotivo.map((motivo) => (
                  <option key={motivo.value} value={motivo.value}> {motivo.label} </option>
                ))}
              </select>
            </div>
            <div className="col-md-5">
              <label>Descrição</label>
              <input required type="text" className="form-control" value={descricao} onChange={(e) => setDescricao(e.target.value)}/>
            </div>
            <div className="col-md-5" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '20px', justifyContent: 'space-between' }}>
              {alteracao ? (
                <>
                  <button className="btn btn-success w-40" type="submit">
                    Alterar Motivo
                  </button>
                  <button className="btn btn-secondary w-40" onClick={() => { setAlteracao(false); setDescricao(''); setPendenciaRecusa('') }}>
                    Cancelar Alteração
                  </button>
                </>
              ) : (
                <button className="btn btn-success w-70" type="submit">
                  Adicionar Motivo
                </button>
              )}
              <Button className="btn btn-info w-20" style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} 
                onClick={() => setAtualizar(!atualizar)}>
                <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '1px', color: 'white' }} />
              </Button>
            </div>
          </div>
        </form>

        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive" style={{ marginTop: '20px' }}>
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {motivo.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.pendenciaRecusa}</td>
                      <td>{item.descricao}</td>
                      <td>
                        <ButtonAlterarMotivo onClick={() => alterarMotivo(item.id!, item.ativo!, item.descricao!, item.pendenciaRecusa!)} />
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarMotivo(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarMotivo(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MotivoComponent;