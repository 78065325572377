import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { format } from 'date-fns';
import { ArquivoDTO } from '../../shared/dto/arquivoDTO';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TipoDocumento } from '../../shared/select/tipoDocumento';
import PropostaDocumentoService from '../../services/propostaDocumentoService';

function ConsultaDocumentosComponent() {
  const [loading, setLoading] = useState(false);
  const [idProposta, setIdProposta] = useState('');
  const [arquivosDTO, setArquivosDTO] = useState<ArquivoDTO[]>([]);
  const [exibeDetalhe, setExibeDetalhe] = useState(false);

  const propostaDocumentoService: PropostaDocumentoService = new PropostaDocumentoService();

  const listarDocumentosProposta = async () => {
    if (idProposta === '') return alert('Por favor, informe o número da Proposta');
    setLoading(true);
    try {
      const response = await propostaDocumentoService.listarPropostaDocumentoPorIdProposta(idProposta);
      setArquivosDTO(response.data);
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const downloadArquivo = async (caminhoArquivo: string, nomeArquivo: string) => {
    if (idProposta === '') return alert('Por favor, informe o número da Proposta');
    setLoading(true);
    try {
      await propostaDocumentoService.downloadPropostaDocumentoPorCaminhoArquivo(caminhoArquivo)
        .then((resp) => {
          const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
          const urlBlob = window.URL.createObjectURL(blob);
          const linkDownload = document.createElement('a');
          linkDownload.href = urlBlob;
          linkDownload.download = nomeArquivo;
          document.body.appendChild(linkDownload);
          linkDownload.click();
          document.body.removeChild(linkDownload);
          window.URL.revokeObjectURL(urlBlob);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const downloadTodosArquivos = async () => {
    if (idProposta === '') return alert('Por favor, informe o número da Proposta');
    setLoading(true);
    try {
      await propostaDocumentoService.downloadLotePropostaDocumentoPorIdProposta(idProposta)
        .then((resp) => {
          const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
          const urlBlob = window.URL.createObjectURL(blob);
          const linkDownload = document.createElement('a');
          linkDownload.href = urlBlob;
          linkDownload.download = "Proposta_" + idProposta + '.zip';
          document.body.appendChild(linkDownload);
          linkDownload.click();
          document.body.removeChild(linkDownload);
          window.URL.revokeObjectURL(urlBlob);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Esteira &gt; Proposta &gt; Consulta de Documentos</h5>
        <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}> Voltar</Button>
      </div>

      <div className="row" style={{ marginTop: '10px' }}>
        <div className="col-md-2">
          <div className="form-group">
            <label>Proposta <span style={{ color: 'red' }}>*</span></label>
            <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
              value={idProposta} onChange={(e) => { setIdProposta((e.target.value)); setArquivosDTO([]); setExibeDetalhe(false); }} />
          </div>
        </div>
        <div className="col-md-2">
          <button className="input-group-btn"
            style={{
              backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
              justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
            }} onClick={listarDocumentosProposta}>
            <a>
              <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
            </a>
          </button>
        </div>
      </div>

      {loading ? <Spinner loading={loading} /> : (
        <div className="card" style={{ marginTop: '25px' }}>
          <div className="card-header ">
            <strong>Documentos</strong>
          </div>
          <div className="card-body">
            <div className="row">
              {arquivosDTO.length > 0 ? (
                <div style={{ marginTop: '10px', visibility: 'visible', overflowY: 'auto', width: '100%', borderWidth: '1px' }}>
                  <div>
                    <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                      <tbody>
                        <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                          <th scope="col">Nome</th>
                          <th scope="col">Tipo</th>
                          <th scope="col">Data e Hora</th>
                          <th align="left" scope="col">
                            <a onClick={() => downloadTodosArquivos()}>
                              <Button style={{ background: 'black', borderColor: 'black' }}>
                                <FontAwesomeIcon icon={faDownload} />
                              </Button>
                            </a>
                          </th>
                        </tr>
                        {arquivosDTO.map((item, index) => (
                          <tr className="GridRowStyle">
                            <td>{item.nomeArquivo}</td>
                            <td>{TipoDocumento.find(tipo => tipo.value === item.tipoDocumento.toString())?.label}</td>
                            <td>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy HH:mm') : ''}</td>
                            <td align="left">
                              <a onClick={() => downloadArquivo(item.caminhoArquivo, item.nomeArquivo)}>
                                <Button>
                                  <FontAwesomeIcon icon={faDownload} />
                                </Button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : 
                <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                  { exibeDetalhe &&
                  <span style={{ color: 'red' }}>Não há documentos para esta proposta!</span>
                  }
                </div>}
            </div>
          </div>
        </div>

      )}
    </div >
  );
}

export default ConsultaDocumentosComponent;
