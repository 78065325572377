export enum ProcessoPropostaEsteiraEnum {
  APR_PROP_AUT = 'APR_PROP_AUT',
  AGUARDA_APR_EXT = 'AGUARDA_APR_EXT',
  ANEXAR_DOCS = 'ANEXAR_DOCS',
  DADOS_CADASTRAIS = 'DADOS_CADASTRAIS',
  IMP_DOC = 'IMP_DOC',
  VISUALIZAR_PROP = 'VISUALIZAR_PROP',
  PROP_REP = 'PROP_REP',
  VALIDA_ALC_EST = 'VALIDA_ALC_EST',
  VALIDA_TP_FORMALIZ = 'VALIDA_TP_FORMALIZ',
  BPO_EMITIR_CARTAO = 'BPO_EMITIR_CARTAO',
  GERA_NR_OPER = 'GERA_NR_OPER',
  INTEGRA_BACKOFFICE = 'INTEGRA_BACKOFFICE',
  PROP_DADOS_LIB = 'PROP_DADOS_LIB',
  LECCA_INC_PROP = 'LECCA_INC_PROP',
  CERQ_INC_CCB = 'CERQ_INC_CCB',
  ANALISE_RMC = 'ANALISE_RMC',
  AVERBACAO_RMC = 'AVERBACAO_RMC',
  EXCLUSAO_RMC = 'EXCLUSAO_RMC',
  BPO_INC_FORM_DIG = 'BPO_INC_FORM_DIG',
  BPO_ENTR_CART = 'BPO_ENTR_CART',
  BPO_NOTIF_DIG = 'BPO_NOTIF_DIG',
}