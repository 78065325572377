import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { OperadorDTO } from '../../../shared/dto/operadorDTO';
import { ButtonInativar, ButtonAtivar, ButtonAlterar } from '../../../shared/buttons';
import OperadorService from '../../../services/operadorService';

function OperadorComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [operador, setOperador] = useState<OperadorDTO[]>([]);

  const operadorService: OperadorService = new OperadorService();

  const listarTodosOperadores = async () => {
    try {
      setLoading(true);
      const responseList = await operadorService.listarTodosOperadores();
      const jsonListOperador = responseList.data;

      setOperador(jsonListOperador);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarOperador = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }

    if (confirmacao) {
      try {
        await operadorService.ativarInativarOperador(id);
        listarTodosOperadores();
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    listarTodosOperadores();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Operador</h5>
          <Button
            onClick={() => navigate('/origens-operador/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Novo Operador
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Operador</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Supervisor</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {operador.map((item) => (
                    <tr>
                      <td>{item.id} - {item.nome}</td>
                      <td>{item.cpf}</td>
                      <td>{item.cidade}</td>
                      <td>{item.telefoneComercial}</td>
                      <td>{item.email}</td>
                      <td>{item.gerente} - {item.nomeGerente}</td>
                      <td>
                        <Link to={`/origens-operador/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarOperador(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarOperador(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OperadorComponent;