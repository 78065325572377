import createAxiosInstance from './axiosConfig';
import { Produto } from '../shared/model/produto';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/produtoController');

export default class ProdutoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosProdutos() {
    return await this.axios.get(`/listarTodosProdutos`);
  };

  async listarProdutoPorId(id: string) {
    return await this.axios.get(`/listarProdutoPorId?id=${id}`);
  };

  async incluirAlterarProduto(dto: Produto) {
    return await this.axios.post(`/incluirAlterarProduto`, dto);
  };

}