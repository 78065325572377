import createAxiosInstance from './axiosConfig';
import { PontoVendaDTO } from '../shared/dto/pontoVendaDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/pontoVendaController');

export default class PontoVendaService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosPontosVendas() {
    return await this.axios.get(`/listarTodosPontosVendas`);
  };

  async listarTodosPontosVendasAtivos() {
    return await this.axios.get(`/listarTodosPontosVendasAtivos`);
  };

  async ativarInativarPontoVenda(id: string) {
    return await this.axios.get(`ativarInativarPontoVenda?id=${id}`);
  };

  async listarPontoVendaPorId(id: string) {
    return await this.axios.get(`listarPontoVendaPorId?id=${id}`);
  };

  async incluirAlterarPontoVenda(pontoVendaDTO: PontoVendaDTO) {
    return await this.axios.post(`/incluirAlterarPontoVenda`, pontoVendaDTO);
  };

  async listarPontoVendaPorPromotora(idPromotora: string) {
    return await this.axios.get(`listarPontoVendaPorPromotora?idPromotora=${idPromotora}`);
  };

}