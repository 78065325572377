import createAxiosInstance from './axiosConfig';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/historicoAverbacaoController');
export default class HistoricoAverbacaoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarHistoricoPorIdProposta(idProposta: string) {
    return await this.axios.get(`/listarHistoricoPorIdProposta?idProposta=${idProposta}`);
  };

}