import { Cliente } from "../model/Cliente";
import { PropostaEsteira } from "../model/propostaEsteira";
import { ResultadoSimulacaoPropostaDTO } from "./resultadoSimulacaoPropostaDTO";

export class PropostaDTO {
  public id!: number;
  
  public promotora!: string;
  public gerente!: string;
  public operador!: string;
  public pontoVenda!: string;

  public produto!: string;
  public tipoProposta!: string;
  public empregador!: string;
  public orgao!: string;
  public cliente!: Cliente;
  public idCliente!: number;
  public cpfCliente!: string;
  public numeroBeneficiarioCliente!: string;
  public nomeCliente!: string;
  public dtNascimentoCliente!: Date;
  public tipoConta!: string;
  public banco!: string;
  public agencia!: string;
  public conta!: string;
  public contaDv!: string;
  public valorRMC!: string;
  public valorLimite!: string;
  public limiteCompra!: string;
  public limiteSaque!: string;
  public tarifaEmissao!: string;
  public valorDespesas!: string;
  public valorTC!: string;
  public anuidade!: string;

  public taxaClAm!: string;
  public taxaClAa!: string;
  public taxaCetAm!: string;
  public taxaCetAa!: string;
  public valorSolicitado!: string;
  public valorIof!: string;
  public valorLiquido!: string;
  public valorParcela!: string;
  public valorBruto!: string;
  public valorPrincipal!: string;
  public usuario!: string;
  
  public simulacaoProposta!: ResultadoSimulacaoPropostaDTO;
  public prazo!: number;
  public dtPrimeiroVencimento!: Date;
  public dtUltimoVencimento!: Date;
  public dtAlteracao!: Date;
  public dataAlteracao!: string;
  public codigoTabela!: string;
	public tabela!: string;

  public financiamento!: string;

  public statusProposta!: string;
  public atividadeAtual!: string;
  public dtMovimentacao!: Date;
  public processoEsteira!: string;
  public pmt!: string;
  public numeroContrato!: string;

  public idFormalizacao!: string;
  public numeroContratoExterno!: string;
  public adeAverbacao!: string;
  public idEsteira!: string;

  public selected!: boolean;
  public dataCadastro!: Date;
  public isFinalizada!: boolean;
  public atividadesAnteriores!: Array<PropostaEsteira>

}