import createAxiosInstance from './axiosConfig';
import { AcaoPropostaEnum } from '../shared/enum/acaoPropostaEnum';
import { ClienteDTO } from '../shared/dto/clienteDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/clienteController');

export default class ClienteService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarAprovarAtualizacaoPorCpf(cpf: string | null) {
    return await this.axios.get(`/listarAprovarAtualizacaoPorCpf?cpf=${cpf}`);
  };

  async aprovarReprovarAtualizacaoPorId(id: string, aprovar: boolean, idCliente: string) {
    return await this.axios.get(`/aprovarReprovarAtualizacaoPorId?id=${id}&aprovar=${aprovar}&idCliente=${idCliente}`);
  };

  async listarClientePorId(idCliente: string) {
    return await this.axios.get(`/listarClientePorId?id=${idCliente}`);
  };

  async listarConsultaClientePorCpf(cpf: string, tipoTela: string | undefined) {
    return await this.axios.get(`/listarConsultaClientePorCpf?cpf=${cpf}&limite=${tipoTela === 'LIMITE' ? 'true' : 'false'}`);
  };

  async listarClienteHistoricoPorCpf(cpf: string) {
    return await this.axios.get(`/listarClienteHistoricoPorCpf?cpf=${cpf}`);
  };

  async incluirAlterarCliente(idProposta: string, acaoPropostaEnum: AcaoPropostaEnum, atualizacaoCadastro: boolean, clienteDTO: ClienteDTO) {
    return await this.axios.post(`/incluirAlterarCliente?idProposta=${idProposta}&acaoPropostaEnum=${acaoPropostaEnum!.toLocaleUpperCase()}
      &atualizacao=${atualizacaoCadastro}`, clienteDTO);
  };

}