export class Usuario {
  public id!: number;
  public ativo!: boolean;
  public dtInclusao!: Date;
  public login!: string;
  public senha!: string;
  public nome!: string;
  public cpf!: string;
  public dtNascimento!: Date;
  public tipoUsuario!: string;
  public email!: string;
  public idUsuarioCadastro!: string;
  public dtAlteracao!: Date;
  public dtTermo!: Date;
  public perfil!: string;
  public tipoAcesso!: string;
}
