import { EnderecoDTO } from "./enderecoDTO";

export class OperadorDTO {
  public gerente!: string;
  public nomeGerente!: string;
  public id!: string | null;
  public ativo!: boolean;
  public nome!: string;
  public cpf!: string;
  public email!: string;
  public cidade!: string;
  public numeroCertificacao!: string;
  public codigoBanco!: string;
  public enderecoDTO!: EnderecoDTO | undefined;
  public telefoneComercial!: string;
  public telefoneCelular!: string;
}