import { EnderecoDTO } from "./enderecoDTO";

export class MatrizDTO {
  public id!: string | null;
  public nome!: string;
  public cnpj!: string;
  public email!: string;
  public enderecoDTO!: EnderecoDTO | undefined;
  public telefoneComercial!: string;
  public telefoneCelular!: string;
}