import createAxiosInstance from './axiosConfig';
import { ParcelaCessaoBaixadaDTO } from '../shared/dto/parcelaCessaoBaixadaDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/cessaoParcelaController');

export default class CessaoParcelaService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarParcelasBaixadas() {
    return await this.axios.get(`/listarParcelasBaixadas`);
  };

  async solicitarLiquidacaoParcelas(dto: ParcelaCessaoBaixadaDTO[]) {
    return await this.axios.post(`/solicitarLiquidacaoParcelas`, dto, {
      responseType: 'blob',
    });
  };

}