import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import Spinner from '../../shared/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { ConsultaLimiteClienteDTO } from '../../shared/dto/consultaLimiteClienteDTO';
import { useNavigate, useParams } from 'react-router-dom';
import ClienteService from '../../services/clienteService';

function ClienteConsultaComponent() {
  const navigate = useNavigate();
  const { tipoTela } = useParams();
  
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cliente, setCliente] = useState<ConsultaLimiteClienteDTO[]>([]);

  const [exibeDetalhe, setExibeDetalhe] = useState(false);

  const clienteService: ClienteService = new ClienteService();

  const listarClientePorCpf = async () => {
    if (cpf === '') return alert('Preencha o campo de busca!');
    try {
      setLoading(true);

      const responseList = await clienteService.listarConsultaClientePorCpf(cpf, tipoTela);
      const json = responseList.data;
      if (tipoTela === 'ATUALIZACAO'){
        navigate('/esteira-cliente/alteracao', { state: { idCliente: json[0].id, edicao: 'edicao', idProposta: 0, atualizacaoCadastro: 'true' } });
      }else {
        setCliente(json);
        setExibeDetalhe(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  {tipoTela === 'LIMITE' ? <h5>Cliente &gt; Consulta de Limite</h5> : <h5>Cliente &gt; Atualização Cadastral</h5>}
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Digite o CPF do Cliente <span style={{ color: 'red' }}>*</span></label>
                      <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={(e) => { setCpf(e.target.value); setExibeDetalhe(false); setCliente([]); }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarClientePorCpf}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>
                {exibeDetalhe && (
                  <div className="card-body">
                    <div className="table-responsive" style={{ width: '100%', borderWidth: '1px' }}>
                      <table className="table table-bordered table-striped" cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                        <thead>
                          <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                            <th style={{ textAlign: 'center' }} scope="col">CPF</th>
                            <th style={{ textAlign: 'center' }} scope="col">Matricula</th>
                            <th style={{ textAlign: 'center' }} scope="col">Cliente</th>
                            <th style={{ textAlign: 'center' }} scope="col">Empregador</th>
                            <th style={{ textAlign: 'center' }} scope="col">Órgão</th>
                            <th style={{ textAlign: 'center' }} scope="col">RMC</th>
                            <th style={{ textAlign: 'center' }} scope="col">Limite Saque</th>
                            <th style={{ textAlign: 'center' }} scope="col">Limite Compra</th>
                            <th style={{ textAlign: 'center' }} scope="col">Limite Saque Disponível</th>
                            {tipoTela === 'ATUALIZACAO' ? <th scope="col"> </th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {cliente.map((item) => (
                            <tr className="GridRowStyle">
                              <td style={{ textAlign: 'center' }}>{item.cpf}</td>
                              <td style={{ textAlign: 'center' }}>{item.matricula}</td>
                              <td style={{ textAlign: 'center' }}>{item.cliente}</td>
                              <td style={{ textAlign: 'center' }}>{item.empregador}</td>
                              <td style={{ textAlign: 'center' }}>{item.orgao}</td>
                              <td style={{ textAlign: 'center' }}>{item.rmc ? 'R$ ' + item.rmc : ''}</td>
                              <td style={{ textAlign: 'center' }}>{item.limiteSaque ? 'R$ ' + item.limiteSaque : ''}</td>
                              <td style={{ textAlign: 'center' }}>{item.limiteCompra ? 'R$ ' + item.limiteCompra : ''}</td>
                              <td style={{ textAlign: 'center' }}>{item.limiteSaqueDisponivel ? 'R$ ' + item.limiteSaqueDisponivel : ''}</td>
                              {tipoTela === 'ATUALIZACAO' ? 
                              <td style={{ textAlign: 'center' }}> <button>
                                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '5px' }} 
                                onClick={() => navigate('/esteira-cliente/alteracao', { state: { idCliente: item.id, edicao: 'edicao', idProposta: 0, atualizacaoCadastro: 'true' } })}/></button>
                               </td> : null}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

}

export default ClienteConsultaComponent;