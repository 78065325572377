import createAxiosInstance from './axiosConfig';
import { Motivo } from '../shared/model/motivo';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/motivoController');

export default class MotivoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosMotivos() {
    return await this.axios.get(`/listarTodosMotivos`);
  };

  async ativarInativarMotivo(id: string) {
    return await this.axios.get(`/ativarInativarMotivo?id=${id}`);
  };

  async incluirAlterarMotivo(dto: Motivo) {
    return await this.axios.post(`/incluirAlterarMotivo`, dto);
  };

  async listarTodosMotivosPorPendenciaOuRecusa(tipoPendenciaRecusa: string) {
    return await this.axios.get(`/listarTodosMotivosPorPendenciaOuRecusa?pendenciaRecusa=${tipoPendenciaRecusa}`);
  };

}