import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import Spinner from '../../shared/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { ClienteAtualizacaoDTO } from '../../shared/dto/clienteAtualizacaoDTO';
import { format } from 'date-fns';
import { Cliente } from '../../shared/model/Cliente';
import { ClienteEndereco } from '../../shared/model/clienteEndereco';
import ScrollToTopButton from '../../shared/scrollToTopButton';
import ClienteService from '../../services/clienteService';

function ClienteConsultaHistoricoComponent() {
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cliente, setCliente] = useState<ClienteAtualizacaoDTO[]>([]);

  const [exibeDetalhe, setExibeDetalhe] = useState(false);

  const [exibeVisualizar, setExibeVisualizar] = useState(false);
  const [c, setC] = useState<Cliente>();
  const [enderecoResidencia, setEnderecoResidencia] = useState<ClienteEndereco>();
  const [enderecoComercial, setEnderecoComercial] = useState<ClienteEndereco>();
  const [enderecoCobranca, setEnderecoCobranca] = useState<ClienteEndereco>();

  const clienteService: ClienteService = new ClienteService();

  const listarClientePorCpf = async () => {
    if (cpf === '') return alert('Preencha o campo de busca!');
    try {
      setLoading(true);

      const responseList = await clienteService.listarClienteHistoricoPorCpf(cpf);
      const json = responseList.data;
      setCliente(json);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const listarCliente = async (idCliente: string) => {
    try {
      setLoading(true);
      await clienteService.listarClientePorId(idCliente)
        .then((responseList) => {
          const json = responseList.data.cliente;
          const jsonEnderecos = responseList.data.enderecos;
          setC(json);
          setExibeVisualizar(true);
          scrollToBottom();

          if (jsonEnderecos !== undefined) {
            jsonEnderecos.forEach((end: ClienteEndereco) => {
              if (end.tipoEndereco === 'Comercial') {
                let enderecoCom: ClienteEndereco = new ClienteEndereco();
                enderecoCom.tipoEndereco = 'Comercial';
                enderecoCom.cep = end.cep;
                enderecoCom.logradouro = end.logradouro;
                enderecoCom.numero = end.numero;
                enderecoCom.complemento = end.complemento;
                enderecoCom.bairro = end.bairro;
                enderecoCom.localidade = end.localidade;
                enderecoCom.uf = end.uf;
                setEnderecoComercial(enderecoCom);
              } else if (end.tipoEndereco === 'Residencial') {
                let enderecoRes: ClienteEndereco = new ClienteEndereco();
                enderecoRes.tipoEndereco = 'Residencial';
                enderecoRes.cep = end.cep;
                enderecoRes.logradouro = end.logradouro;
                enderecoRes.numero = end.numero;
                enderecoRes.complemento = end.complemento;
                enderecoRes.bairro = end.bairro;
                enderecoRes.localidade = end.localidade;
                enderecoRes.uf = end.uf;
                setEnderecoResidencia(enderecoRes);
              } else if (end.tipoEndereco === 'Cobrança') {
                let enderecoCob: ClienteEndereco = new ClienteEndereco();
                enderecoCob.tipoEndereco = 'Cobrança';
                enderecoCob.cep = end.cep;
                enderecoCob.logradouro = end.logradouro;
                enderecoCob.numero = end.numero;
                enderecoCob.complemento = end.complemento;
                enderecoCob.bairro = end.bairro;
                enderecoCob.localidade = end.localidade;
                enderecoCob.uf = end.uf;
                setEnderecoCobranca(enderecoCob);
              }
            })
          }
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  };

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <h5>Cliente &gt; Consulta Histórico de Atualização Cadastral</h5>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Digite o CPF do Cliente <span style={{ color: 'red' }}>*</span></label>
                      <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={(e) => { setCpf(e.target.value); setExibeDetalhe(false); setCliente([]); }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarClientePorCpf}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>
                {exibeDetalhe && (
                  <div className="card-body">
                    <div className="table-responsive" style={{ width: '100%', borderWidth: '1px' }}>
                      <table className="table table-bordered table-striped" cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                        <thead>
                          <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                            <th style={{ textAlign: 'center' }} scope="col">Código</th>
                            <th style={{ textAlign: 'center' }} scope="col">Status</th>
                            <th style={{ textAlign: 'center' }} scope="col">CPF</th>
                            <th style={{ textAlign: 'center' }} scope="col">Nome</th>
                            <th style={{ textAlign: 'center' }} scope="col">Data Inclusão</th>
                            <th style={{ textAlign: 'center' }} scope="col">Usuário Inclusão</th>
                            <th style={{ textAlign: 'center' }} scope="col">Data Alteração</th>
                            <th style={{ textAlign: 'center' }} scope="col">Usuário Alteração</th>
                            <th style={{ textAlign: 'center' }} scope="col">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cliente.map((item) => (
                            <tr className="GridRowStyle">
                              <td style={{ textAlign: 'center' }}>{item.idCliente}</td>
                              <td style={{ textAlign: 'center', backgroundColor: item.aprovado === true && item.dtAprovacao? 'green' : item.aprovado === true && !item.dtAprovacao ? 'yellow' : item.aprovado === false && item.dtAprovacao ? 'red' : item.ativo === true ? 'green' : 'white', color: item.aprovado === true && item.dtAprovacao ? 'black' : 'black', }}>{item.aprovado === true && item.dtAprovacao ? 'APR' : item.aprovado === true && !item.dtAprovacao ? 'PEN' : item.aprovado=== false && item.dtAprovacao ? 'REP' : item.ativo === true ? 'APR' : ''}</td>
                              <td style={{ textAlign: 'center' }}>{item.cpf}</td>
                              <td style={{ textAlign: 'center' }}>{item.nome}</td>
                              <td style={{ textAlign: 'center' }}>{item.dtInclusao}</td>
                              <td style={{ textAlign: 'center' }}>{item.usuarioInclusao}</td>
                              <td style={{ textAlign: 'center' }}>{item.dtAprovacao}</td>
                              <td style={{ textAlign: 'center' }}>{item.usuarioAprovacao}</td>
                              <td style={{ textAlign: 'center' }}>
                                <button
                                  style={{ backgroundColor: 'black', color: 'white', border: '2px solid darkblack', borderRadius: '5px', width: '40px', height: '30px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginLeft: '5px', marginRight: '5px' }}
                                  title="Visualizar" onClick={() => { listarCliente(item.idCliente); }}>
                                  <FontAwesomeIcon icon={faSearch} />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {exibeVisualizar && (
              <form>
                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Dados Básicos</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2 form-group">
                        <label>Código</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.id}</text>
                      </div>
                      <div className="col-md-3">
                        <label>CPF</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.cpf}</text>
                      </div>

                      <div className="col-md-4">
                        <label>Nome </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.nome}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Data Nascimento</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}> {c?.dtNascimento! ? format(new Date(c?.dtNascimento!), 'dd/MM/yyyy') : ''}</text>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-md-3 ">
                        <label>RG </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.numeroRg}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Data Emissão do RG </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.dtEmissaoRg! ? format(new Date(c?.dtEmissaoRg!), 'dd/MM/yyyy') : ''}</text>
                      </div>

                      <div className="col-md-3">
                        <label>Órgão Emissor </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.orgaoRg}</text>
                      </div>
                      <div className="col-md-3">
                        <label>UF Emissão </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.ufRg}</text>
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-md-1">
                        <label>Naturalidade UF </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.ufNaturalidade}</text>
                      </div>
                      <div className="col-md-2">
                        <label>Sexo </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.sexo}</text>
                      </div>

                      <div className="col-md-3">
                        <label>Estado Civil </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.estadoCivil}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Tipo de Residência</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.tipoResidencia}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Tempo de Residência</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.tempoResidencia}</text>
                      </div>
                    </div>

                    <hr className="my-4" />

                    <div className="row">
                      <div className="col-md-2">
                        <label>CEP </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.cep}</text>
                      </div>
                      <div className="col-md">
                        <label>Endereço </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.logradouro}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Número </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.numero}</text>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <label>Bairro </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.bairro}</text>
                      </div>
                      <div className="col-md">
                        <label>Complemento</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.complemento}</text>
                      </div>
                      <div className="col-md">
                        <label>Cidade </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.localidade}</text>
                      </div>
                      <div className="col-md">
                        <label>Estado </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoResidencia?.uf}</text>
                      </div>
                    </div>

                    <hr className="my-4" />
                    <h5>Filiação</h5>

                    <div className="row">
                      <div className="col-md-5">
                        <label className="form-label">Nome Mãe </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.nomeMae}</text>
                      </div>
                      <div className="col-md-5">
                        <label className="form-label">Nome Pai</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.nomePai}</text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Comercial</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <label>Natureza Ocupação</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.naturezaOcupacao}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Empresa</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.empresa}</text>
                      </div>
                      <div className="col-md-3">
                        <label>CNPJ (se sócio proprietário)</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.cnpjEmpresa}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Data Admissão</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.dtAdmissao! ? format(new Date(c?.dtAdmissao!), 'dd/MM/yyyy') : ''}</text>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <label>CEP</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.cep}</text>
                      </div>
                      <div className="col-md">
                        <label>Endereço</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.logradouro}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Número</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.numero}</text>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md">
                        <label>Bairro</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.bairro}</text>
                      </div>
                      <div className="col-md">
                        <label>Complemento</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.complemento}</text>
                      </div>
                      <div className="col-md">
                        <label>Cidade</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.localidade}</text>
                      </div>
                      <div className="col-md">
                        <label>Estado</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoComercial?.uf}</text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Rendas</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <label>Renda</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.renda}</text>
                      </div>
                      <div className="col-md-2">
                        <label>Outras Rendas</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.outrasRendas}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Nº Benefício/Matrícula  </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.numeroBeneficiario}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Código Autorização</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.codigoAutorizacao}</text>
                      </div>
                      <div className="col-md-2">
                        <label>Mês/Ano Contra-Cheque</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.mesAnoContraCheque}</text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Referências Pessoais</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Ref Pessoal 1</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.refNome1}</text>
                      </div>
                      <div className="col-md">
                        <label>Telefone Celular</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.refTelefone1}</text>
                      </div>
                      <div className="col-md-4">
                        <label>Ref Pessoal 2</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.refNome2}</text>
                      </div>
                      <div className="col-md">
                        <label>Telefone Celular</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.refTelefone2}</text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Dados Bancários</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <label>Tipo Conta </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.tipoConta}</text>
                      </div>
                      <div className="col-md-2">
                        <label>Banco </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.banco}</text>
                      </div>
                      <div className="col-md-2">
                        <label>Agência </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.agencia}</text>
                      </div>
                      <div className="col-md-2">
                        <label>Conta </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.conta}</text>
                      </div>
                      <div className="col-md-2">
                        <label>DV </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.contaDv}</text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Cônjuge</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <label>Nome Cônjuge</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.nomeConjuge}</text>
                      </div>
                      <div className="col-md-3">
                        <label>CPF Cônjuge</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.cpfConjuge}</text>
                      </div>
                      <div className="col-md-3">
                        <label>RG Cônjuge</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.rgConjuge}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Data de Nascimento</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.dtNascimentoConjuge! ? format(new Date(c?.dtNascimentoConjuge!), 'dd/MM/yyyy') : ''}</text>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label>Renda Cônjuge</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.rendaConjuge}</text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Endereço Cobrança</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <label>CEP</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.cep}</text>
                      </div>
                      <div className="col-md">
                        <label>Endereço</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.logradouro}</text>
                      </div>
                      <div className="col-md-3">
                        <label>Número</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.numero}</text>
                      </div>
                    </div><div className="row">
                      <div className="col-md">
                        <label>Bairro</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.bairro}</text>
                      </div>
                      <div className="col-md">
                        <label>Complemento</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.complemento}</text>
                      </div>
                      <div className="col-md">
                        <label>Cidade</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.localidade}</text>
                      </div>
                      <div className="col-md">
                        <label>Estado</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{enderecoCobranca?.uf}</text>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-header">
                    <strong>Contato</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md">
                        <label>Telefone Residencial</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.telefoneResidencial}</text>
                      </div>
                      <div className="col-md">
                        <label>Telefone Celular </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.telefoneCelular}</text>
                      </div>
                      <div className="col-md">
                        <label>Telefone Comercial</label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.telefoneComercial}</text>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl">
                        <label>Lista de E-mails (separados por ';') </label>
                        <text className="form-control" display={'none'} style={{ minHeight: "38px", color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{c?.email}</text>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </>
        )}
        <ScrollToTopButton />
      </div>
    </>
  );

}

export default ClienteConsultaHistoricoComponent;