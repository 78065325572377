import createAxiosInstance from './axiosConfig';
import { OperadorDTO } from '../shared/dto/operadorDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/operadorController');

export default class OperadorService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosOperadores() {
    return await this.axios.get(`/listarTodosOperadores`);
  };

  async listarTodosOperadoresAtivos() {
    return await this.axios.get(`/listarTodosOperadoresAtivos`);
  };

  async ativarInativarOperador(id: string) {
    return await this.axios.get(`ativarInativarOperador?id=${id}`);
  };

  async listarOperadorPorId(id: string) {
    return await this.axios.get(`listarOperadorPorId?id=${id}`);
  };

  async incluirAlterarOperador(dto: OperadorDTO) {
    return await this.axios.post(`/incluirAlterarOperador`, dto);
  };

}