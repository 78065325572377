import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { PontoVendaDTO } from '../../../shared/dto/pontoVendaDTO';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import PontoVendaService from '../../../services/pontoVendaService';

function PontoVendaComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pontoVenda, setPontoVenda] = useState<PontoVendaDTO[]>([]);

  const pontoVendaService: PontoVendaService = new PontoVendaService();

  const listarTodosPontosVendas = async () => {
    try {
      setLoading(true);
      const responseList = await pontoVendaService.listarTodosPontosVendas();
      const jsonListPontoVenda = responseList.data;

      setPontoVenda(jsonListPontoVenda);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarPontoVenda = async (id: any, ativo: boolean) => {
    let confirmacao = null;
    if (ativo) {
      confirmacao = window.confirm('Tem certeza que deseja inativar este item?');
    } else {
      confirmacao = window.confirm('Tem certeza que deseja ativar este item?');
    }

    if (confirmacao) {
      try {
        await pontoVendaService.ativarInativarPontoVenda(id);
        listarTodosPontosVendas();
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    listarTodosPontosVendas();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Ponto de Venda</h5>
          <Button
            onClick={() => navigate('/origens-ponto-venda/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Novo Ponto de Venda
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Ponto de Venda</th>
                    <th scope="col">CPF/CNPJ</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Operador</th>
                    <th scope="col">Alteração</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {pontoVenda.map((item) => (
                    <tr>
                      <td>{item.id} - {item.nome}</td>
                      <td>{item.cpfCnpj}</td>
                      <td>{item.cidade}</td>
                      <td>{item.telefoneComercial}</td>
                      <td>{item.email}</td>
                      <td>{item.operador} - {item.nomeOperador}</td>
                      <td>
                        <Link to={`/origens-ponto-venda/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarPontoVenda(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarPontoVenda(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PontoVendaComponent;