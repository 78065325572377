import { FinanciamentoProduto } from "./financiamentoProduto";
import { FinanciamentoTaxa } from "./financiamentoTaxa";

export class Financiamento {
  public id!: number | string | null;
  public ativo!: boolean;
  public pendente!: boolean;
  public codigo!: string;
  public nome!: string;
  public dtVigenciaInicio!: Date;
  public dtVigenciaFim!: Date;
  public financiamentoTaxa!: FinanciamentoTaxa[];
  public financiamentoProduto!: FinanciamentoProduto[];
  
  public diaCorte!: string;
  public diaVencimento!: string;
  public nomeProduto!: string;
  public idProduto!: string;
}