import { EnderecoDTO } from "./enderecoDTO";

export class PontoVendaDTO {
  public operador!: string;
  public nomeOperador!: string;
  public id!: string | null;
  public ativo!: boolean;
  public nome!: string;
  public cpfCnpj!: string;
  public email!: string;
  public porte!: string;
  public situacaoBanco!: string;
  public observacao!: string;
  public pvBalcao!: boolean;
  public bloqueiaCpf!: boolean;
  public cidade!: string;
  public enderecoDTO!: EnderecoDTO | undefined;
  public telefoneComercial!: string;
  public telefoneCelular!: string;
}