export const RotasProcessoEsteira = [
  { value: "", label: "APR_PROP_AUT"}, //BPO - Aprova Proposta
//{ value: "", label: "AGUARDA_APR_EXT"}, //BPO - Aguarda Aprovação Externa
  { value: "", label: "ANEXAR_DOCS"}, //BPO - Anexar Documentos
  { value: `{"path": "/esteira-cliente/alteracao", "state": { "idCliente": "{idCliente}", "edicao": "proposta", "idProposta": "{idProposta}", "atualizacaoCadastro": "false" }}`, label: "DADOS_CADASTRAIS"}, //BPO - Dados Cadastrais Cliente
  { value: "", label: "IMP_DOC"}, //BPO - Impressão de Documentos
  { value: `{"path": "/proposta-esteira-component", "state": { "idProposta": "{idProposta}", "esteira": "S" }}`, label: "VISUALIZAR_PROP"}, //BPO - Visualizar Proposta
  { value: "", label: "PROP_REP"}, //BPO - Reprovar Proposta
  { value: "", label: "VALIDA_ALC_EST"}, //BPO - Validar Alçada Esteira
  { value: "", label: "VALIDA_TP_FORMALIZ"}, //BPO - Valida Tipo Formalização
  { value: "", label: "BPO_EMITIR_CARTAO"}, //BPO - Emitir Cartão
  { value: "BACKEND", label: "INTEGRA_BACKOFFICE"}, //BPO - Integração BackOffice
  { value: "", label: "PROP_DADOS_LIB"}, //BPO - Dados Liberação Proposta
  { value: "BACKEND", label: "LECCA_INC_PROP"}, //Lecca - Inclusão de Proposta
  { value: "", label: "CERQ_INC_CCB"}, //CERQ - Registro CCB
  { value: `{"path": "/proposta-esteira-component", "state": { "idProposta": "{idProposta}", "esteira": "S" }}`, label: "ANALISE_RMC"}, //BPO - Análise RMC
  { value: `{"path": "/analise-rmc", "state": { "idProposta": "{idProposta}" }}`, label: "AVERBACAO_RMC"}, //BPO - Averbação RMC
  { value: "", label: "EXCLUSAO_RMC"}, //BPO - Exclusão RMC
  { value: "BACKEND", label: "BPO_INC_FORM_DIG"}, //BPO - Inclusão Formalização Digital
  { value: "", label: "BPO_ENTR_CART"}, //BPO - Notifica Entrega Cartão
  { value: "", label: "BPO_NOTIF_DIG"}, //BPO - Notifica Digital
];