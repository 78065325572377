export class Parametros {
  public nomeBancoBpo!: string;
  public urlBpo!: string;
  public usuarioBpo!: string;
  public senhaBpo!: string;
  public urlDigitalBpo!: string;
  public urlLecca!: string;
  public tokenLecca!: string;
  public urlVortx!: string;
  public tokenVortx!: string;
  public urlCessao!: string;
  public tokenCessao!: string;
}