import createAxiosInstance from './axiosConfig';
import { Cessao } from '../shared/model/cessao';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/cessaoController');

export default class CessaoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarCessaoPorId(numeroCessao: string) {
    return await this.axios.get(`/listarCessaoPorId?id=${numeroCessao}`);
  };

  async listarTodasCessoes() {
    return await this.axios.get(`/listarTodasCessoes`);
  };

  async incluirAlterarCessao(dto: Cessao) {
    return await this.axios.post(`/incluirAlterarCessao`, dto);
  };

}