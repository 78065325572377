import { faPencil, faClose, faCheck, faE, faSitemap, faP } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps} from "react-bootstrap";

interface ButtonBProps extends ButtonProps {
  onClick: () => void;
}
export const ButtonAlterar = () => {
  return (
    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}>
      <FontAwesomeIcon icon={faPencil} />
    </Button>
  );
};

export const ButtonInativar: React.FC<ButtonBProps> = ({ onClick, ...props }) => {
  return (
    <Button 
      style={{ backgroundColor: '#dc3545', border: '1px solid #dc3545', color: 'white', cursor: 'pointer', textAlign: 'center' }}
      onClick={onClick}
      {...props}
    >
      <FontAwesomeIcon icon={faClose} />
    </Button>
  );
};

export const ButtonAtivar: React.FC<ButtonBProps> = ({ onClick, ...props }) => {
  return (
    <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white',
    cursor: 'pointer', textAlign: 'center', }}
    onClick={onClick}
    {...props}
    >
      <FontAwesomeIcon icon={faCheck} />
    </Button>
  );
};

export const ButtonEmpregador = () => {
  return (
    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}>
      <FontAwesomeIcon icon={faE} />
    </Button>
  );
};

export const ButtonOrgao = () => {
  return (
    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}>
      <FontAwesomeIcon icon={faSitemap} />
    </Button>
  );
};

export const ButtonAlterarOrgao:React.FC<ButtonBProps> = ({ onClick, ...props }) => {
  return (
    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}
      onClick={onClick}
      {...props}
    >
      <FontAwesomeIcon icon={faPencil} />
    </Button>
  );
};

export const ButtonPromotora = () => {
  return (
    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}>
      <FontAwesomeIcon icon={faP} />
    </Button>
  );
};

export const ButtonAlterarMotivo:React.FC<ButtonBProps> = ({ onClick, ...props }) => {
  return (
    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}
      onClick={onClick}
      {...props}
    >
      <FontAwesomeIcon icon={faPencil} />
    </Button>
  );
};