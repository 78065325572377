import createAxiosInstance from './axiosConfig';
import { Fidc } from '../shared/model/fidc';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/fidcController');

export default class FidcService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosFidcs() {
    return await this.axios.get(`/listarTodosFidcs`);
  };

  async listarFidcPorId(id: string) {
    return await this.axios.get(`/listarFidcPorId?id=${id}`);
  };

  async incluirAlterarFidc(dto: Fidc) {
    return await this.axios.post(`/incluirAlterarFidc`, dto);
  };
}