import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import { format, parse } from 'date-fns';
import ScrollToTopButton from '../../../shared/scrollToTopButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { Empregador } from '../../../shared/model/empregador';
import { Promotora } from '../../../shared/model/promotora';
import { EmpregadorPromotoraCessaoDTO } from '../../../shared/dto/empregadorPromotoraCessaoDTO';
import { EmpregadorPromotoraDTO } from '../../../shared/dto/empregadorPromotoraDTO';
import { OperacaoDTO } from '../../../shared/dto/operacaoDTO';
import PromotoraService from '../../../services/promotoraService';
import OperacaoService from '../../../services/operacaoService';
import CessaoService from '../../../services/cessaoService';
import EmpregadorService from '../../../services/empregadorService';

function RecompraManualComponent() {
  const [loading, setLoading] = useState(false);

  const [numeroCessao, setNumeroCessao] = useState('');
  const [nomeCessao, setNomeCessao] = useState('');
  const [dtBase1, setDtBase1] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtBase2, setDtBase2] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [availablePromotoras, setAvailablePromotoras] = useState<Promotora[]>([]);
  const [selectedPromotoras, setSelectedPromotoras] = useState<Promotora[]>([]);
  const [selectedToMovePromotoras, setSelectedToMovePromotoras] = useState<Promotora[]>([]);
  const [selectedFromSelectedPromotoras, setSelectedFromSelectedPromotoras] = useState<Promotora[]>([]);

  const [availableEmpregadores, setAvailableEmpregadores] = useState<Empregador[]>([]);
  const [selectedEmpregadores, setSelectedEmpregadores] = useState<Empregador[]>([]);
  const [selectedToMoveEmpregadores, setSelectedToMoveEmpregadores] = useState<Empregador[]>([]);
  const [selectedFromSelectedEmpregadores, setSelectedFromSelectedEmpregadores] = useState<Empregador[]>([]);

  const [selecionarTudo, setSelecionarTudo] = useState(true);

  const [operacoes, setOperacoes] = useState<OperacaoDTO[]>([]);

  const [empregadorPromotoraList, setEmpregadorPromotoraList] = useState<EmpregadorPromotoraCessaoDTO[]>([]);

  const [exibeDetalhe, setExibeDetalhe] = useState(false);
  const [exibeOperacao, setExibeOperacao] = useState(false);

  const promotoraService: PromotoraService = new PromotoraService();
  const operacaoService: OperacaoService = new OperacaoService();
  const cessaoService: CessaoService = new CessaoService();
  const empregadorService: EmpregadorService = new EmpregadorService();

  const listarPromotoras = async () => {
    setLoading(true);
    try {
      const response = await promotoraService.listarTodasPromotorasAtivas();
      
      setAvailablePromotoras(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarEmpregadores = async () => {
    setLoading(true);
    try {
      const response = await empregadorService.listarTodosEmpregadoresAtivos();
      setAvailableEmpregadores(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarCessao = async () => {
    if (numeroCessao === '') return alert('Preencha o campo de busca');
    try {
      setLoading(true);

      const responseList = await cessaoService.listarCessaoPorId(numeroCessao);
      const json = responseList.data;
      setNomeCessao(json.nome);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const consultarOperacoes = async () => {
    if (selectedPromotoras.length <= 0) return alert('Selecione ao menos uma Promotora');
    if (selectedEmpregadores.length <= 0) return alert('Selecione ao menos um Empregador');
    if ((dtBase1 !== '' && dtBase2 === '') || dtBase1 === '' && dtBase2 !== '') return alert('Preencha os campos de datas ou limpe os dois!');
    if (dtBase1 !== '' && dtBase2 !== '' && parse(dtBase1, "dd/MM/yyyy", new Date()) > parse(dtBase2, "dd/MM/yyyy", new Date())) return alert('Data inicial deve ser inferior a data final');
    try {
      const dto: EmpregadorPromotoraDTO = new EmpregadorPromotoraDTO();
      dto.empregadores = selectedEmpregadores;
      dto.promotoras = selectedPromotoras;
      dto.dtBase1 = parse(dtBase1, "dd/MM/yyyy", new Date());
      dto.dtBase2 = parse(dtBase2, "dd/MM/yyyy", new Date());

      setLoading(true);
      const responseList = await operacaoService.listarOperacoesRecompraPorPromotoraEmpregador(dto);
      const json = responseList.data;
      setEmpregadorPromotoraList(json.empregadorPromotora);
      setOperacoes(json.operacoes);
      setExibeOperacao(true);
      setLoading(false);
    } catch (err) {
      setExibeOperacao(true);
      setLoading(false);
      console.error(err);
    }
  }

  const lancarRecompra = async () => {
    try {
      setLoading(true);
      const response = await operacaoService.recomprarOperacoesCessao(numeroCessao, operacoes); 
      setExibeOperacao(true);
      setLoading(false);
      alert(response.data);
      window.location.reload();
    } catch (err: any) {
      setExibeOperacao(true);
      setLoading(false);
      console.error(err);
      alert(err.response.data);
    }
  }

  useEffect(() => {
    listarPromotoras();
    listarEmpregadores();
  }, []);

  const moveToSelectedPromotoras = () => {
    const newSelected = [...selectedPromotoras, ...selectedToMovePromotoras];
    const newAvailable = availablePromotoras.filter(
      (promotora) => !selectedToMovePromotoras.includes(promotora)
    );

    setAvailablePromotoras(newAvailable);
    setSelectedPromotoras(newSelected);
    setSelectedToMovePromotoras([]);
  };

  const moveToAvailablePromotoras = () => {
    const newAvailable = [...availablePromotoras, ...selectedFromSelectedPromotoras];
    const newSelected = selectedPromotoras.filter(
      (promotora) => !selectedFromSelectedPromotoras.includes(promotora)
    );

    setAvailablePromotoras(newAvailable);
    setSelectedPromotoras(newSelected);
    setSelectedFromSelectedPromotoras([]);
  };

  const moveAllToSelectedPromotoras = () => {
    const newSelected = [...selectedPromotoras, ...availablePromotoras];
    setSelectedPromotoras(newSelected);
    setAvailablePromotoras([]);
  };

  const moveAllToAvailablePromotoras = () => {
    const newAvailable = [...availablePromotoras, ...selectedPromotoras];
    setAvailablePromotoras(newAvailable);
    setSelectedPromotoras([]);
  };

  //EMPREGADOR

  const moveToSelectedEmpregadores = () => {
    const newSelected = [...selectedEmpregadores, ...selectedToMoveEmpregadores];
    const newAvailable = availableEmpregadores.filter(
      (empregador) => !selectedToMoveEmpregadores.includes(empregador)
    );

    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores(newSelected);
    setSelectedToMoveEmpregadores([]);
  };

  const moveToAvailableEmpregadores = () => {
    const newAvailable = [...availableEmpregadores, ...selectedFromSelectedEmpregadores];
    const newSelected = selectedEmpregadores.filter(
      (empregador) => !selectedFromSelectedEmpregadores.includes(empregador)
    );

    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores(newSelected);
    setSelectedFromSelectedEmpregadores([]);
  };

  const moveAllToSelectedEmpregadores = () => {
    const newSelected = [...selectedEmpregadores, ...availableEmpregadores];
    setSelectedEmpregadores(newSelected);
    setAvailableEmpregadores([]);
  };

  const moveAllToAvailableEmpregadores = () => {
    const newAvailable = [...availableEmpregadores, ...selectedEmpregadores];
    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores([]);
  };

  //OPERACAO

  const handleAtivoChange = (id: any) => {
    if (selecionarTudo)
      setSelecionarTudo(false)

    setOperacoes((prevData) =>
      prevData.map((item) =>
        item.numeroContrato === id ? {
          ...item, ativo: !item.ativo
        } : item
      )
    );
  };

  const selecionarDeselecionarTodos = (marcar: boolean) => {
    setSelecionarTudo(!selecionarTudo);
    setOperacoes((prevData) =>
      prevData.map((item) => ({ ...item, ativo: marcar }))
    );
  }

  const totalOperacoes = empregadorPromotoraList.reduce((acc, item) => acc + item.qtdOperacoes, 0);
  const totalFinanciado = empregadorPromotoraList.reduce((acc, item) => acc + item.valorFinanciado, 0);
  const totalBruto = empregadorPromotoraList.reduce((acc, item) => acc + item.valorBruto, 0);

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <strong>Lançamento de Recompra de Carteira - Manual</strong>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Código Cessão <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
                        value={numeroCessao} onChange={(e) => { setNumeroCessao((e.target.value)); setExibeDetalhe(false); setNomeCessao(''); setExibeOperacao(false); setOperacoes([]); setEmpregadorPromotoraList([]); }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarCessao}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>

                {exibeDetalhe ? (
                  <>
                    <div className="" style={{ marginTop: '10px' }}>
                      <div className="row">
                        <div className="col-md-3" style={{ paddingRight: '10px' }}>
                          <label style={{ width: '100%' }}>Nome Cessão</label>
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          <label>Faixa de Datas Base <span style={{ color: 'red' }}>*</span></label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-md-3" style={{ paddingRight: '10px' }}>
                          <input type="text" value={nomeCessao} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                        <div className="col-md-4">
                          <thead>
                            <tr>
                              <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase1} onChange={(e) => setDtBase1((e.target.value).replace(/_/g, ""))} /></th>
                              <th style={{ width: "15px" }}> a </th>
                              <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase2} onChange={(e) => setDtBase2((e.target.value).replace(/_/g, ""))} /></th>
                            </tr>
                          </thead>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <div>
                        <h6>Promotoras Disponíveis</h6>
                        <select
                          multiple
                          value={selectedToMovePromotoras.map((p) => p.id!.toString())}
                          onChange={(e) => {
                            const options = e.target.options;
                            const selected = [];
                            for (let i = 0; i < options.length; i++) {
                              if (options[i].selected) {
                                const promotora = availablePromotoras.find(p => p.id!.toString() === options[i].value);
                                if (promotora) {
                                  selected.push(promotora);
                                }
                              }
                            }
                            setSelectedToMovePromotoras(selected);
                          }}
                          style={{ width: '575px', height: '250px' }}
                        >
                          {availablePromotoras.map((promotora) => (
                            <option key={promotora.id} value={promotora.id!.toString()}>
                              {promotora.id} - {promotora.nome!}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <button onClick={moveToSelectedPromotoras} style={{ margin: '5px' }}>{'>'}</button>
                        <button onClick={moveAllToSelectedPromotoras} style={{ margin: '5px' }}>{'>>'}</button>
                        <button onClick={moveToAvailablePromotoras} style={{ margin: '5px' }}>{'<'}</button>
                        <button onClick={moveAllToAvailablePromotoras} style={{ margin: '5px' }}>{'<<'}</button>
                      </div>
                      <div>
                        <h6>Promotoras Selecionadas</h6>
                        <select
                          multiple
                          value={selectedFromSelectedPromotoras.map((p) => p.id!.toString())}
                          onChange={(e) => {
                            const options = e.target.options;
                            const selected = [];
                            for (let i = 0; i < options.length; i++) {
                              if (options[i].selected) {
                                const promotora = selectedPromotoras.find(p => p.id!.toString() === options[i].value);
                                if (promotora) {
                                  selected.push(promotora);
                                }
                              }
                            }
                            setSelectedFromSelectedPromotoras(selected);
                          }}
                          style={{ width: '575px', height: '250px' }}
                        >
                          {selectedPromotoras.map((promotora) => (
                            <option key={promotora.id} value={promotora.id!.toString()}>
                              {promotora.id} - {promotora.nome!}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <div>
                        <h6>Empregadores Disponíveis </h6>
                        < select
                          multiple
                          value={selectedToMoveEmpregadores.map((e) => e.id!.toString())}
                          onChange={(e) => {
                            const options = e.target.options;
                            const selected = [];
                            for (let i = 0; i < options.length; i++) {
                              if (options[i].selected) {
                                const empregador = availableEmpregadores.find(e => e.id!.toString() === options[i].value);
                                if (empregador) {
                                  selected.push(empregador);
                                }
                              }
                            }
                            setSelectedToMoveEmpregadores(selected);
                          }}
                          style={{ width: '575px', height: '250px' }}
                        >
                          {
                            availableEmpregadores.map((empregador) => (
                              <option key={empregador.id} value={empregador.id!.toString()} >
                                {empregador.id} - {empregador.nome!}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                      < div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <button onClick={moveToSelectedEmpregadores} style={{ margin: '5px' }}> {'>'} </button>
                        < button onClick={moveAllToSelectedEmpregadores} style={{ margin: '5px' }}> {'>>'} </button>
                        < button onClick={moveToAvailableEmpregadores} style={{ margin: '5px' }}> {'<'} </button>
                        < button onClick={moveAllToAvailableEmpregadores} style={{ margin: '5px' }}> {'<<'} </button>
                      </div>
                      < div >
                        <h6>Empregadores Selecionados </h6>
                        < select
                          multiple
                          value={selectedFromSelectedEmpregadores.map((e) => e.id!.toString())}
                          onChange={(e) => {
                            const options = e.target.options;
                            const selected = [];
                            for (let i = 0; i < options.length; i++) {
                              if (options[i].selected) {
                                const empregador = selectedEmpregadores.find(e => e.id!.toString() === options[i].value);
                                if (empregador) {
                                  selected.push(empregador);
                                }
                              }
                            }
                            setSelectedFromSelectedEmpregadores(selected);
                          }}
                          style={{ width: '575px', height: '250px' }}
                        >
                          {
                            selectedEmpregadores.map((empregador) => (
                              <option key={empregador.id} value={empregador.id!.toString()} >
                                {empregador.id} - {empregador.nome!}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: '15px' }}>
                      <div className="col-md-3">
                        <button className="input-group-btn"
                          style={{
                            width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                            justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '50px', minWidth: '50px'
                          }} onClick={(e) => { consultarOperacoes(); setOperacoes([]); setEmpregadorPromotoraList([]) }}>
                          <a>
                            <FontAwesomeIcon icon={faSearch} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                          </a>
                          <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Consultar Operações</strong>
                        </button>
                      </div>
                    </div>

                    {empregadorPromotoraList != null && empregadorPromotoraList!.length > 0 ? (
                      <div style={{ maxHeight: '250px' }} className="table-responsive">
                        <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                          <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                            <thead>
                              <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black', position: 'sticky', top: '0' }}>
                                <th style={{ textAlign: 'center' }} scope="col">Nome Empregador</th>
                                <th style={{ textAlign: 'center' }} scope="col">Nome Promotora</th>
                                <th style={{ textAlign: 'center' }} scope="col">Qtd Operações</th>
                                <th style={{ textAlign: 'center' }} scope="col">Valor Financiado</th>
                                <th style={{ textAlign: 'center' }} scope="col">Valor Bruto</th>
                                <th style={{ textAlign: 'center' }} scope="col">Valor Cessão</th>
                              </tr>
                            </thead>
                            <tbody>
                              {empregadorPromotoraList.map((item) => (
                                <tr className="GridRowStyle">
                                  <td style={{ textAlign: 'center' }}>{item.empregador}</td>
                                  <td style={{ textAlign: 'center' }}>{item.promotora}</td>
                                  <td style={{ textAlign: 'center' }}>{item.qtdOperacoes}</td>
                                  <td style={{ textAlign: 'center' }}>R$ {item.valorFinanciado!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  <td style={{ textAlign: 'center' }}>R$ {item.valorBruto!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {/* R$ {item.valorCessao!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                  </td>
                                </tr>
                              ))}
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: 'center' }}>{totalOperacoes}</td>
                              <td style={{ textAlign: 'center' }}>R$ {totalFinanciado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                              <td style={{ textAlign: 'center' }}>R$ {totalBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                              <td></td>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : exibeOperacao ? (
                      <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                        <span style={{ color: 'red' }}>Não existem operações disponíveis para cessão na data informada!</span>
                      </div>
                    ) : null
                    }

                    {exibeOperacao && operacoes != null && operacoes.length > 0 ? (
                      <>
                        <form
                        >
                          <h4><strong>Operações Disponíveis</strong></h4>
                          <div style={{ maxHeight: '1000px' }} className="table-responsive">
                            <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                              <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                                <thead>
                                  <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black', position: 'sticky', top: '0' }}>
                                    <th scope="col"><input type="checkbox" checked={selecionarTudo} onChange={() => selecionarDeselecionarTodos(!selecionarTudo)} /> Tudo</th>
                                    <th scope="col">CPF</th>
                                    <th scope="col">Nº Contrato</th>
                                    <th scope="col">Data Base</th>
                                    <th scope="col">Empregador</th>
                                    <th scope="col">Prazo</th>
                                    <th scope="col">Valor Parcela</th>
                                    <th scope="col">Valor Financiado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {operacoes.map((item) => (
                                    <tr>
                                      <td><input type="checkbox" checked={item.ativo} onChange={() => handleAtivoChange(item.numeroContrato)} /></td>
                                      <td>{item.cpfCliente}</td>
                                      <td>{item.numeroContrato}</td>
                                      <td>{item.dtBase ? format(new Date(item.dtBase), 'dd/MM/yyyy') : ''}</td>
                                      <td>{item.empregador}</td>
                                      <td>{item.prazo}</td>
                                      <td>R$ {item.valorParc!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                      <td>R$ {item.valorFinan!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </form>
                        <div className="row" style={{ marginTop: '20px', height: '50px', maxWidth: '70%' }}>
                          <div className="col-md-3">
                            <button className="input-group-btn"
                              style={{
                                width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                                justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                              }} onClick={lancarRecompra}>
                              <a>
                                <FontAwesomeIcon icon={faSave} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                              </a>
                              <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Lançar Recompra</strong>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}

                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div >
      <ScrollToTopButton />
    </>
  );
}

export default RecompraManualComponent;