import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import { Button } from 'react-bootstrap';
import { Comunicado } from '../../shared/model/comunicado';
import { TipoComunicado } from '../../shared/model/tipoComunicado';
import { format } from 'date-fns';
import { Promotora } from '../../shared/model/promotora';
import ComunicadoService from '../../services/comunicadoService';

function ComunicadosVisualizacao() {
  const location = useLocation();
  const { id } = location.state || {};
  
  const [loading, setLoading] = useState(false);
  
  const [tiposComunicado, setTiposComunicado] = useState<TipoComunicado[]>([]);
  const [comunicado, setComunicado] = useState<Comunicado>();

  const [selectedPromotoras, setSelectedPromotoras] = useState<Promotora[]>([]);

  const [selectPromotora, setSelectPromotora] = useState(false);
  const [selectSupervisor, setSelectSupervisor] = useState(false);
  const [selectPontoVenda, setSelectPontoVenda] = useState(false);

  const comunicadoService: ComunicadoService = new ComunicadoService();

  const listarTipoComunicados = async () => {
    setLoading(true);
    try {
      const response = await comunicadoService.listarTodosTipoComunicadoAtivo();
      setTiposComunicado(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarComunicadoPorId = async () => {
    setLoading(true);
    try {
      const response = await comunicadoService.listarComunicadoPorId(id);
      const json = response.data;
      setSelectedPromotoras(json.promotorasList);
      
      setComunicado(json);
      if (json.exibirPara.includes('P')) setSelectPromotora(true);
      if (json.exibirPara.includes('S')) setSelectSupervisor(true);
      if (json.exibirPara.includes('L')) setSelectPontoVenda(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarComunicadoPorId();
    listarTipoComunicados();
  }, []);

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <h5>Comunicados Vigentes &gt; Visualização</h5>
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
          </div>

          <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-md-1">
              <label>Código</label>
              <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
            </div>
            <div className="col-md-4">
              <label>Nome Do Comunicado </label>
              <input required type="text" className="form-control" value={comunicado?.nomeComunicado} style={{ backgroundColor: 'LightGrey' }} />
            </div>
            <div className="col-md-3">
              <label>Tipo de Comunicado </label>
              <select className="form-control" value={comunicado?.idTipoComunicado} style={{ backgroundColor: 'LightGrey' }} disabled >
                {tiposComunicado.map((item) => (<option key={item.id} value={item.id!}>{item.id + ' - ' + item.descricao}</option>))}
              </select>
            </div>
            <div className="col-md-2">
              <label>Data Início </label>
              <input type="text" className="form-control" readOnly value={comunicado?.dtInicio ? format(new Date(comunicado?.dtInicio), 'dd/MM/yyyy') : ''} style={{ backgroundColor: 'LightGrey' }} />
            </div>
            <div className="col-md-2">
              <label>Data Fim </label>
              <input type="text" className="form-control" readOnly value={comunicado?.dtFim ? format(new Date(comunicado?.dtFim), 'dd/MM/yyyy') : ''} style={{ backgroundColor: 'LightGrey' }} />
            </div>
          </div>

          <div style={{ display: 'flex', marginTop: '10px' }}>
            <div>
              <label>Promotoras Selecionadas</label>
              <div className="form-control" style={{ width: '575px', height: '150px', backgroundColor: 'LightGrey', overflowY: 'auto', maxHeight: '150px' }}>
                {selectedPromotoras.map((promotora) => (
                  <div key={promotora.id}>
                    {promotora.id} - {promotora.nome}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px' }}>
            <div className="col">
              <label>Descrição</label>
              <input type="text" className="form-control" value={comunicado?.descricao} style={{ backgroundColor: 'LightGrey' }} />
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px' }}>
            <div className="col">
              <label>Visualizar Comunicado</label>
              <div className="form-group form-control">
                <label style={{ marginRight: '10px' }}>
                  <input style={{ marginRight: '7px' }} type="checkbox" checked={selectPromotora} />
                  Promotora
                </label>
                <label style={{ marginRight: '10px' }}>
                  <input style={{ marginRight: '7px' }} type="checkbox" checked={selectSupervisor} />
                  Supervisor
                </label>
                <label style={{ marginRight: '10px' }}>
                  <input style={{ marginRight: '7px' }} type="checkbox" checked={selectPontoVenda} />
                  Ponto de Venda
                </label>
              </div>
            </div>
          </div>
          {comunicado?.arquivoBase64 && (
            <div style={{ maxWidth: '500px', maxHeight: '500px', marginTop: '10px', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={comunicado?.arquivoBase64 ? `data:image/png;base64,${comunicado?.arquivoBase64}` : ''}
                alt="Imagem"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ComunicadosVisualizacao;