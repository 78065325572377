import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import Spinner from '../../../shared/spinner';
import { format, parse } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { OperacaoDTO } from '../../../shared/dto/operacaoDTO';
import OperacaoService from '../../../services/operacaoService';

function EstornoOperacao() {
  const [loading, setLoading] = useState(false);

  const [contratoList, setContratoList] = useState<OperacaoDTO[]>([]);
  const [exibeDetalhe, setExibeDetalhe] = useState(false);
  const [dtMovimento, setDtMovimento] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [contrato, setContrato] = useState('');

  const operacaoService: OperacaoService = new OperacaoService();

  const listarContrato = async () => {
    if (contrato === '') return alert('Preencha o campo de busca');
    try {
      setContratoList([]);
      setLoading(true);

      const responseList = await operacaoService.listarContratoPorFiltro("CONTRATO", contrato);
      setContratoList(responseList.data);
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const estornarOperacao = async () => {
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return alert('Data de movimento não pode ser superior a data de hoje');

    try {
      
      setLoading(true);
      const response = await operacaoService.estornarOperacao(contrato, dtMovimento.toString());
      const json = response.data;
      setExibeDetalhe(false);
      
      alert(response.data)
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      alert(err.response.data);
      console.error(err);
    }
  }

  return (
    <>
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <strong>Estorno de Operação</strong>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Contrato <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
                        value={contrato} onChange={(e) => { setContrato((e.target.value)); setExibeDetalhe(false); setContratoList([]); }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarContrato}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>

                {exibeDetalhe ? (
                  <>
                    {contratoList.length === 0 ? (
                      <span style={{ backgroundColor: 'LightGrey', marginTop: '10px' }}>Nenhum Contrato localizado!</span>
                    ) : (
                      <>
                        <div className="" style={{ marginTop: '10px' }}>
                          <div className="row">
                            <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              <label style={{ width: '100%' }}>Data Movimento</label>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: '5px' }}>
                            <div className="col-md-2" style={{ paddingLeft: '10px' }}>
                              <InputMask required mask="99/99/9999" className="form-control" type="text"
                                value={dtMovimento} onChange={(e) => setDtMovimento((e.target.value).replace(/_/g, ""))} />
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div style={{ width: '100%', borderWidth: '1px' }}>
                            <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }}>
                              <tbody>
                                <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                                  <th style={{ textAlign: 'center' }} scope="col">Contrato</th>
                                  <th style={{ textAlign: 'center' }} scope="col">CPF</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Nome</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Matricula</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Data Base</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Valor Financiado</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Prazo</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Parcelas Liquidadas</th>
                                  <th style={{ textAlign: 'center' }} scope="col">Status</th>
                                </tr>
                                {contratoList.map((item) => (
                                  <tr className="GridRowStyle">
                                    <td style={{ textAlign: 'center' }}>{item.numeroContrato}</td>
                                    <td style={{ textAlign: 'center' }}>{item.cpfCliente}</td>
                                    <td style={{ textAlign: 'center' }}>{item.nomeCliente}</td>
                                    <td style={{ textAlign: 'center' }}>{item.matriculaCliente}</td>
                                    <td style={{ textAlign: 'center' }}>{item.dtBase ? format(new Date(item.dtBase), 'dd/MM/yyyy HH:mm') : ''}</td>
                                    <td style={{ textAlign: 'center' }}>R$ {item.valorFinanciado}</td>
                                    <td style={{ textAlign: 'center' }}>{item.prazo}</td>
                                    <td style={{ textAlign: 'center' }}>{item.numeroParcelasLiquidadas ? item.numeroParcelasLiquidadas : 0}</td>
                                    <td style={{ textAlign: 'center' }}>{item.ativo ? 'Ativo' : 'Estornado'}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="row" style={{ marginTop: '10px', height: '50px', maxWidth: '70%' }}>
                            <div className="col-md-4">
                              <button className="input-group-btn"
                                style={{
                                  width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                                  justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                                }} onClick={estornarOperacao}>
                                <a>
                                  <FontAwesomeIcon icon={faSave} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                                </a>
                                <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Confirmar Estorno</strong>
                              </button>
                            </div>
                            <div className="col-md-3">
                              <button className="input-group-btn"
                                style={{
                                  width: '80%', display: 'flex', alignItems: 'center', backgroundColor: 'red', borderColor: 'red',
                                  justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                                }} onClick={() => window.history.back()}>
                                <a>
                                  <FontAwesomeIcon icon={faClose} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                                </a>
                                <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Cancelar</strong>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div >
    </>
  );
}

export default EstornoOperacao;