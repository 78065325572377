export class ClienteEndereco {
  public id!: number;
  public tipoEndereco!: string;
  public idCliente!: number;
  public cep!: string;
  public logradouro!: string;
  public numero!: string;
  public complemento!: string;
  public bairro!: string;
  public localidade!: string;
  public uf!: string;
}