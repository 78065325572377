
import createAxiosInstance from './axiosConfig';
import { Financiamento } from '../shared/model/financiamento';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/financiamentoController');
export default class FinanciamentoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosFinanciamentos() {
    return await this.axios.get(`/listarTodosFinanciamentos`);
  };

  async excluirFinanciamento(id: string) {
    return await this.axios.get(`/excluirFinanciamento?id=${id}`);
  };

  async ativarInativarFinanciamento(id: string) {
    return await this.axios.get(`/ativarInativarFinanciamento?id=${id}`);
  };

  async listarFinanciamentoDTOPorId(id: string) {
    return await this.axios.get(`/listarFinanciamentoDTOPorId?id=${id}`);
  };

  async incluirAlterarFinanciamento(dto: Financiamento) {
    return await this.axios.post(`/incluirAlterarFinanciamento`, dto);
  };

  async listarFinanciamentoPorPromotoraEmpregadorProposta(promotora: string, empregador: string, tpProposta: string) {
    return await this.axios.get(`/listarFinanciamentoPorPromotoraEmpregadorProposta?idPromotora=${promotora}&idEmpregador=${empregador}&tipoProposta=${tpProposta}`);
  };
  
}