export class Cliente {
  public id!: number;
  public nome!: string;
  public cpf!: string;
  public dtNascimento!: Date;
  public numeroRg!: string;
  public orgaoRg!: string;
  public ufRg!: string;
  public dtEmissaoRg!: Date;
  public ufNaturalidade!: string;
  public sexo!: string;
  public estadoCivil!: string;
  public tipoResidencia!: string;
  public tempoResidencia!: string;
  public nomePai!: string;
  public nomeMae!: string;
  public naturezaOcupacao!: string;
  public empresa!: string;
  public cnpjEmpresa!: string;
  public dtAdmissao!: Date;
  public cargo!: string;
  public renda!: number;
  public outrasRendas!: number;
  public numeroBeneficiario!: string;
  public codigoAutorizacao!: string;
  public mesAnoContraCheque!: string;
  public refNome1!: string;
  public refTelefone1!: string;
  public refNome2!: string;
  public refTelefone2!: string;
  public tipoConta!: string;
  public banco!: string;
  public agencia!: string;
  public conta!: string;
  public contaDv!: string;
  public nomeConjuge!: string;
  public cpfConjuge!: string;
  public rgConjuge!: string;
  public dtNascimentoConjuge!: Date;
  public rendaConjuge!: number;
  public email!: string;
  public pfPj!: string;
  public telefoneResidencial!: string;
  public telefoneComercial!: string;
  public telefoneCelular!: string;
}