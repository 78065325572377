import { UUID } from "crypto";

export class ArquivoDTO {
  public id!: UUID | null;
  public tipoArquivo!: number;
  public statusProcessamento!: number;
  public dtInicioProcessamento!: Date;
  public dtFimProcessamento!: Date;
  public mensagem!: string;

  public idProposta!: number;
  public tipoDocumento!: number;
  public arquivoBase64!: string;
  public nomeArquivo!: string;
  public dtInclusao!: Date;
  public caminhoArquivo!: string;
}