import { EsteiraModelo } from "./esteira_modelo";
import { ProdutoIdadeCartao } from "./produtoIdadeCartao";
import { ProdutoIdadeSaque } from "./produtoIdadeSaque";

export class Produto {
  public id!: number | string | null;
  public ativo!: boolean;
  public nome!: string;
  public tipoProduto!: string;
  public esteira!: EsteiraModelo;
  public horaCorte!: string;
  public validaBanco!: string;
  public valorMinLimite!: string;
  public qtdContratos!: string;
  public validarPor!: string;
  public faixaIdade1!: string;
  public faixaIdade2!: string;
  public iof!: string;
  public financia!: string;
  public prefixo!: string;
  public mascara!: string;
  public calculaDV!: string;
  public percentCompra!: string;
  public percentSaque!: string;
  public limiteSaque1!: string;
  public limiteSaque2!: string;
  public consisteIdadeSaque!: string;
  public tabelaIdadeCartao!: ProdutoIdadeCartao[];
  public tabelaIdadeSaque!: ProdutoIdadeSaque[];
  public codigoExterno!: number;
}