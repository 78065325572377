import { Empregador } from "../model/empregador";
import { Promotora } from "../model/promotora";

export class EmpregadorPromotoraDTO {
  public empregadores!: Array<Empregador>;
  public promotoras!: Array<Promotora>;
  public dtBase1!: Date;
  public dtBase2!: Date;
  public idCessao!: number;

}