import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { ButtonInativar } from '../../shared/buttons';
import { TipoComunicado } from '../../shared/model/tipoComunicado';
import ComunicadoService from '../../services/comunicadoService';

function ComunicadosTipoComponent() {
  const [loading, setLoading] = useState(true);
  const [tipos, setTipos] = useState<TipoComunicado[]>([]);
  const [descricao, setDescrica] = useState('');
  const [refresh, setRefresh] = useState(false);

  const comunicadoService: ComunicadoService = new ComunicadoService();

  const listarTodosTipoComunicadoAtivo = async () => {
    try {
      setLoading(true);
      const responseList = await comunicadoService.listarTodosTipoComunicadoAtivo();
      setTipos(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      let tipo: TipoComunicado = new TipoComunicado();
      tipo.descricao = descricao;

      await comunicadoService.inclusaoTipoComunicado(tipo);
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const excluirTipoComunicado = async (id: number) => {
    if (window.confirm('Tem certeza que deseja excluir este Tipo de Comunicado??')) {
      try {
        await comunicadoService.inativarTipoComunicadoAtivo(id);
        alert('Excluído com sucesso!');
        setRefresh(!refresh);
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    listarTodosTipoComunicadoAtivo();
  }, [refresh]);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Comunicados &gt; Tipos de Comunicados</h5>
          <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
            Voltar
          </Button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <label>Descrição <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={descricao} onChange={(e) => setDescrica(e.target.value)} />
            </div>
            <div className="col-xl" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Adicionar Tipo Comunicado</button>
            </div>
          </div>
        </form>

        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '10%' }}>Código</th>
                  <th scope="col" style={{ width: '70%' }}>Descrição</th>
                  <th scope="col" style={{ width: '20%' }}>Excluir</th>
                </tr>
              </thead>
              <tbody>
                {tipos.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.descricao}</td>
                    <td>
                      <ButtonInativar onClick={() => excluirTipoComunicado(item.id!)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default ComunicadosTipoComponent;