import createAxiosInstance from './axiosConfig';
import { FinanciamentoEmpregadorDTO } from '../shared/dto/financiamentoEmpregadorDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/financiamentoEmpregadorController');

export default class FinanciamentoEmpregadorService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarFinanciamentoEmpregador(idFinanciamento: string) {
    return await this.axios.get(`/listarFinanciamentoEmpregador?idFinanciamento=${idFinanciamento}`);
  };

  async incluirAlterarFinanciamentoEmpregador(idFinanciamento: string, dto: FinanciamentoEmpregadorDTO[]) {
    return await this.axios.post(`/incluirAlterarFinanciamentoEmpregador?financiamentoId=${idFinanciamento}`, dto);
  };

}