import { UUID } from "crypto";
import { Promotora } from "./promotora";

export class Comunicado {
	public id!: number | null;
	public ativo!: boolean;
	public dtInclusao!: Date;
	public codigo!: string;
	public idTipoComunicado!: number;
	public idPromotora!: number;
	public idUsuarioCadastro!: number;
	public nomeComunicado!: string;
	public dtInicio!: Date;
	public dtFim!: Date;
	public descricao!: string;
	public exibirPara!: string;
	public nomeArquivo!: string;
	public caminhoArquivo!: string;
	public idUsuarioAlteracao!: number;;
	public dtAlteracao!: Date;

	public promotorasList!: Promotora[];
	public arquivoBase64!: string;
}