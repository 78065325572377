import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../../../shared/spinner';
import { EsteiraModelo } from '../../../shared/model/esteira_modelo';
import { useNavigate } from 'react-router-dom';
import EsteiraModeloService from '../../../services/esteiraModeloService';

function EsteiraInclusao() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [nomeEsteira, setNomeEsteira] = useState('');

  const esteiraModeloService: EsteiraModeloService = new EsteiraModeloService();

  const handleSubmitName = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let esteiraModel: EsteiraModelo = new EsteiraModelo();
    esteiraModel.nomeEsteira = nomeEsteira;

    try {
      const response = await esteiraModeloService.incluirAlterarEsteiraModelo(esteiraModel);
      
      setLoading(false);
      alert('Inclusão do nome realizada com sucesso!');
      navigate('/parametros-esteira/alteracao', { state: { idEsteira: response.data?.id!, nomeEst: response.data?.nomeEsteira } })
    } catch (err) {
      setLoading(false);
      alert('Ocorreu um erro ao adicionar nome esteira!');
      console.error(err);
    }

  };

  return (
    <div className="container">
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmitName}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <h5>Parâmetros Cartão &gt; Esteira &gt; Inclusão</h5>
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row" style={{ display: 'flex' }}>
            <div className="col-xl" style={{ maxWidth: '45%' }}>
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={nomeEsteira} onChange={(e) => setNomeEsteira(e.target.value)} />
            </div>

            <div className="rmc-button" style={{ marginTop: '2%', maxWidth: '55%' }}>
              <button type="submit" className="w-60 btn btn-primary ">
                Salvar
              </button>
            </div>
          </div>
          <hr className="my-4" />
        </form>
      )}

    </div>
  );
}

export default EsteiraInclusao;