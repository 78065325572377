import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import './App.css';
import ClienteComponent from "./app/pages/esteira/clienteComponent";
import EsteiraAtividades from "./app/pages/esteira/esteiraAtividades";
import PropostaEsteiraComponent from "./app/pages/esteira/propostaEsteiraComponent";
import PropostasComponent from "./app/pages/esteira/propostasComponent";
import PromotoraEmpregador from "./app/pages/origens-parametros/promotoraEmpregador";
import MatrizComponent from './app/pages/origens/matriz/matrizComponent';
import OperadorComponent from "./app/pages/origens/operador/operadorComponent";
import PontoVendaComponent from "./app/pages/origens/pontoVenda/pontoVendaComponent";
import PromotoraComponent from "./app/pages/origens/promotora/promotoraComponent";
import SupervisorComponent from "./app/pages/origens/supervisor/supervisorComponent";
import OrgaoComponent from "./app/pages/parametros/empregador/orgaoComponent";
import EmpregadorComponent from "./app/pages/parametros/empregador/empregadorComponent";
import EsteiraAlteracao from "./app/pages/parametros/esteira/esteiraAlteracao";
import EsteiraComponent from "./app/pages/parametros/esteira/esteiraComponent";
import EsteiraInclusao from "./app/pages/parametros/esteira/esteiraInclusao";
import FinanciamentoComponent from "./app/pages/parametros/financiamento/financiamentoComponent";
import FinanciamentoEmpregador from "./app/pages/parametros/financiamento/financiamentoEmpregador";
import FinanciamentoPromotora from "./app/pages/parametros/financiamento/financiamentoPromotora";
import MotivoComponent from "./app/pages/parametros/motivo/motivoComponent";
import ProdutoComponent from "./app/pages/parametros/produto/produtoComponent";
import CartaoComponent from "./app/pages/proposta/cartaoComponent";
import ParametrosTi from "./app/pages/sistema/parametrosTi";
import AnaliseRmcComponent from "./app/pages/esteira/analiseRmcComponent";
import ConsultaContratoComponent from "./app/pages/back-office/contrato/consultaContratoComponent";
import DetalheContratoComponent from "./app/pages/back-office/contrato/detalheContratoComponent";
import BaixaParcelaManualComponent from "./app/pages/back-office/lancamentos/baixaParcelaManualComponent";
import EstornoParcelaComponent from "./app/pages/back-office/lancamentos/estornoParcelaComponent";
import HistoricoFinanceiroComponent from "./app/pages/back-office/tabelas/historicoFinanceiro/historicoFinanceiroComponent";
import HistoricoFinanceiroInclusaoAlteracao from "./app/pages/back-office/tabelas/historicoFinanceiro/historicoFinanceiroInclusaoAlteracao";
import CancelamentoMovimentacao from "./app/pages/back-office/lancamentos/cancelamentoMovimentacao";
import EstornoOperacao from "./app/pages/back-office/lancamentos/estornoOperacao";
import ProrrogacaoVencimentoManualComponent from "./app/pages/back-office/lancamentos/prorrogacaoVencimentoManualComponent";
import ArquivoLoteDetalhe from "./app/pages/back-office/arquivoLote/arquivoLoteDetalhe";
import ArquivoLoteComponent from "./app/pages/back-office/arquivoLote/arquivoLoteComponent";
import FidcComponent from "./app/pages/back-office/tabelas/cessao/fidcComponent";
import FidcInlusaoAlteracao from "./app/pages/back-office/tabelas/cessao/fidcInclusaoAlteracao";
import LancamentoManual from "./app/pages/back-office/cessao/lancamentoManual";
import RecompraManualComponent from "./app/pages/back-office/cessao/recompraManualComponent";
import OperacoesRealizadasComponent from "./app/pages/back-office/relatorios/operacoesRealizadasComponent";
import DeVencimentosComponent from "./app/pages/back-office/relatorios/deVencimentosComponent";
import MovimentacaoFinanceiraComponent from "./app/pages/back-office/relatorios/movimentacaoFinanceiraComponent";
import ContratosCedidosComponent from "./app/pages/back-office/relatorios/contratosCedidosComponent";
import ParcelasCedidasComponent from "./app/pages/back-office/relatorios/parcelasCedidasComponent";
import RemessaoLiquidacaoComponent from "./app/pages/back-office/lancamentos/remessaLiquidacaoComponent";
import ConsultaDocumentosComponent from "./app/pages/esteira/consultaDocumentosComponent";
import RetornoFluxoComponent from "./app/pages/esteira/retornoFluxoComponent";
import ProducaoAnaliticoComponent from "./app/pages/relatorios/producaoAnaliticoComponent";
import ProducaoSinteticoComponent from "./app/pages/relatorios/producaoSinteticoComponent";
import OrigensCadastradasComponent from "./app/pages/relatorios/origensCadastradasComponent";
import ClienteConsultaComponent from "./app/pages/cliente/clienteConsultaComponent";
import ClienteConsultaHistoricoComponent from "./app/pages/cliente/clienteConsultaHistoricoComponent";
import ClienteAprovarAtualizacaoComponent from "./app/pages/cliente/clienteAprovarAtualizacaoComponent";
import ClienteRelatorioComponent from "./app/pages/cliente/clienteRelatorioComponent";
import ComunicadosVigentesComponent from "./app/pages/comunicados/comunicadosVigentesComponent";
import ComunicadosTipoComponent from "./app/pages/comunicados/comunicadosTipoComponent";
import ComunicadosComponent from "./app/pages/comunicados/comunicadosComponent";
import ComunicadosInclusaoAlteracao from "./app/pages/comunicados/comunicadosInclusaoAlteracao";
import ComunicadosVigentesVisualizacao from "./app/pages/comunicados/comunicadosVisualizacao";
import UsuarioComponent from "./app/pages/sistema/usuarioComponent";
import CessaoComponent from "./app/pages/back-office/tabelas/cessao/cessaoComponent";
import UsuarioInclusaoAlteracao from "./app/pages/sistema/usuarioInclusaoAlteracao";
import MatrizInclusaoAlteracao from "./app/pages/origens/matriz/matrizInclusaoAlteracao";
import PromotoraInclusaoAlteracao from "./app/pages/origens/promotora/promotoraInclusaoAlteracao";
import SupervisorInclusaoAlteracao from "./app/pages/origens/supervisor/supervisorInclusaoAlteracao";
import OperadorInclusaoAlteracao from "./app/pages/origens/operador/operadorInclusaoAlteracao";
import PontoVendaInclusaoAlteracao from "./app/pages/origens/pontoVenda/pontoVendaInclusaoAlteracao";
import EmpregadorInclusaoAlteracao from "./app/pages/parametros/empregador/empregadorInclusaoAlteracao";
import ProdutoInclusaoAlteracao from "./app/pages/parametros/produto/produtoInclusaoAlteracao";
import FinanciamentoInclusaoAlteracao from "./app/pages/parametros/financiamento/financiamentoInclusaoAlteracao";
import CessaoInclusaoAlteracao from "./app/pages/back-office/tabelas/cessao/cessaoInclusaoAlteracao";
import PrivateRoute from "./privateRoute";
import LoginComponent from "./app/pages/login/loginComponent";
import NavBar from './app/NavBar';
import Home from './app/pages/Home';

const App = () => (
  <Router>
    <Layout />
  </Router>
);

const Layout = () => {
  const location = useLocation();
  const hideNavBar = location.pathname === '/login';

  return (
    <>
      {!hideNavBar && <NavBar />}
      <Routes>
        <Route path="/login" element={<LoginComponent />} />

        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />

        {/* PROPOSTA */}
        <Route path="/proposta-cartao" element={<PrivateRoute><CartaoComponent /></PrivateRoute>} />

        {/* ESTEIRA */}
        <Route path="/esteira-proposta/:tipoTela" element={<PrivateRoute><PropostasComponent /></PrivateRoute>} />
        <Route path="/esteira-cliente/alteracao" element={<PrivateRoute><ClienteComponent /></PrivateRoute>} />
        <Route path="/esteira-proposta/atividades" element={<PrivateRoute><EsteiraAtividades /></PrivateRoute>} />
        <Route path="/proposta-esteira-component" element={<PrivateRoute><PropostaEsteiraComponent /></PrivateRoute>} />
        <Route path="/analise-rmc" element={<PrivateRoute><AnaliseRmcComponent /></PrivateRoute>} />
        <Route path="/esteira-consulta-documentos" element={<PrivateRoute><ConsultaDocumentosComponent /></PrivateRoute>} />
        <Route path="/esteira-retorno-fluxo" element={<PrivateRoute><RetornoFluxoComponent /></PrivateRoute>} />

        {/* CLIENTE */}
        <Route path="/cliente-consulta/:tipoTela" element={<PrivateRoute><ClienteConsultaComponent /></PrivateRoute>} />
        <Route path="/cliente-consulta-historico" element={<PrivateRoute><ClienteConsultaHistoricoComponent /></PrivateRoute>} />
        <Route path="/cliente-aprovar-atualizacao" element={<PrivateRoute><ClienteAprovarAtualizacaoComponent /></PrivateRoute>} />
        <Route path="/cliente-relatorio" element={<PrivateRoute><ClienteRelatorioComponent /></PrivateRoute>} />

        {/* RELATORIOS */}
        <Route path="/relatorio-analitico" element={<PrivateRoute><ProducaoAnaliticoComponent /></PrivateRoute>} />
        <Route path="/relatorio-sintetico" element={<PrivateRoute><ProducaoSinteticoComponent /></PrivateRoute>} />
        <Route path="/relatorio-origens-cadastradas" element={<PrivateRoute><OrigensCadastradasComponent /></PrivateRoute>} />

        {/* ORIGENS */}
        <Route path="/origens-matriz" element={<PrivateRoute><MatrizComponent /></PrivateRoute>} />
        <Route path="/origens-matriz/:tipo" element={<PrivateRoute><MatrizInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/origens-promotora" element={<PrivateRoute><PromotoraComponent /></PrivateRoute>} />
        <Route path="/origens-promotora/:tipo" element={<PrivateRoute><PromotoraInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/origens-supervisor" element={<PrivateRoute><SupervisorComponent /></PrivateRoute>} />
        <Route path="/origens-supervisor/:tipo" element={<PrivateRoute><SupervisorInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/origens-operador" element={<PrivateRoute><OperadorComponent /></PrivateRoute>} />
        <Route path="/origens-operador/:tipo" element={<PrivateRoute><OperadorInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/origens-ponto-venda" element={<PrivateRoute><PontoVendaComponent /></PrivateRoute>} />
        <Route path="/origens-ponto-venda/:tipo" element={<PrivateRoute><PontoVendaInclusaoAlteracao /></PrivateRoute>} />

        {/* PARAMETROS CARTAO */}
        <Route path="/parametros-empregador" element={<PrivateRoute><EmpregadorComponent /></PrivateRoute>} />
        <Route path="/parametros-empregador/:tipo" element={<PrivateRoute><EmpregadorInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/parametros-esteira" element={<PrivateRoute><EsteiraComponent /></PrivateRoute>} />
        <Route path="/parametros-esteira/inclusao" element={<PrivateRoute><EsteiraInclusao /></PrivateRoute>} />
        <Route path="/parametros-esteira/alteracao" element={<PrivateRoute><EsteiraAlteracao /></PrivateRoute>} />

        <Route path="/parametros-empregador/orgao" element={<PrivateRoute><OrgaoComponent /></PrivateRoute>} />

        <Route path="/parametros-produto" element={<PrivateRoute><ProdutoComponent /></PrivateRoute>} />
        <Route path="/parametros-produto/:tipo" element={<PrivateRoute><ProdutoInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/parametros-financiamento" element={<PrivateRoute><FinanciamentoComponent /></PrivateRoute>} />
        <Route path="/parametros-financiamento/:tipo" element={<PrivateRoute><FinanciamentoInclusaoAlteracao /></PrivateRoute>} />

        <Route path="/parametros-financiamento/promotora" element={<PrivateRoute><FinanciamentoPromotora /></PrivateRoute>} />
        <Route path="/parametros-financiamento/empregador" element={<PrivateRoute><FinanciamentoEmpregador /></PrivateRoute>} />

        <Route path="/promotora-empregador" element={<PrivateRoute><PromotoraEmpregador /></PrivateRoute>} />

        <Route path="/motivo-recusa" element={<PrivateRoute><MotivoComponent /></PrivateRoute>} />

        {/* COMUNICADOS */}
        <Route path="/comunicados-vigentes" element={<PrivateRoute><ComunicadosVigentesComponent /></PrivateRoute>} />
        <Route path="/comunicados-visualizacao" element={<PrivateRoute><ComunicadosVigentesVisualizacao /></PrivateRoute>} />
        <Route path="/comunicados-tipos" element={<PrivateRoute><ComunicadosTipoComponent /></PrivateRoute>} />
        <Route path="/comunicados" element={<PrivateRoute><ComunicadosComponent /></PrivateRoute>} />
        <Route path="/comunicados/:tipo" element={<PrivateRoute><ComunicadosInclusaoAlteracao /></PrivateRoute>} />
        <Route path="/comunicados-confirmacao" element={<PrivateRoute><ComunicadosVigentesComponent /></PrivateRoute>} />

        {/* SISTEMA */}
        <Route path="/sistema-parametros-ti" element={<PrivateRoute><ParametrosTi /></PrivateRoute>} />
        <Route path="/sistema-usuario" element={<PrivateRoute><UsuarioComponent /></PrivateRoute>} />
        <Route path="/sistema-usuario/:tipo" element={<PrivateRoute><UsuarioInclusaoAlteracao /></PrivateRoute>} />

        {/* BACKOFFICE */}
        <Route path="/backoffice-contrato/consulta" element={<PrivateRoute><ConsultaContratoComponent /></PrivateRoute>} />
        <Route path="/backoffice-contrato/detalhe" element={<PrivateRoute><DetalheContratoComponent /></PrivateRoute>} />

        {/* BACKOFFICE - LANCAMENTOS */}
        <Route path="/backoffice-lancamentos/baixa-parcela" element={<PrivateRoute><BaixaParcelaManualComponent /></PrivateRoute>} />
        <Route path="/backoffice-contrato/prorrogacao-vencimento" element={<PrivateRoute><ProrrogacaoVencimentoManualComponent /></PrivateRoute>} />
        <Route path="/backoffice-lancamentos/estorno-parcela" element={<PrivateRoute><EstornoParcelaComponent /></PrivateRoute>} />
        <Route path="/backoffice-lancamentos/cancelamento-movimentacao" element={<PrivateRoute><CancelamentoMovimentacao /></PrivateRoute>} />
        <Route path="/backoffice-lancamentos/estorno-operacao" element={<PrivateRoute><EstornoOperacao /></PrivateRoute>} />

        {/* BACKOFFICE -ARQUIVOS LOTE */}
        <Route path="/backoffice-arquivo-lote/:processamento" element={<PrivateRoute><ArquivoLoteComponent /></PrivateRoute>} />
        <Route path="/backoffice-arquivo-lote/detalhe/:idArquivo" element={<PrivateRoute><ArquivoLoteDetalhe /></PrivateRoute>} />

        {/* BACKOFFICE - CESSAO */}
        <Route path="/backoffice-cessao/lancamento-manual" element={<PrivateRoute><LancamentoManual /></PrivateRoute>} />
        <Route path="/backoffice-cessao/recompra-manual" element={<PrivateRoute><RecompraManualComponent /></PrivateRoute>} />
        <Route path="/backoffice-cessao/remessa-liquidacao" element={<PrivateRoute><RemessaoLiquidacaoComponent /></PrivateRoute>} />

        {/* BACKOFFICE -RELATORIOS */}
        <Route path="/backoffice-relatorios/operacoes-realizadas" element={<PrivateRoute><OperacoesRealizadasComponent /></PrivateRoute>} />
        <Route path="/backoffice-relatorios/vencimentos" element={<PrivateRoute><DeVencimentosComponent /></PrivateRoute>} />
        <Route path="/backoffice-relatorios/movimentacao-financeira"element={<PrivateRoute><MovimentacaoFinanceiraComponent /></PrivateRoute>} />
        <Route path="/backoffice-relatorios/contratos-cedidos" element={<PrivateRoute><ContratosCedidosComponent /></PrivateRoute>} />
        <Route path="/backoffice-relatorios/parcelas-cedidas" element={<PrivateRoute><ParcelasCedidasComponent /></PrivateRoute>} />

        {/* BACKOFFICE - TABELAS */}
        <Route path="/backoffice-tabelas/historico-financeiro" element={<PrivateRoute><HistoricoFinanceiroComponent /></PrivateRoute>} />
        <Route path="/backoffice-tabelas/historico-financeiro/:tipo" element={<PrivateRoute><HistoricoFinanceiroInclusaoAlteracao /></PrivateRoute>} />
        <Route path="/backoffice-tabelas/fidc" element={<PrivateRoute><FidcComponent /></PrivateRoute>} />
        <Route path="/backoffice-tabelas/fidc/:tipo" element={<PrivateRoute><FidcInlusaoAlteracao /></PrivateRoute>} />
        <Route path="/backoffice-tabelas/cessao" element={<PrivateRoute><CessaoComponent /></PrivateRoute>} />
        <Route path="/backoffice-tabelas/cessao/:tipo" element={<PrivateRoute><CessaoInclusaoAlteracao /></PrivateRoute>} />

        <Route path="*" element={<Navigate to="/login" replace />} />

      </Routes>
    </>

  );
}

export default App;