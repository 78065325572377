import axios from 'axios';
import { EnderecoDTO } from '../shared/dto/enderecoDTO';

export default class ViaCepService {
  axios: any;

  async viaCep(cepWithoutMask: any) {
    return await axios.get<EnderecoDTO>(
      `https://viacep.com.br/ws/${cepWithoutMask}/json/`);
  };

}