import createAxiosInstance from './axiosConfig';
import { ArquivoDTO } from '../shared/dto/arquivoDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/propostaDocumentoController');

export default class PropostaDocumentoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async inclusaoPropostaDocumento(dto: ArquivoDTO) {
    return await this.axios.post(`/inclusaoPropostaDocumento`, dto);
  };

  async listarPropostaDocumentoPorIdProposta(idProposta: string) {
    return await this.axios.get(`/listarPropostaDocumentoPorIdProposta?idProposta=${idProposta}`);
  };

  async downloadPropostaDocumentoPorCaminhoArquivo(caminhoArquivo: string) {
    return await this.axios.get(`/downloadPropostaDocumentoPorCaminhoArquivo?caminhoArquivo=${caminhoArquivo}`,
      { responseType: 'blob' });
  };

  async downloadLotePropostaDocumentoPorIdProposta(idProposta: string) {
    return await this.axios.get(`/downloadLotePropostaDocumentoPorIdProposta?idProposta=${idProposta}`,
        { responseType: 'arraybuffer' })
  };

  async excluirPropostaDocumentoPorCaminhoArquivo(caminhoArquivo: string) {
    return await this.axios.get(`/excluirPropostaDocumentoPorCaminhoArquivo?caminhoArquivo=${caminhoArquivo}`);
  };

}